import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import IconTick from '../../icons/icon-tick'
import { Stack } from '../stack/stack'
import { findById, validateFileType } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import axios from "axios";
import Promise from 'bluebird'

function NationalidUpload() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['NationalIdUpload']
    // const [selectedFile, setSelectedFile] = useState()
    const uploadFl = findById(metadata, 'upload')
    const btnSkip = findById(metadata, 'btnSkip')
    const btnNext = findById(metadata, 'btnNext')
    const list = findById(metadata, 'list')
    const identificationTooltip = findById(metadata, 'identificationTooltip')
    let agreementDetails = workflowState['CreateAgreementResponse'];
    let NationalIdUploadDetails = workflowState['nationalid-upload']
    const deviceDetection = workflowState['device-detection']
    const autoDetection = workflowState['auto-detection']
    const ManualSelection = workflowState['manual-detection']
    const quoteOffer = workflowState['quote-offer']
    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const isNewPhone = detectionData?.isNewPhone
    const navigateTo = isNewPhone ? 'ReceiptUpload' : 'VideoCapture'
    const [dataCleared, setDataCleared] = useState(false)
    const [fileUploadError, setFileUploadError] = useState()
    const [fileName, setFileName] = useState(null)
    const [loaded, uploadProgressState] = useState(0)
    const [moreThan15Mb, setMoreThan15Mb] = useState(false)
    const [enableUploadBtn, setEnableUploadButton] = useState()
    useEffect(() => {
        clearPhotoDetails()
        setFileUploadError(null)
        setEnableUploadButton(true)
        uploadProgressState(0)
        setDataCleared(true)
    }, [])
    let handleClick = (btnText) => {
        let _vhcTags = workflowState?.vhcTags ? workflowState?.vhcTags?.filter((v) => v !== 'photoId') : []
        _vhcTags.push('photoId')
        updateState({ ...workflowState, vhcTags: _vhcTags });
        updateAgreement(btnText)
    }
    const updateAgreement = (btnText) => {
        let UpdateAgreementRequest = {
            CreateAgreementActivityRequest: {
                AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
                AgreementRequestStatus: btnText === 'Skip' ? 'ID_SKIPPED' : 'ID_UPLOAD',
            }
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
            updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
            return completeCurrentStep(currentStep, {
                NationalidUploadSkipped: btnText === 'Skip' ? true : false,
                navigateTo: btnText === 'Skip' ? 'NationalidUploadSkipped' : navigateTo
            })
        })
    }

    const uploadToS3 = (url, document, formData) => {
        return axios({
            method: 'put',
            url: url,
            headers: {
                Accept: 'application/json',
                'Content-Type': document.type,
            },
            data: document,
            onUploadProgress: (progressEvent) => {
                const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                uploadProgressState(progress)
            },
            formData: formData,
        })
            .then((response) => {
                setEnableUploadButton(false)
                let UpdateAgreementRequest = {
                    CreateAgreementActivityRequest: {
                        AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
                        PhotoIdBucket: NationalIdUploadDetails?.PresignUrlResult?.[0]?.Bucket,
                        PhotoIdPath: NationalIdUploadDetails?.PresignUrlResult?.[0]?.Path1,
                    },
                }
                API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
                    updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
                    // return completeCurrentStep(currentStep, {
                    //     ...workflowState[currentStep],
                    //     navigateTo: navigateTo,
                    // })
                })
            })
            .catch((error) => {
            })
    }
    let clearPhotoDetails = () => {
        updateState({
            ...workflowState, [currentStep]: {
                ...workflowState[currentStep],
                selectedNationaldScreenShot: [],
                PhotoIdResult: undefined,
                DateOfBirth: undefined,
                DocumentType: undefined,
                PassportNumber: undefined,
                LicenceNumber: undefined,
                PhotoIdError: undefined,
                enableUploadBtn: true,
            }
        });
    }
    let handleChange = (e) => {
        setDataCleared(false)
        if (!validateFileType(e.target.files[0].name)) {
            clearPhotoDetails()
            setFileUploadError('Invalid file format')
        } else {
            setFileUploadError(null)
            //if (onUpload) onUpload()
            setFileName(e.target.files[0].name)
            updateState({
                ...workflowState, [currentStep]: {
                    ...workflowState[currentStep],
                    selectedNationaldScreenShot: [...e.target.files]
                }
            });
        }
    }

    let prependPhotoIdPrefix = (path) => {
        return `PhotoId_${path}`
    }

    useEffect(() => {
        if (NationalIdUploadDetails?.selectedNationaldScreenShot && NationalIdUploadDetails?.selectedNationaldScreenShot[0]) {
            let fileSizeInKB = NationalIdUploadDetails?.selectedNationaldScreenShot[0]?.size / 1024
            if (fileSizeInKB && fileSizeInKB <= 102400) {
                setMoreThan15Mb(false)
                if (NationalIdUploadDetails?.selectedNationaldScreenShot && NationalIdUploadDetails?.selectedNationaldScreenShot.length > 0 && !dataCleared) {
                    getPresignUrl()
                }
            } else {
                setMoreThan15Mb(true)
                clearPhotoDetails()
            }
        }
    }, [NationalIdUploadDetails?.selectedNationaldScreenShot])

    useEffect(() => {
        if (NationalIdUploadDetails?.PresignUrlResult && NationalIdUploadDetails?.PresignUrlResult.length > 0 && !dataCleared) {
            Promise.map(NationalIdUploadDetails?.selectedNationaldScreenShot, (document, index) => {
                const formData = new FormData()
                formData.append('file', document)
                let filterUrl =
                    NationalIdUploadDetails?.PresignUrlResult &&
                    NationalIdUploadDetails?.PresignUrlResult.filter((u) => u.Path1.split('/')[2] === prependPhotoIdPrefix(document.name))
                return uploadToS3(filterUrl[0].PreSignedURL, document, formData)
            })
        }
    }, [NationalIdUploadDetails?.PresignUrlResult])

    const getPresignUrl = (e) => {
        let files = NationalIdUploadDetails?.selectedNationaldScreenShot.map((file) => {
            return {
                ClaimId: agreementDetails?.AgreementRequestId || '111111111',
                FileName: prependPhotoIdPrefix(file.name),
                FolderType: 'buyflow',
            }
        })
        let getPresignUrlRequest = {
            FileUploadRequest: {
                Files: files,
            },
        }

        API[ActionTypes.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST](getPresignUrlRequest).then(data => {
            updateState({
                ...workflowState, getPresignUrlResponse: data.data.Files, [currentStep]: {
                    ...workflowState[currentStep],
                    PresignUrlResult: data.data.Files
                }
            });
        })
        //actions.getPresignUrlForUpload({ getPresignUrlRequest: getPresignUrlRequest, id: 'upload-photo' })
    }
    const removeFile = () => {
        setEnableUploadButton(true)
        uploadProgressState(0)
        clearPhotoDetails()
    }
    list.args = {
        ...list.args,
        image: IconTick
    }
    identificationTooltip.args = {
        ...identificationTooltip.args,
        labelText:
            '<span class="text-black">Please provide one of the following forms of identification </span>',
        tooltipText:
            'Photo identification is required to verify authenticity of the customer during the claims process.'
    }
    uploadFl.args = {
        ...uploadFl.args,
        handleChange: e => handleChange(e),
        selectedFile: NationalIdUploadDetails?.selectedNationaldScreenShot?.[0]?.name,
        progress: loaded,
        removeFile: removeFile
    }
    btnSkip.args = {
        ...btnSkip.args,
        onClick: e => updateAgreement('Skip'),
        marginClasses: `mb-4 ${loaded == 100 ? 'hidden' : 'block'}`,
    }
    btnNext.args = {
        ...btnNext.args,
        marginClasses: `mb-4 ${loaded == 100 ? 'block' : 'hidden'}`,
        onClick: e => handleClick('Next')
    }

    return <Stack orientation='vertical' metadata={metadata} />

}

export default NationalidUpload;