import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById, formatModelName } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";

function QRScan() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['QRScan']
    const headerText = findById(metadata, 'headerText')
    const qrcodeField = findById(metadata, 'qr-code')

    let autoDetection = workflowState['auto-detection'];
    const deviceDetection = workflowState['device-detection']
    const ManualSelection = workflowState['manual-detection']
    let agreementDetails = workflowState['CreateAgreementResponse'];

    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const Make = detectionData.Make;
    const Model = detectionData.Model;

    let formattedModelName = formatModelName(Make, Model)

    let [QRState, setQRState] = useState({
        expired: false,
        timer: null,
    })


    useEffect(() => {
        const UpdateAgreementRequest = {
            CreateAgreementActivityRequest: {
                AgreementRequestId: agreementDetails?.AgreementRequestId,
                AgreementRequestStatus: 'VHC_2PHONE_QR_CODE',
            },
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
            updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        })

        // const intervalId = setInterval(() => {
        //     //assign interval to a variable to clear it.
        //     setQRState({ timer: null, expired: true })
        // }, 1000 * process.env.QR_EXPIRY)

        // return () => clearInterval(intervalId)
    }, [])

    headerText.args = {
        ...headerText.args,
        content: `Your phone: <span class='text-purple-700'>${Make} ${formattedModelName}<span>`,
    }
    qrcodeField.args = {
        ...qrcodeField.args,
        qrUrl: ``,
        QRState: QRState
    }
    return <Stack orientation='vertical' metadata={metadata} />
}
export default QRScan;