/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8918 5.1148L7.66639 16.2546L3.56268 11.9377L4.77064 10.7894L7.58052 13.7453L15.6082 4.05176L16.8918 5.1148Z" fill="#8223D2" />
    </svg>
)

export default icon
