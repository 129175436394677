import React, { useState } from 'react'
import { Image } from '../image/image';
import PropTypes from 'prop-types'

// import IconImage from "../../icons/image";
// import IconIscreenDamaged from "../../icons/iscreen_damaged";
// import IconSecondPhone3 from "../../icons/secondphoneimg";
// import IconBatteryIssues from "../../icons/img-BatteryIssues";
// import IconAmex from "../../icons/img-amex";
// import IconAndroidLogo from "../../icons/img-android_logo";
// import IconAppleLogo from "../../icons/img-apple_logo";
// import IconDevicebroken from "../../icons/img-devicebroken";
// import IconIphoneSetting from "../../icons/img-iphone-setting";
// import IconLost from "../../icons/img-lost";
// import IconMail from "../../icons/img-mail";
// import IconMaster from "../../icons/img-master";
// import IconReturnDevice from "../../icons/img-return_device";
// import IconSecondPhone from "../../icons/img-second_phone";
// import IconStolen from "../../icons/img-stolen";
// import IconTruck2 from "../../icons/img-truck";
// import IconVisa2 from "../../icons/img-visa";
// import IconWaterDamaged from "../../icons/img-water_damaged";
import IconAsurionMobilePlus from "../../icons/icon-AsurionMobilePlus";
// import IconDocument from "../../icons/icon-img-document";
// import IconQuestion from "../../icons/icon-question";
// import IconAsurionMobilePlus1 from "../../icons/icon-AsurionMobileplus1";
// import IconDots from "../../icons/icon-img-dots";
import IconSecondPhone2 from "../../icons/icon-second-phn";
// import IconMedWomen1 from "../../icons/icon-Med_Women1";
// import IconMobilephone from "../../icons/icon-img-mobilephone";
// import IconSecurityAdviser from "../../icons/icon-security-adviser";
// import IconAusPost from "../../icons/icon-aus-post";
// import IconNews from "../../icons/icon-img-news";
// import IconSelectedStar from "../../icons/icon-selected-star";
// import IconCancelClaim from "../../icons/icon-cancel-claim";
// import IconPayment from "../../icons/icon-img-payment";
import IconSmallClosex from "../../icons/icon-small-closex";
// import IconCancelLogoutNew from "../../icons/icon-cancel-logout-new";
// import IconPlan2 from "../../icons/icon-img-plan2";
import IconSoluto from "../../icons/icon-soluto";
// import IconCancelLogout from "../../icons/icon-cancel-logout";
// import IconProfile from "../../icons/icon-img-profile";
// import IconSquareTick from "../../icons/icon-square-tick";
// import IconChangeDevice from "../../icons/icon-change-device";
import IconProfile1 from "../../icons/icon-img-profile1";
// import IconStar from "../../icons/icon-star";
// import IconChangePolicy from "../../icons/icon-change-policy";
// import IconSecure from "../../icons/icon-img-secure";
// import IconTechTips from "../../icons/icon-tech-tips";
// import IconChatAdviser from "../../icons/icon-chat-adviser";
// import IconUseredit from "../../icons/icon-img-useredit";
// import IconTick from "../../icons/icon-tick";
// import IconChceky from "../../icons/icon-chceky";
import IconLetGetPrepared from "../../icons/icon-imgLetGetPrepared";
// import IconTooltip from "../../icons/icon-tooltip";
// import IconClassicResolve from "../../icons/icon-classic-resolve";
import IconIngenico from "../../icons/icon-ingenico";
// import IconTruck from "../../icons/icon-truck";
// import IconClosex from "../../icons/icon-closex";
import IconIPhoneImei from "../../icons/icon-iphone-imei";
// import IconUploadFile from "../../icons/icon-upload-file";
// import IconContact from "../../icons/icon-contact";
import IconIphoneMirror from "../../icons/icon-iphone-mirror";
// import IconUserEdit from "../../icons/icon-user-edit";
// import IconCoveredRepair from "../../icons/icon-covered-repair";
 //import IconIphoneScreenshot from "../../icons/icon-iphone-screenshot";
// import IconVideoSelfie from "../../icons/icon-video-selfie";
// import IconCoveredReplace from "../../icons/icon-covered-replace";
// import IconLiteResolve from "../../icons/icon-lite-resolve";
// import IconVideoWoman from "../../icons/icon-video-woman";
// import IconCoveredResolveClassic from "../../icons/icon-covered-resolve-classic";
// import IconLock from "../../icons/icon-lock";
import IconVisa from "../../icons/icon-visa";
// import IconCoveredResolveLite from "../../icons/icon-covered-resolve-lite";
// import IconLogoMobileplus from "../../icons/icon-logo-mobileplus";
// import IconWipeClean from "../../icons/icon-wipe-clean";
// import IconCoveredResolvePrestige from "../../icons/icon-covered-resolve-prestige";
// import IconLogout from "../../icons/icon-logout";
// import IconYes from "../../icons/icon-yes";
// import IconCoveredRestorePrestige from "../../icons/icon-covered-restore-prestige";
import IconMastercard from "../../icons/icon-mastercard";
// import IconCreditCard from "../../icons/icon-credit-card";
// import IconMyClaims from "../../icons/icon-my-claims";
// import IconCreditCardNumber from "../../icons/icon-creditcardnumber";
// import IconMyDocuments from "../../icons/icon-my-documents";
// import IconDatepicker from "../../icons/icon-datepicker";
// import IconMyPayments from "../../icons/icon-my-payments";
// import IconDeliveryPerson from "../../icons/icon-delivery-person";
// import IconMyProfile from "../../icons/icon-my-profile";
// import IconEdit from "../../icons/icon-edit";
// import IconMyasurion from "../../icons/icon-myasurion";
// import IconEnquiry from "../../icons/icon-enquiry";
// import IconNameoncard from "../../icons/icon-nameoncard";
// import IconExpand from "../../icons/icon-expand";
// import IconNo from "../../icons/icon-no";
// import IconFacebook from "../../icons/icon-facebook";
// import IconNoteContact from "../../icons/icon-note-contact";
// import IconFb from "../../icons/icon-fb";
// import IconNoteNoWifi from "../../icons/icon-note-no-wifi";
// import IconFmip from "../../icons/icon-fmip";
// import IconNoteOS from "../../icons/icon-note-os";
// import IconGoogle from "../../icons/icon-google";
// import IconNoteSim from "../../icons/icon-note-sim";
// import IconGoogle1 from "../../icons/icon-google1";
// import IconNoteTracking from "../../icons/icon-note-tracking";
import IconHamburger from "../../icons/icon-hamburger";
// import IconNps from "../../icons/icon-nps";
// import IconHome from "../../icons/icon-home-img";
// import IconPayment2 from "../../icons/icon-payment";
// import IconHome2 from "../../icons/icon-home2";
import IconPdf from "../../icons/icon-pdf";
// import IconBackArrow from "../../icons/icon-img-backArrow";
// import IconPrestigeRepair from "../../icons/icon-prestige-repair";
// import IconChatLite from "../../icons/icon-img-chat-lite";
// import IconPrestigeResolve from "../../icons/icon-prestige-resolve";
// import IconChat from "../../icons/icon-img-chat";
// import IconProfile2 from "../../icons/icon-profile";
// import IconMobileplus from "../../icons/asurion-mobileplus";
// import IconDelete from "../../icons/icon-delete";
// import IconUnion from "../../icons/icon-union";
// import IconCloseCircle from "../../icons/icon-closecircle";
// import IconExpand1 from "../../icons/icon-expand";
// import IconUpload from "../../icons/icon-upload";
// import IconCollapse from "../../icons/icon-collapse";
// import IconGreenTick from "../../icons/icon-green-tick";
import Icon2ndPhn from "../../icons/icon-2ndphone";
//import IconMirrorApproach from "../../icons/icon-mirrorapproach";

const ICONMAP = {
    //   image: IconImage,
    //   IsScreenDamaged: IconIscreenDamaged,
    //   IconSecondPhone3,
    //   IconBatteryIssues,
    //   IconAmex,
    //   IconAndroidLogo,
    //   IconAppleLogo,
    //   IconDevicebroken,
    //   IconIphoneSetting,
    //   IconLost,
    //   IconMail,
    //   IconMaster,
    //   IconReturnDevice,
    //   IconSecondPhone,
    //   IconStolen,
    //   IconTruck2,
    //   IconVisa2,
    //   IconWaterDamaged,
    mobileplus: IconAsurionMobilePlus,
    //   IconDocument,
    //   IconQuestion,
    //   IconAsurionMobilePlus1,
    //   IconDots,
    secondPhn: IconSecondPhone2,
    //   IconMedWomen1,
    //   IconMobilephone,
    //   IconSecurityAdviser,
    //   IconAusPost,
    //   IconNews,
    //   IconSelectedStar,
    //   IconCancelClaim,
    //   IconPayment,
    close: IconSmallClosex,
    //   IconCancelLogoutNew,
    //   IconPlan2,
    soluto: IconSoluto,
    //   IconCancelLogout,
    //   IconProfile,
    //   IconSquareTick,
    //   IconChangeDevice,
    profile: IconProfile1,
    //   IconStar,
    //   IconChangePolicy,
    //   IconSecure,
    //   IconTechTips,
    //   IconChatAdviser,
    //   IconUseredit,
    //   IconTick,
    //   IconChceky,
    LetGetPrepared: IconLetGetPrepared,
    //   IconTooltip,
    //   IconClassicResolve,
    ingenico: IconIngenico,
    //   IconTruck,
    // close:  IconClosex,
    iPhoneImei: IconIPhoneImei,
    //   IconUploadFile,
    //   IconContact,
    iPhoneMirror: IconIphoneMirror,
    //   IconUserEdit,
    //   IconCoveredRepair,
    //   IconIphoneScreenshot,
    //   IconVideoSelfie,
    //   IconCoveredReplace,
    //   IconLiteResolve,
    //   IconVideoWoman,
    //   IconCoveredResolveClassic,
    //   IconLock,
    visa: IconVisa,
    //   IconCoveredResolveLite,
    //   IconLogoMobileplus,
    //   IconWipeClean,
    //   IconCoveredResolvePrestige,
    //   IconLogout,
    //   IconYes,
    //   IconCoveredRestorePrestige,
    mastercard: IconMastercard,
    //   IconCreditCard,
    //   IconMyClaims,
    //   IconCreditCardNumber,
    //   IconMyDocuments,
    //   IconDatepicker,
    //   IconMyPayments,
    //   IconDeliveryPerson,
    //   IconMyProfile,
    //   IconEdit,
    //   IconMyasurion,
    //   IconEnquiry,
    //   IconNameoncard,
    //   IconExpand,
    //   IconNo,
    //   IconFacebook,
    //   IconNoteContact,
    //   IconFb,
    //   IconNoteNoWifi,
    //   IconFmip,
    //   IconNoteOS,
    //   IconGoogle,
    //   IconNoteSim,
    //   IconGoogle1,
    //   IconNoteTracking,
    hamburger: IconHamburger,
    //   IconNps,
    //   IconHome,
    //   IconPayment2,
    //   IconHome2,
    pdf: IconPdf,
    //   IconBackArrow,
    //   IconPrestigeRepair,
    //   IconChatLite,
    //   IconPrestigeResolve,
    //   IconChat,
    //   IconProfile2,
    //   IconMobileplus,
    //   IconDelete,
    //   IconUnion,
    //   IconCloseCircle,
    //   IconExpand1,
    //   IconUpload,
    //   IconCollapse,
    //   IconGreenTick,
    //   Icon2ndPhn,
    //   IconMirrorApproach
};


export const Icon = ({
    icon,
    type,
    url,
    altIcon,
    property,
    initialValue,
    className,
    onChange = () => { }
}) => {
    const [value, setValue] = useState(initialValue ?? true);
    if (type === 'hyperlink') {
        return <a onClick={() => window.open(url, '_blank')} className="text-purple-500 cursor-pointer"><Image image={ICONMAP[icon]} className={className} /></a>
    }
    if (type === "toggle-button") {
        return <div onClick={() => { setValue(v => !v); onChange({ [property]: !value }) }}> <Image image={value ? ICONMAP[icon] : ICONMAP[altIcon]} className={`${className} cursor-pointer`} /></div>

    }
    return <Image image={ICONMAP[icon]} className={className} />
}

Icon.propTypes = {
    icon: PropTypes.oneOf(Object.keys(ICONMAP)),
    type: PropTypes.oneOf(['hyperlink', 'button', 'toggle-button']),
    url: PropTypes.string,
    altIcon: PropTypes.oneOf(Object.keys(ICONMAP)),
}

export default Icon
