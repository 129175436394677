import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

function NoVideoReason() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['NoVideoReason']
    const returnVideoCapture = findById(metadata, 'return-video-capture')
    const headerText = findById(metadata, 'headerText')
    const descriptiontext = findById(metadata, 'description-text')
    const subheaderText = findById(metadata, 'sub-header-text')
    let videoNotsure = workflowState['video-notsure'];
    const notSureToVideoOption = videoNotsure?.notSureToVideoOption
    console.log("notSureToVideoOption", notSureToVideoOption)
    const text = notSureToVideoOption === 'mirror' ? 'No Problem!' : (notSureToVideoOption === 'tooBusy' ? 'That’s Ok!' : (notSureToVideoOption === 'privacyConcerns' ? 'We Understand.' : (notSureToVideoOption === 'other' ? 'Please share other reason':'')))
    const textb = notSureToVideoOption === 'mirror' ? `I don't have a 2nd phone or mirror` : (notSureToVideoOption === 'tooBusy' ? `I'm too busy at this time` : (notSureToVideoOption === 'privacyConcerns' ? `I have privacy concerns` : (notSureToVideoOption === 'other' ? ``:'')))
    const textr = notSureToVideoOption === 'mirror' ? `Hey no worries. We'll send you an email with a link. You can return once you have a mirror or a 2nd device you can use.<br/><br/>
    When ready, come back to the site and we'll step through the process in a jiffy!<br/>` : (notSureToVideoOption === 'tooBusy' ? `Hey no worries. We'll send you an email with a link so that you can return once you have time.<br/><br/>
    When ready, come back to the site and we'll step through the process in a jiffy!<br/>` : (notSureToVideoOption === 'privacyConcerns' ? `Asurion cares very much about your privacy.<br/><br/>
    The camera is only enabled during the video check of your phone and you are in charge of pointing the phone / camera.<br/><br/>
    All information submitted is managed under Asurion’s strict privacy policy.<br/>` : (notSureToVideoOption === 'other' ? `In some cases we may not be able to help, but we are always keen to understand more.<br/><br/>
    Please enter your feedback`:''))) 
    
    headerText.args = {
        ...headerText.args,
        content: text
    }
    subheaderText.args = {
        ...subheaderText.args,
        content: textb
    }

    descriptiontext.args = {
        ...descriptiontext.args,
        content: textr
    }

    
    returnVideoCapture.args = {
        ...returnVideoCapture.args,
        onClick: s => {
            return completeCurrentStep(currentStep, {
                returnVideoCapture: true
            })
        }
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default NoVideoReason;