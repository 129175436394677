/* eslint-disable max-len */
import React from 'react'

const icon = ({ onClick, isSelected, className, fill }) => (
  <svg onClick={onClick} className={className} width="18" height="18" viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99996 15.6665C12.6819 15.6665 15.6666 12.6817 15.6666 8.99984C15.6666 5.31794 12.6819 2.33317 8.99996 2.33317C5.31806 2.33317 2.33329 5.31794 2.33329 8.99984C2.33329 12.6817 5.31806 15.6665 8.99996 15.6665ZM8.99996 17.3332C13.6023 17.3332 17.3333 13.6022 17.3333 8.99984C17.3333 4.39746 13.6023 0.666504 8.99996 0.666504C4.39759 0.666504 0.666626 4.39746 0.666626 8.99984C0.666626 13.6022 4.39759 17.3332 8.99996 17.3332ZM5.66663 7.33317C5.66663 5.49484 7.16163 3.99984 8.99996 3.99984C10.8383 3.99984 12.3333 5.49484 12.3333 7.33317C12.3333 8.884 11.2691 10.1898 9.83329 10.5615V11.4998H8.16663V8.99984H8.99996C9.91913 8.99984 10.6666 8.25234 10.6666 7.33317C10.6666 6.41317 9.91913 5.6665 8.99996 5.6665C8.08079 5.6665 7.33329 6.41317 7.33329 7.33317H5.66663ZM8.99996 13.9998C9.4602 13.9998 9.83329 13.6267 9.83329 13.1665C9.83329 12.7063 9.4602 12.3332 8.99996 12.3332C8.53972 12.3332 8.16663 12.7063 8.16663 13.1665C8.16663 13.6267 8.53972 13.9998 8.99996 13.9998Z"
      fill="black"
    // fill={isSelected ?"black":"#8223D2"}
    />
  </svg>
)

export default icon
