import metadata from '../../metadata/header.json';
import React from 'react'
import { Stack } from '../stack/stack'


export const Header = () => {
  return <Stack orientation='vertical' padding='px-4' metadata={metadata} />
}

export default Header
