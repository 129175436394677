import React from 'react'
import PropTypes from 'prop-types'

export const Select = ({
  label,
  options,
  value,
  marginClasses,
  paddingClasses,
  onSelect,
  disabled,
  ...props
}) => {
  return (
    <div
      className={`relative h-14 text-base rounded-md border border-gray-200 pr-2 focus-within:border-gray-500 focus-within:shadow-sm ${marginClasses} ${paddingClasses} ${disabled ? 'bg-gray-100 cursor-not-allowed' : ''
        }`}
    >
      <select
        onChange={e => onSelect(e.target.value)}
        value={value}
        disabled={disabled}
        className={`peer bg-transparent ${value ? 'pt-5' : ''
          }  peer focus:outline-none  h-14 px-2 w-full`}
      >
        {label && <option value=''>{label}</option>}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label
        className={`bg-transparent peer-placeholder-shown:opacity-100 opacity-50 peer-focus:opacity-75 peer-placeholder-shown:scale-100 scale-90 peer-focus:scale-90 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 px-3 pl-2 py-3 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out 
        ${value ? ' ' : 'hidden'}`}
      >
        {label}
      </label>
    </div>
  )
}

Select.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  label: PropTypes.string,

  options: PropTypes.arrayOf(PropTypes.object)
}

Select.defaultProps = {
  onSelect: e => { }
}
