import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";
import { config } from './config'

import Step1Image from '../../assets/2 phone step 1.png'
import Step2Image from '../../assets/2 phone step 2.png'
import Step3Image from '../../assets/2 phone step 3.png'
import Step4Image from '../../assets/2 phone step 4.png'
import Step5Image from '../../assets/2 phone step 5.png'
import Step6Image from '../../assets/2 phone step 6.png'

function IMEIVideo() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['ImeiVideo']
    const imeiVideoImg = findById(metadata, 'imeiVideoImg')
    const btnNext = findById(metadata, 'btnNext')
    let autoDetection = workflowState['auto-detection'];
    const deviceDetection = workflowState['device-detection']
    const ManualSelection = workflowState['manual-detection']
    let agreementDetails = workflowState['CreateAgreementResponse'];

    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const Make = detectionData.Make;
    const Model = detectionData.Model;
    const UTMUrl =''
    // images need to be chnaged
    imeiVideoImg.args = {
        ...imeiVideoImg.args,
        images: [
            { src: Step1Image, alt: 'slide-1' },
            { src: Step2Image, alt: 'slide-2' },
            { src: Step3Image, alt: 'slide-3' },
            { src: Step4Image, alt: 'slide-4' },
            { src: Step5Image, alt: 'slide-5' },
            { src: Step6Image, alt: 'slide-6' }
        ]
    }

    btnNext.args = {
        ...btnNext.args,
        onClick: s => {
            window.location.href = encodeURI(
                `${config.dhcPortalUrlMirror}?rid=${agreementDetails?.AgreementRequestId}&pid=1&pm=${Model}&home_url=${encodeURIComponent(
                    config.cognito.redirectSignOut
                )}&${UTMUrl}`
            )
        }
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default IMEIVideo;