import React from 'react'
import PropTypes from 'prop-types'

export const Input = (props) => {
  const {
    enabled,
    label,
    value,
    type,
    fontClasses,
    dimensionClasses,
    colorClasses,
    borderClasses,
    paddingClasses,
    marginClasses,
    displayClasses,
    onChange,
    maxlength,
    tabIndex,
  } = props
  return (
    <div
      className={`relative ${fontClasses} ${dimensionClasses} ${colorClasses} ${paddingClasses} ${marginClasses}`}
    >
      <input
        type={type}
        className={`peer ${
          label ? 'pt-7' : ''
        } ${borderClasses} ${displayClasses} ${fontClasses}  w-full p-3 h-10 placeholder-transparent`}
        placeholder={label}
        value={value}
        enabled={enabled}
        maxLength={maxlength}
        tabIndex={tabIndex}
        onChange={e => onChange(e.target.value)}
      />
      <label className={`peer-placeholder-shown:opacity-100 opacity-75 peer-focus:opacity-75 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-black text-gray-500 scale-80 peer-focus:scale-80 peer-placeholder-shown:translate-y-0 -translate-y-3 peer-focus:-translate-y-3 peer-placeholder-shown:translate-x-0 translate-x-1 peer-focus:translate-x-1 absolute top-0 left-0 py-3 px-2 h-full pointer-events-none transform origin-left transition-all duration-100 ease-in-out ${fontClasses}`}>
        {label}
      </label>
    </div>
  )
}

Input.propTypes = {
  /**
   * enable: Indicates if button is enabled or disabled
   */
  enabled: PropTypes.bool,
  /**
   * label: Text to be displayed on button
   */
  label: PropTypes.string,
  /**
   * value: Value of the input control
   */
  value: PropTypes.string,
  /**
   * type: Type of the input control
   */
  type: PropTypes.string,
  /**
   * fontClasses: Classes to manage fonts of the control
   */
  fontClasses: PropTypes.string,
  /**
   * dimensionClasses: Classes to manage dimensions of the control
   */
  dimensionClasses: PropTypes.string,
  /**
   * colorClasses: Classes to manage foreground and background colors of the control
   */
  colorClasses: PropTypes.string,
  /**
   * borderClasses: Classes to manage border of the control
   */
  borderClasses: PropTypes.string,
  /**
   * paddingClasses: Classes to manage padding of the control
   */
  paddingClasses: PropTypes.string,
  /**
   * marginClasses: Classes to manage margin of the control
   */
  marginClasses: PropTypes.string,
  /**
   * displayClasses: Classes to manage display of the control
   */
  displayClasses: PropTypes.string,
  /**
   * onChange: On Click event handler for button
   */
  onChange: PropTypes.func,
  /**
   * maxlength: max length for input
   */
  maxlength: PropTypes.string,
  /**
   * tabIndex: tabIndex for input
   */
  tabIndex: PropTypes.string,
  /**
   * onFocus: On focus event handler for input
   */
  onFocus: PropTypes.func,
  /**
   * onKeyUp: On key up event handler for input
   */
  onKeyUp: PropTypes.func
}

Input.defaultProps = {
  enabled: true,
  label: 'Label',
  value: '',
  type: 'text',
  fontClasses: 'text-base',
  dimensionClasses: '',
  colorClasses: '',
  borderClasses:
    'border border-gray-200 focus:outline-none rounded-md focus:border-gray-500 focus:shadow-sm',
  paddingClasses: '',
  marginClasses: 'mb-5',
  displayClasses: '',
  onChange: undefined,
  onFocus: undefined,
  onKeyUp: undefined,
  maxlength: '',
  tabIndex: ''
}
