import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById, validateFileType } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import axios from "axios";
import Promise from 'bluebird'

function BypassIMEIInstructions() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const [uploadedFile, setSelectedFile] = useState('')
    const metadata = workflowState['config']?.metadata['BypassimeiinstructionPage']
    const btnCompletesubm = findById(metadata, 'btnCompletesubm')
    const uploadFl = findById(metadata, 'upload')
    let agreementDetails = workflowState['CreateAgreementResponse'];
    let byPassImeiInstrDetails = workflowState['bypass-imei-instructions']
    let receiptUploadDetails = workflowState['receipt-upload']
    const deviceDetection = workflowState['device-detection']
    const ManualSelection = workflowState['manual-detection']
    let autoDetection = workflowState['auto-detection'];

    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const make = detectionData.Make;

    const [dataCleared, setDataCleared] = useState(false)
    const [fileUploadError, setFileUploadError] = useState()
    const [fileName, setFileName] = useState(null)
    const [loaded, setProgressBarValue] = useState(0)
    const [moreThan15Mb, setMoreThan15Mb] = useState(false)
    const [enableUploadBtn, setEnableUploadButton] = useState()

    let clearImeiDetails = () => {
        updateState({
            ...workflowState, [currentStep]: {
                ...workflowState[currentStep],
                selectedReceiptUpload: [],
                PresignUrlResult: undefined,
                selectedFile: [],
                selectedImeiScreenShot: []
            }
        });
    }
    useEffect(() => {
        setEnableUploadButton(false)
        clearImeiDetails()
        setProgressBarValue(0)
        setDataCleared(true)
    }, [])
    useEffect(() => {
        if (byPassImeiInstrDetails?.selectedImeiScreenShot?.length && byPassImeiInstrDetails?.PresignUrlResult) {
            setDataCleared(true)
            let UpdateAgreementRequest = {
                CreateAgreementActivityRequest: {
                    AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
                    IMEIBucket: byPassImeiInstrDetails?.PresignUrlResult?.[0]?.Bucket,
                    IMEIPath: byPassImeiInstrDetails?.PresignUrlResult?.[0]?.Path1,
                    ReceiptProvider: receiptUploadDetails?.selectedReciptProvider,
                    AgreementRequestStatus: 'BYPASS_VHC_IMEI_PAGE',
                }
            }
            API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
                updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
            })
        }
    }, [byPassImeiInstrDetails?.selectedImeiScreenShot, byPassImeiInstrDetails?.PresignUrlResult])

    const uploadToS3 = (url, document, formData) => {
        return axios({
            method: 'put',
            url: url,
            headers: {
                Accept: 'application/json',
                'Content-Type': document.type,
            },
            data: document,
            onUploadProgress: (progressEvent) => {
                const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                setProgressBarValue(progress)
            },
            formData: formData,
        })
            .then((response) => {
                let uniqueId = agreementDetails?.AgreementRequestId || '111111111'
                let params = {
                    GetAssetDetailsRequest: {
                        FileDetails: {
                            Bucket: byPassImeiInstrDetails?.PresignUrlResult?.[0]?.Bucket,
                            Key: `buy-now/${uniqueId}/${document.name}`//PresignUrlResult && PresignUrlResult[0] && PresignUrlResult[0].Path1,
                        },
                        DeviceMake: make && make.toUpperCase(),
                    },
                }
                setEnableUploadButton(false)
                // actions.getAssetDetailsFromImeiScreenShot(params)
            })
            .catch((error) => {
            })
    }

    let handleChange = (e) => {
        setDataCleared(false)
        if (!validateFileType(e.target.files[0].name)) {
            clearImeiDetails()
            setFileUploadError('Invalid file format')
        } else {
            setFileUploadError(null)
            setFileName(e.target.files[0].name)
            updateState({
                ...workflowState, [currentStep]: {
                    ...workflowState[currentStep],
                    selectedImeiScreenShot: [...e.target.files]
                }
            });
        }
    }

    useEffect(() => {
        if (byPassImeiInstrDetails?.selectedImeiScreenShot[0]) {
            let fileSizeInKB = byPassImeiInstrDetails?.selectedImeiScreenShot[0]?.size / 1024
            if (fileSizeInKB && fileSizeInKB <= 102400) {
                setMoreThan15Mb(false)
                if (byPassImeiInstrDetails?.selectedImeiScreenShot.length > 0) {
                    getPresignUrl()
                }
            } else {
                setMoreThan15Mb(true)
                clearImeiDetails()
            }
        }
    }, [byPassImeiInstrDetails?.selectedImeiScreenShot])

    useEffect(() => {
        if (byPassImeiInstrDetails?.PresignUrlResult?.length > 0) {
            Promise.map(byPassImeiInstrDetails?.selectedImeiScreenShot, (document, index) => {
                const formData = new FormData()
                formData.append('file', document)
                let filterUrl =
                    byPassImeiInstrDetails?.PresignUrlResult?.filter((u) => u.Path1.split('/')[2] === document.name)
                return uploadToS3(filterUrl[0].PreSignedURL, document, formData)
            })
        }
    }, [byPassImeiInstrDetails?.PresignUrlResult])

    const getPresignUrl = (e) => {
        let files = byPassImeiInstrDetails?.selectedImeiScreenShot.map((file) => {
            return {
                ClaimId: agreementDetails?.AgreementRequestId || '111111111',
                FileName: file.name,
                FolderType: 'buy-now',
            }
        })
        let getPresignUrlRequest = {
            FileUploadRequest: {
                Files: files,
            },
        }
        API[ActionTypes.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST](getPresignUrlRequest).then(data => {
            updateState({
                ...workflowState, getPresignUrlResponse: data.data.Files, [currentStep]: {
                    ...workflowState[currentStep],
                    PresignUrlResult: data.data.Files
                }
            });
        })
        //actions.getPresignUrlForUpload({ getPresignUrlRequest: getPresignUrlRequest, id: 'upload-photo' })
    }

    const removeFile = () => {
        setEnableUploadButton(true)
        setProgressBarValue(0)
        clearImeiDetails()
    }

    btnCompletesubm.args = {
        ...btnCompletesubm.args,
        enabled: byPassImeiInstrDetails?.selectedImeiScreenShot?.length > 0 ? true : false,
        onClick: e => {
            return completeCurrentStep(currentStep, {
                navigateToPolicy: true
            })
        }
    }
    uploadFl.args = {
        ...uploadFl.args,
        handleChange: e => handleChange(e),
        selectedFile: byPassImeiInstrDetails?.selectedImeiScreenShot?.[0]?.name,
        progress: loaded,
        removeFile: removeFile
    }

    return <Stack orientation='vertical' metadata={metadata} />
}

export default BypassIMEIInstructions;