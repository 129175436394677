import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image } from '../image/image'
import IconUnion from '../../icons/icon-union'
import IconClose from '../../icons/icon-closecircle'
const sizemap = {
  xs: 'xs',
  sm: 'sm',
  md: 'base',
  lg: 'lg',
  '2xl': '2xl'
}
export const Tooltip = ({
  showLabel,
  showIcon,
  labelText,
  tooltipText,
  toolTipValue,
  size,
  color,
  justification,
  ...props
}) => {
  const [toolTip, setTooltip] = useState('')
  const className = toolTip === toolTipValue ? 'visible' : 'hidden'
  const fontSize = `text-${sizemap[size]}`
  return (
    <div className>
      <div className={`flex flex-row items-center ${justification}`}>
        {showLabel && (
          <span
            className={`${fontSize} ${color} cursor-pointer`}
            onClick={() => setTooltip(toolTipValue)}
            dangerouslySetInnerHTML={{ __html: labelText }} 
          />
          

        )}
        {showIcon && (
          <span
            className='mx-2 first-letter: cursor-pointer'
            onClick={() => setTooltip(toolTipValue)}
          >
            <Image image={IconUnion} />
          </span>
        )}
      </div> 
      <div
        className={`absolute ${className} z-10 mx-3 p-2 pt-1 flex flex-col  text-sm rounded-md  border text-blue-700 shadow-lg border-blue-700  relative `}
        style={{ backgroundColor: '#F0F3FF' }}
      >
        <span
          className='self-end cursor-pointer'
          onClick={() => setTooltip('')}
        >
          <Image image={IconClose} />
        </span>
        <div
          className='text-sm'
          dangerouslySetInnerHTML={{ __html: tooltipText }}
        
        />
      </div>
    </div>
  )
}

Tooltip.propTypes = {
  /**
   * Is the control for label
   */
  labelText: PropTypes.string,
  /**
   * Is the control for tooltip text
   */
  tooltipText: PropTypes.string,
  /**
   * Is the control for tooltip value
   */
  toolTipValue: PropTypes.string,
  /**
   * Is the control to show/hide label
   */
  showLabel: PropTypes.bool,
  /**
   * Is the control to show/hide icon
   */
  showIcon: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', '2xl']),
  color: PropTypes.string
}

Tooltip.defaultProps = {
  labelText: 'This is label with tooltip',
  tooltipText: 'this is tooltip text',
  toolTipValue: 'toInsure',
  showLabel: true,
  showIcon: true,
  size: 'md',
  color: 'text-blue-700'
}
