import React, { useState} from 'react'
import { formatModelName, getPrice } from '../helper'
import IconUnion from '../../../icons/icon-union'
import IconClose from '../../../icons/icon-closecircle'
import IconCross from '../../../icons/icon-closex'
import IconCheck from '../../../icons/icon-chceky'
import IconClose1 from '../../../icons/icon-small-closex'

const PlanPriceHeader = ({ planDetails, planName, isNewPhone }) => {
    let monthlyFee = getPrice('MONTHLY', planDetails[planName.toUpperCase()], isNewPhone)
    let yearlyFee = getPrice('YEARLY', planDetails[planName.toUpperCase()], isNewPhone)

    const monthlyPriceForYearlyFee = (Number(yearlyFee) / 12)?.toFixed(2)?.toString()

    return (
        <div className=" flex flex-col py-0 w-1/4">
            <div className="flex items-center md:min-h-34 min-h-46 justify-center border border-gray.v1 py-2">
                <p className="md:text-sm text-lg font-bold">
                    {planName ? planName : <p className="invisible">None</p>}
                </p>
            </div>
            <div className="flex items-center justify-center border border-gray.v1 py-2">
                <p className={`${!(isNaN(monthlyFee)) ? 'text-gray.v2 font-bold' : 'text-black'} md:text-xs text-lg`}>
                    {!(isNaN(monthlyFee)) ? '$' + monthlyFee : 'Pay monthly'}
                </p>
            </div>
            <div className="flex items-center justify-center border border-gray.v1 py-2">
                <p className={`${!(isNaN(monthlyPriceForYearlyFee)) ? 'text-gray.v2 font-bold' : 'text-black'} md:text-xs text-lg`}>
                    {!(isNaN(monthlyPriceForYearlyFee)) ? '$' + monthlyPriceForYearlyFee : 'Pay yearly'}
                </p>
            </div>
            <div className={`flex items-center justify-center border border-gray.v1 ${(!(isNaN(monthlyPriceForYearlyFee)) && !(isNaN(monthlyFee))) ? 'md:py-p2 py-2' : 'py-2'}`}>
                <p className={`${(!(isNaN(monthlyPriceForYearlyFee)) && !(isNaN(monthlyFee))) ? 'py-2' : 'py-1'} md:text-xs text-lg`}>
                    {(!(isNaN(monthlyPriceForYearlyFee)) && !(isNaN(monthlyFee))) ? <IconCheck /> : 'Cancel anytime'}
                </p>
            </div>
        </div>
    )
}

const CategoryRow = ({ setTooltip, toolTip, text = '', toolTipText }) => {
    return (
        <p className="md:text-sm md:leading-21 text-lg font-bold pl-p10 md:pt-0 text-blue.v1">
            <div className="relative ">
                <div className={`absolute ${toolTip === text ? 'visible' : 'hidden'}  top-40 z-10 mx-5 p-2 flex flex-col text-14 rounded-md border text-blue-700 shadow-lg border-blue-700`} style={{ backgroundColor: '#F0F3FF' }}>
                    <span className="self-end cursor-pointer" onClick={() => setTooltip('')} ><IconClose /></span>
                    <p className="text-sm leading-18 z-10"> {toolTipText}</p>
                </div>
                <div className="">
                    <span className="inline-block text-xl md:text-base md:leading-21">
                        {text}
                    </span>
                    <span className="absolute inline-block ml-m5 cursor-pointer top-2p" onClick={() => setTooltip(text)} > <IconUnion fill='black' isSelected={true} /></span> {/*isSelected={toolTip === text} */}
                </div>
            </div>
            {/* <span>{text}</span>
      <img className="inline-block ml-4" data-tip data-for={text} src={require('./../../../assets/question.png')} alt={`${text}-question`} />
      <ReactTooltip id={text} place="right" effect="solid" className="w-235 ml-40 md:ml-0">
       
      </ReactTooltip> */}
        </p>
    )
}

const SubCategoryRow = ({ lite, classic, prestige, title, sup }) => {
    return (
        <div className=" flex flex-row py-0 border-t border-gray.v1">
            {/* <div className="flex flex-row w-3/4 justify-evenly"> */}
            <div className="flex items-center w-1/4 justify-center border-r border-gray.v1">
                <p className="md:text-xs text-lg text-blue.v2 font-bold">
                    {lite && lite}
                </p>
            </div>
            <div className="flex items-center w-1/4 justify-center border-r border-gray.v1">
                <p className="md:text-xs text-lg text-blue.v2 font-bold">
                    {classic && classic}
                </p>
            </div>
            <div className="flex items-center w-1/4 justify-center border-r border-gray.v1">
                <p className="md:text-xs text-lg text-blue.v2 font-bold">
                    {prestige && prestige}
                </p>
                {/* </div> */}
            </div>
            <div className="flex ml-0 w-1/4 justify-center">
                {/* <p className="md:text-xs text-lg font-bold w-max text-Asurion-Gray-Deepest py-3">{title === 'Knowledgebase' ? 'Knowledge Base' : title}</p> */}
                <div
                    className={`md:text-xs text-lg font-bold w-max text-gray.v2 py-2`}
                    dangerouslySetInnerHTML={{ __html: title === 'Knowledgebase' ? 'Knowledge Base' : title }}
                ></div>
                {((sup > 0 || sup !== "") && sup !== 0) &&
                    <sup className={`pt-2 text-xs text-purple-1 inherit ml-2 font-bold`}>{sup}</sup>
                }
            </div>
        </div>
    )
}

const CheckImage = () => {
    return <IconCheck />
}
const CrossImage = () => {
    return <IconCross />
}

const ComparePlan = (props) => {
    const { planDetails, selectedModel, make, isNewPhone, showHeader = true } = props

    const searchByFeature = (plan, feature, featureType) => {
        const featureValue =
            planDetails &&
            planDetails[plan] &&
            planDetails[plan][feature] &&
            planDetails[plan][feature].types &&
            planDetails[plan][feature].types.find((element) => element?.feature === featureType)
        return featureValue?.value < 0 ? 'Unlimited' : featureValue?.value
    }

    let formmatedModelName = formatModelName(make, selectedModel)
    const [toolTip, setTooltip] = useState('')

    return (
        <>
            <div className={`w-auto mx-0 bg-white py-0 rounded-none`}>

                {showHeader && (
                    <div className={`mt-7 flex flex-row ${props.hideBlock != false ? 'justify-center' : 'justify-between mr-20 md:m10 xxs:mr-m9'}`}>
                        <div>
                            <p className="text-xl leading-26 ml-5 font-bold">{formmatedModelName} Compare Plans</p>
                        </div>
                        <div className="self-center">
                            <IconClose1
                                onClick={(e) => props.closePopUp(false)}
                                className={`${props.hideBlock != false ? 'hidden' : 'block'} ml-auto" alt="menu-img`} />

                        </div>
                    </div>
                )}
                <div className=' border border-light-gray-4 rounded-md '>
                    <div className="py-1 bg-blue.v3 border-t border-gray.v1">
                        <CategoryRow setTooltip={setTooltip} toolTip={toolTip} text="Repair" toolTipText="Genuine screen repair using original equipment manufacturer parts" />
                    </div>
                    <div className="flex flex-col">
                        <SubCategoryRow title="Screen Repair" sup={1}
                            lite={<>{searchByFeature('LITE', 'Repairs', 'Repairs / 12 months')}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Repairs', 'Repairs / 12 months')}</>}
                            prestige={<> {searchByFeature('PRESTIGE', 'Repairs', 'Repairs / 12 months')}</>}
                        />
                        <SubCategoryRow title="Excess" sup={0}
                            lite={<>${planDetails['LITE']['Repairs'].surFee}</>}
                            classic={<>${planDetails['CLASSIC']['Repairs'].surFee}</>}
                            prestige={<>${planDetails['PRESTIGE']['Repairs'].surFee}</>} />
                    </div>
                    <div className="py-1 bg-blue.v3 border-t">
                        <CategoryRow setTooltip={setTooltip} toolTip={toolTip} text="Battery Replacement " toolTipText="Apple phones are eligible for a battery replacement if they are aged between 24-36 months from the original purchase date and where the battery state of health is below 80%. One battery replacement is included. Prestige Policy only." />
                    </div>
                    <div className="flex flex-col">
                        <SubCategoryRow title="Battery" sup={2}
                            lite={<>{searchByFeature('LITE', 'Replacement', 'Battery Replacement') || <CrossImage />}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Replacement', 'Battery Replacement') || <CrossImage />}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Replacement', 'Battery Replacement') || <CrossImage />}</>}
                        />
                        <SubCategoryRow title="Excess" sup={0}
                            lite={<><CrossImage /></>}
                            classic={<><CrossImage /></>}
                            prestige={<>$29</>} />
                    </div>
                    <div className="mt-0 py-1 bg-blue.v3 border-t">
                        <CategoryRow setTooltip={setTooltip} toolTip={toolTip} text="Replace" toolTipText="We’ll replace your phone “like for like” when damaged" />
                    </div>
                    <div className="flex flex-col">
                        <SubCategoryRow title="<span class='mb-m5'>Damaged</span><br />Phone" sup={"1,3"}
                            lite={<>{searchByFeature('LITE', 'Swap', 'Swap - Damage') ? (<CrossImage />) : (<CrossImage />)}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Swap', 'Swap - Damage')}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Swap', 'Swap - Damage')}</>}
                        />
                        <SubCategoryRow title="Excess" sup={0}
                            lite={<><CrossImage /></>}
                            classic={<>${planDetails['CLASSIC']['Swap'].swapFee}</>}
                            prestige={<>${planDetails['PRESTIGE']['Swap'].swapFee}</>}
                        />
                    </div>
                    <div className="py-1 bg-blue.v3 border-t">
                        <CategoryRow setTooltip={setTooltip} toolTip={toolTip} text="Restore" toolTipText="We’ll replace your phone “like for like” when lost or stolen" />
                    </div>
                    <div className="flex flex-col">
                        <SubCategoryRow title="Lost or Stolen" sup={1}
                            lite={<>{searchByFeature('LITE', 'Replacement', 'Lost') ? (<CheckImage />) : (<CrossImage />)}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Replacement', 'Lost') ? (<CheckImage />) : (<CrossImage />)}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Replacement', 'Lost')}</>}
                        />
                        <SubCategoryRow title="Excess" sup={0}
                            lite={<><CrossImage /></>}
                            classic={<><CrossImage /></>}
                            prestige={<> ${planDetails['PRESTIGE']['Replacement'].replacementFee}</>}
                        />
                    </div>
                    <div className="py-1 bg-blue.v3 border-t">
                        <CategoryRow setTooltip={setTooltip} toolTip={toolTip} text="Resolve" toolTipText="Technical support when you need help with your phone" />
                    </div>
                    <div className="flex flex-col">
                        <SubCategoryRow title="Knowledgebase" sup={0}
                            lite={<>{searchByFeature('LITE', 'Soluto', 'Knowledgebase') === 'Y' ? (<CheckImage />) : (<p className="md:text-xs text-lg text-blue.v2 font-bold">${searchByFeature('LITE', 'Soluto', 'Knowledgebase')}</p>)}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Soluto', 'Knowledgebase') === 'Y' ? (<CheckImage />) : (<p className="md:text-xs text-lg text-blue.v2 font-bold">${searchByFeature('CLASSIC', 'Soluto', 'Knowledgebase')}</p>)}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Soluto', 'Knowledgebase') === 'Y' ? (<CheckImage />) : (<p className="md:text-xs text-lg text-blue.v2 font-bold">${searchByFeature('PRESTIGE', 'Soluto', 'Knowledgebase')}</p>)}</>}
                        />
                        <SubCategoryRow title="Virtual Assistant" sup={0}
                            lite={<>{searchByFeature('LITE', 'Soluto', 'Virtual Assist') === 'N' ? (<CrossImage />) : (<CheckImage />)}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Soluto', 'Virtual Assist') === 'N' ? (<CrossImage />) : (<CheckImage />)}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Soluto', 'Virtual Assist') === 'N' ? (<CrossImage />) : (<CheckImage />)}</>}
                        />
                        <SubCategoryRow title="Tech Experts" sup={0}
                            lite={<>{searchByFeature('LITE', 'Soluto', 'Personal Assist') === 'Y' ? (<CheckImage />) : (<CrossImage />)}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Soluto', 'Personal Assist') === 'Y' ? (<CheckImage />) : (<CrossImage />)}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Soluto', 'Personal Assist') === 'Y' ? (<CheckImage />) : (<CrossImage />)}</>}
                        />
                        <SubCategoryRow title="Security Advisor" sup={0}
                            lite={<>{searchByFeature('LITE', 'Soluto', 'Security Advisor') === 'N' ? (<CrossImage />) : (<CheckImage />)}</>}
                            classic={<>{searchByFeature('CLASSIC', 'Soluto', 'Security Advisor') === 'N' ? (<CrossImage />) : (<CheckImage />)}</>}
                            prestige={<>{searchByFeature('PRESTIGE', 'Soluto', 'Security Advisor') === 'N' ? (<CrossImage />) : (<CheckImage />)}</>}
                        />
                    </div>
                    <div className="flex flex-col border-t border-gray.v1">
                        <div className="flex flex-col py-2 mr-0  ml-m8 ">
                            <p className='bg-white text-purple md:text-sm text-lg font-bold mt-m5'>Monthly Premium</p>
                            <p className='bg-white md:text-sm text-lg my-m5'>Pricing includes the new phone discount</p>
                        </div>
                        <div className='flex flex-row justify-center'>
                            <PlanPriceHeader
                                bgColor={'white'}
                                textColor={'black'}
                                planName="Lite"
                                className="mr-m5"
                                planDetails={planDetails}
                                isNewPhone={isNewPhone}
                            />
                            <PlanPriceHeader
                                textColor={'black'}
                                bgColor={'white'}
                                planName="Classic"
                                className="mr-m5"
                                planDetails={planDetails}
                                isNewPhone={isNewPhone}
                            />
                            <PlanPriceHeader
                                textColor={'black'}
                                bgColor={'white'}
                                planName="Prestige"
                                className="mr-m5"
                                planDetails={planDetails}
                                isNewPhone={isNewPhone}
                            />
                            <PlanPriceHeader
                                textColor={'black'}
                                bgColor={'white'}
                                planName=""
                                className="mr-m5"
                                planDetails={planDetails}
                                isNewPhone={isNewPhone}
                            />
                        </div>
                    </div>
                </div>
                <div className="md:mx-m5 ml-200 mt-30">
                    <p className="md:text-xs mb-0 text-base"><span className="text-purple-1 font-bold mr-m8">1</span>Claims allowed in 12 month period</p>
                    <p className="md:text-xs text-base mb-0"><span className="text-purple-1 font-bold mr-6">2</span>Applicable to iPhone devices 24-36 months old</p>
                    <p className="md:text-xs text-base"><span className="text-purple-1 font-bold mr-6">3</span>Damaged device returned to Asurion</p>
                </div>

            </div>
        </>
    )
}
export default ComparePlan
