import { useContext, useEffect, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { filterDeviceSpecs, findById, getPrice, returnDAXSupportedDevices } from '../enrollment-workflow/helper'
import { useNavigate } from "react-router-dom";
import ActionTypes from '../../ActionTypes'
import API from '../../services'
import colorCodes from './device-colors.json'
function AutoDetection() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const navigate = useNavigate();
  const metadata = workflowState['config']?.metadata['AutoDetection']
  const switchBtn = findById(metadata, 'switchBtn')
  const btnNext = findById(metadata, 'btnNext')
  const selectPhn = findById(metadata, 'selectPhn')
  const phnColor = findById(metadata, 'phnColor')
  const phnSize = findById(metadata, 'phnSize')
  const radioBtn = findById(metadata, 'radioBtn')
  const priceContent = findById(metadata, 'priceContent')
  const capacityTooltip = findById(metadata, 'capacityTooltip')

  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState({
    selectedMake: undefined,
    selectedModel: undefined,
    selectedDeviceColor: undefined,
    selectedDeviceCapacity: undefined,
    paymentMethod: 'Annual',
    isNewPhone: true,
    discountApplied: ['DIGITAL PROMOTIONAL DISCOUNT']
  })
  let autoDetection = workflowState['auto-detection'];
  let autoDetectedDevices = workflowState['device-detection'].autoDetectedDevices
  let monthlyFee, yearlyFee, desimalNumber, formattedPrice;

  let detectedBrand = autoDetectedDevices[0].brand
  let deviceData = workflowState['config']?.DeviceDetails
  const modelsByMake = deviceData?.filter(
    ({ AssetMakeName }) => AssetMakeName?.toUpperCase() === detectedBrand.toUpperCase()
  )
  let detectedModels = returnDAXSupportedDevices(modelsByMake, autoDetectedDevices)
  if (!detectedModels.length) {
    navigate("/manual-detection")
  }
  let { deviceModels, deviceSizes, deviceColors } = filterDeviceSpecs(detectedModels, selectedDeviceDetails?.selectedModel, '', 'coolade')
  deviceModels.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
  deviceSizes?.sort((a, b) => (+a > +b ? 1 : -1))

  let _options = deviceModels.map((ele, i) => {
    return {
      label: ele.replace('Iphone', 'iPhone'),
      value: ele.replace('Iphone', 'iPhone'),
    }
  })
  _options.push({ label: 'My phone is not listed', value: 'My phone is not listed' })

  let createAgreement = async () => {
    const CreateAgreementRequest = {
      CreateAgreementRequest: {
        FirstName: '',
        Flow: 'COOLADE',
        InteractionLineId: workflowState['config']?.InteractionLineId,
        ClientId: workflowState['config']?.ClientId,
        ClientChannelId: workflowState['config']?.ClientChannelId,
        PromotionId: '',
        GAClientId: '',
        UTMId: '',
        AgreementRequestStatus: 'INSURANCE_DUTY',
      },
    }
    API[ActionTypes.CREATE_AGREEMENT_REQUEST](CreateAgreementRequest).then(data => {
      updateState({ ...workflowState, CreateAgreementResponse: data.data.CreateAgreementResponse });
    })
  }
  let getAllQuotes = (selectedDevice) => {
    /* get all quotes request */
    let { deviceSizes } = getDeviceSizeColor(selectedDevice?.selectedModel)
    let filterAssets = deviceData?.filter(
      ({ AssetMakeName, Family, Capacity }) => AssetMakeName?.toUpperCase() === selectedDevice?.selectedMake?.toUpperCase() &&
        Family === selectedDevice?.selectedModel && Capacity === deviceSizes[0]
    )
    const GetAllQuotesRequest = {
      GetAllQuotesRequest: {
        ClientId: workflowState['config']?.ClientId,
        AssetCatlogIds: filterAssets?.map(v => v.AssetCatlogId),
        ClientOfferName: "LITE"
      }
    }
    if (filterAssets.length > 0) {
      API[ActionTypes.GET_ALL_QUOTE_REQUEST](GetAllQuotesRequest).then(data => {
        updateState({ ...workflowState, GetAllQuotesResponse: data.data });
      })
    }
    /* get all quotes request */
  }
  if (workflowState['GetAllQuotesResponse'] && workflowState['GetAllQuotesResponse'].quoteData && workflowState['GetAllQuotesResponse'].PromotionalOffer) {
    let lowLitePrice = workflowState['GetAllQuotesResponse']?.quoteData?.sort((a, b) => (+a.YearlyEnrollmentFee > +b.YearlyEnrollmentFee ? 1 : -1))
    let discount = selectedDeviceDetails?.discountApplied
    let otherDiscount = discount?.filter((v) => v !== 'DIGITAL PROMOTIONAL DISCOUNT').filter((v) => v !== 'NEWPH_DISC')
    monthlyFee = getPrice('MONTHLY', lowLitePrice[0], selectedDeviceDetails?.isNewPhone, otherDiscount[0], workflowState['GetAllQuotesResponse']?.PromotionalOffer)
    yearlyFee = getPrice('YEARLY', lowLitePrice[0], selectedDeviceDetails?.isNewPhone, otherDiscount[0], workflowState['GetAllQuotesResponse']?.PromotionalOffer)
    const price = selectedDeviceDetails?.paymentMethod?.toUpperCase() === 'MONTHLY' ? monthlyFee : yearlyFee
    formattedPrice = price?.indexOf('.') > -1 ? price?.split('.') : [price, '00']
    desimalNumber = formattedPrice[1] && formattedPrice[1]
    desimalNumber = desimalNumber ? (desimalNumber.length === 1 ? desimalNumber + '0' : desimalNumber) : '00'

  }
  const confirmDeviceDetails = () => {
    let confirmedDeviceDetails = deviceData?.filter(
      ({ AssetMakeName, Family, Capacity, ClientColor }) => AssetMakeName?.toUpperCase() === selectedDeviceDetails?.selectedMake?.toUpperCase() &&
        Family === selectedDeviceDetails?.selectedModel && Capacity === selectedDeviceDetails?.selectedDeviceCapacity?.replace('GB', '') &&
        ClientColor === selectedDeviceDetails?.selectedDeviceColor
    )[0]
    let agreementDetails = workflowState['CreateAgreementResponse'];
    const GetQuoteRequest = {
      GetQuoteRequest: {
        BaseItemId: confirmedDeviceDetails?.ItemId,
        ClientId: workflowState['config']?.ClientId,
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AssetCatlogId: confirmedDeviceDetails?.AssetCatlogId,
      },
    }
    const UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AssetCatalogId: confirmedDeviceDetails?.AssetCatlogId,
        ItemId: confirmedDeviceDetails?.ItemId,
        IsNewPhone: selectedDeviceDetails?.isNewPhone,
        AgreementRequestStatus: 'DEVICE_SELECTION',
      },
    }
    API[ActionTypes.GET_QUOTE_REQUEST](GetQuoteRequest).then(data => {
      updateState({ ...workflowState, GetQuoteResponse: data.data.plans });
      API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
        updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        return completeCurrentStep(currentStep, {
          Make: selectedDeviceDetails?.selectedMake,
          Model: selectedDeviceDetails?.selectedModel,
          Color: selectedDeviceDetails?.selectedDeviceColor,
          Size: selectedDeviceDetails?.selectedDeviceCapacity,
          AutoDetected: true,
          DeviceSelected: true,
          isNewPhone: selectedDeviceDetails?.isNewPhone,
          discountApplied: selectedDeviceDetails?.discountApplied,
          confirmedDeviceDetails: confirmedDeviceDetails
        })
      })
    })
  }
  let getDeviceSizeColor = (selectedModel) => {
    let { deviceSizes, deviceColors } = filterDeviceSpecs(detectedModels, selectedModel, '', 'coolade')
    deviceSizes?.sort((a, b) => (+a > +b ? 1 : -1))
    return { deviceSizes, deviceColors }
  }
  let setSelectedDevice = (device) => {
    setSelectedDeviceDetails({
      ...selectedDeviceDetails,
      ...device
    })
  }
  useEffect(() => {
    setSelectedDevice({
      selectedMake: detectedBrand,
      selectedModel: _options[0].label
    })
    createAgreement()
    getAllQuotes({
      selectedMake: detectedBrand,
      selectedModel: _options[0].label,
    })
  }, [])

  const setSelectedPhoneData = device => {
    let model = _options.find(d => d.label == device).label
    setSelectedDevice({
      selectedModel: model,
      selectedDeviceColor: undefined,
      selectedDeviceCapacity: undefined,
      isNewPhone: undefined
    })

    getAllQuotes({
      selectedMake: detectedBrand,
      selectedModel: model
    })
    if (device === 'My phone is not listed') {
      return completeCurrentStep(currentStep, {
        AutoDetected: false,
        DeviceSelected: false
      })
    }
  }

  const getColorCode = (color) => {
    return colorCodes.filter(v => v[color])?.[0]?.[color]
  }

  switchBtn.args = {
    ...switchBtn.args,
    checked: selectedDeviceDetails?.paymentMethod == 'Monthly' ? true : false,
    onChange: s => setSelectedDevice({
      paymentMethod: s ? 'Monthly' : 'Annual',
      discountApplied: !s ? [...selectedDeviceDetails?.discountApplied.filter(v => v != 'DIGITAL PROMOTIONAL DISCOUNT'), 'DIGITAL PROMOTIONAL DISCOUNT'] : [...selectedDeviceDetails?.discountApplied.filter(v => v != 'DIGITAL PROMOTIONAL DISCOUNT')]
    })
  }
  phnColor.args = {
    ...phnColor.args,
    phoneColor: selectedDeviceDetails?.selectedDeviceColor,
    deviceColors: deviceColors.map(i => ({
      ColorCode: `bg-[${getColorCode(i)}]`,
      color: i
    })),
    onClick: s => setSelectedDevice({
      selectedDeviceColor: s.color
    })
  }
  phnSize.args = {
    ...phnSize.args,
    deviceSizes: deviceSizes?.map(i => ({
      size: `${i}GB`
    })),
    phoneSize: selectedDeviceDetails?.selectedDeviceCapacity,
    onClick: s => setSelectedDevice({
      selectedDeviceCapacity: s.size
    })
  }
  radioBtn.args = {
    ...radioBtn.args,
    value: selectedDeviceDetails?.isNewPhone,
    onSelect: s => setSelectedDevice({
      isNewPhone: s == "true" ? true : false,
      discountApplied: s == "true" ? [...selectedDeviceDetails?.discountApplied.filter(v => v != 'NEWPH_DISC'), 'NEWPH_DISC'] : [...selectedDeviceDetails?.discountApplied.filter(v => v != 'NEWPH_DISC')]
    })
  }
  selectPhn.args = {
    ...selectPhn.args,
    value: selectedDeviceDetails?.selectedModel,
    onSelect: setSelectedPhoneData,
    options: _options
  }
  if (formattedPrice) {
    priceContent.args = {
      ...priceContent.args,
      content: '${value}{decimal}'.replace('{value}', formattedPrice?.[0]).replace('{decimal}', desimalNumber && `.${desimalNumber}${selectedDeviceDetails?.isNewPhone ? '*' : ''}`)
    }
  }
  const capacityTooltipText = `<div>
  <p className="font-bold  mb-2">
                        ${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' ? "iPhone's Capacity" : selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? "Samsung's Capacity" : "Oppo's Capacity"}
                      </p>
                      <p className="mb-0">
                        <span className="font-bold">1.</span>${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' || selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? "Select" : "Go to"} <span className="font-bold">'Settings'</span>
                      </p>
                      <p className="mb-0">
                        <span className="font-bold">2.</span> Select 
                        <span className="font-bold">${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' ? "'About'" : selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? "'Device Care'" : "'Storage'"}</span>
                      </p>
                      <p className="mb-0">
                        <span className="font-bold">3.</span> See 
                        <span className="font-bold">${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' || selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? '' : 'Phone'} ${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' ? 'Capacity' : 'Storage'}</span> - e.g.
                        64GB
                      </p>
                      <p className="font-bold mt-27 mb-m5">Why is this important?</p>
                      <p className="">
                        If your phone needs replacing due to damage or lost / theft, Asurion needs to know the full
                        specifications so we can provide a "like for like" replacement.
                      </p></div>`
  capacityTooltip.args = {
    ...capacityTooltip.args,
    tooltipText: capacityTooltipText
  }

  btnNext.args = {
    ...btnNext.args,
    enabled:
      selectedDeviceDetails?.selectedModel &&
        selectedDeviceDetails?.selectedDeviceColor &&
        selectedDeviceDetails?.selectedDeviceCapacity &&
        selectedDeviceDetails?.isNewPhone != undefined
        ? true
        : false,
    onClick: e => confirmDeviceDetails()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default AutoDetection
