import React from "react";
import PropTypes from "prop-types";
import { AsyncPaginate } from "react-select-async-paginate";

export const AsyncSelect = (props) => {
  return <AsyncPaginate {...props} />;
};

AsyncSelect.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
};

AsyncSelect.defaultProps = {
  enabled: true,
};
