/* eslint-disable max-len */
import React from 'react'

const icon = ({ isSelected, className }) => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5 21.9993V26.666C14.5 28.1388 13.3061 29.3327 11.8333 29.3327H3.83333C2.36057 29.3327 1.16666 28.1388 1.16666 26.666V5.33268C1.16666 3.85992 2.36057 2.66602 3.83333 2.66602H11.8333C13.3061 2.66602 14.5 3.85992 14.5 5.33268V9.99935" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.5 24H1.16666" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M18.5 9.99935V5.33268C18.5 3.85992 19.6939 2.66602 21.1667 2.66602H29.1667C30.6394 2.66602 31.8333 3.85992 31.8333 5.33268V26.666C31.8333 28.1388 30.6394 29.3327 29.1667 29.3327H21.1667C19.6939 29.3327 18.5 28.1388 18.5 26.666V21.9993" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M31.8333 24H18.5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.4693 13.0312V18.6881" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.2978 15.8597H13.6409" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.83333 26.332C8.01743 26.332 8.16667 26.4813 8.16667 26.6654V26.6654C8.16667 26.8495 8.01743 26.9987 7.83333 26.9987V26.9987C7.64924 26.9987 7.5 26.8495 7.5 26.6654V26.6654C7.5 26.4813 7.64924 26.332 7.83333 26.332" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M25.1667 26.332C25.3508 26.332 25.5 26.4813 25.5 26.6654V26.6654C25.5 26.8495 25.3508 26.9987 25.1667 26.9987V26.9987C24.9826 26.9987 24.8333 26.8495 24.8333 26.6654V26.6654C24.8333 26.4813 24.9826 26.332 25.1667 26.332" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

)

export default icon
