import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

function IMEINotFound() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
      )
    const metadata = workflowState['config']?.metadata['ImeiNotFound']
    const contactMethodCheckbox = findById(metadata, 'contactMethodCheckbox')
    const btnNext = findById(metadata, 'btnNext')
    const [contactMethod, setContactMethod] = useState()
    contactMethodCheckbox.args = {
        ...contactMethodCheckbox.args,
        value: contactMethod,
        onSelect: setContactMethod
    }
    btnNext.args = {
        ...btnNext.args,
        enabled: contactMethod !== undefined
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default IMEINotFound;