import { useContext, useEffect, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { findById } from '../enrollment-workflow/helper'
import { useNavigate } from 'react-router-dom'
import API from '../../services'
import ActionTypes from '../../ActionTypes'

function EmailOTP() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  let metadata = workflowState['config']?.metadata['EmailOTP']
  const navigate = useNavigate();

  const [otp, setOtp] = useState('')
  let otpInput = findById(metadata, 'otpInput')
  let otpBtn = findById(metadata, 'otpBtn')
  let emailtext = findById(metadata, 'emailtext')
  let navigateBack = findById(metadata, 'navigateBack')

  let agreementDetails = workflowState['CreateAgreementResponse'];
  let loginDetails = workflowState['login'];
  let otpDetails = workflowState['email-otp'];
  const agreementStatus = loginDetails?.agreementStatus
  const agreementError = loginDetails?.agreementError
  const OTPError = loginDetails?.OTPError
  const OTPSent = loginDetails?.OTPSent
  const otpValue = otp && otp.join('')

  let verifyOtp = () => {
    API[ActionTypes.VERIFY_LOGIN_OTP](otpValue).then(data => {
      updateState({
        ...workflowState, otpResponse: data
      });
      return completeCurrentStep(currentStep, {
        otpValue: otpValue,
        OTPVerified: 'VERIFIED'
      })
    })
  }
  let buyNowReengage = () => {
    updateState({
      ...workflowState, 'login': {
        ...workflowState['login'],
        buyNowReengage: true
      }
    });
    navigate("/reengage")
  }
  useEffect(() => {
    let isReengageFlow = Boolean(agreementError?.DynamoDBdata)
    let isCoolade = agreementError?.DynamoDBdata?.Flow === 'COOLADE'
    let isAgreementNotFound = agreementStatus === 'NOT_FOUND' || agreementStatus === 'INIT'
    let errorMsg = agreementError?.data?.message

    if (otpDetails?.OTPVerified === 'VERIFIED') {
      if (isAgreementNotFound && errorMsg && isReengageFlow && isCoolade) {
        buyNowReengage()
      } else {
        const UpdateAgreementRequest = {
          CreateAgreementActivityRequest: {
            AgreementRequestId: agreementDetails?.AgreementRequestId,
            FirstName: loginDetails?.firstName,
            LastName: loginDetails?.lastName,
            EmailAddress: loginDetails?.email,
            AgreementRequestStatus: 'EMAIL_OTP_VERIFICATION',
          },
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
          updateState({
            ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse
          });
          return completeCurrentStep(currentStep, {
            otpValue: otpValue,
            verifiedOtp: true
          })
        })
      }
    }
  }, [agreementStatus, agreementError, otpDetails?.OTPVerified])

  otpInput.args = {
    ...otpInput.args,
    onChange: setOtp
  }
  navigateBack.args = {
    ...navigateBack.args,
    onClick: () => navigate("/login")
  }
  emailtext.args = {
    ...emailtext.args,
    content: loginDetails?.email
  }

  otpBtn.args = {
    ...otpBtn.args,
    enabled: otpValue && otpValue.length === 4 ? true : false,
    onClick: e => verifyOtp()
    // return completeCurrentStep(currentStep, {
    //   OtpValue: otpValue,
    //   verifiedOtp: true
    // })
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default EmailOTP
