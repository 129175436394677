/* eslint-disable max-len */
import React from 'react'

const icon = ({ className ,onClick}) => (
    <svg className={className} onClick={onClick} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.249 4.70697L19.834 3.29297L12.542 10.586L5.24898 3.29297L3.83398 4.70697L11.127 12L3.83398 19.293L5.24898 20.707L12.542 13.414L19.834 20.707L21.249 19.293L13.956 12L21.249 4.70697Z" 
         fill='black'/>
    </svg>


)

export default icon
