/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg className={`${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="white"/>
</mask>
<g mask="url(#mask0)">
<path fillRule="evenodd" clipRule="evenodd" d="M22.8 12.0662C22.8 6.0651 17.9645 1.20001 12 1.20001C6.03545 1.20001 1.2 6.0651 1.2 12.0662C1.2 17.4899 5.14936 21.9855 10.3127 22.8V15.207H7.57052V12.0662H10.3127V9.67218C10.3127 6.94921 11.9249 5.44474 14.3917 5.44474C15.5733 5.44474 16.8094 5.65663 16.8094 5.65663V8.33119H15.4472C14.106 8.33119 13.6877 9.16888 13.6877 10.0278V12.0662H16.6828L16.2037 15.207H13.6877V22.8C18.8506 21.9855 22.8 17.4899 22.8 12.0662Z" fill="#1877F2"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.171 15.1136L16.6689 11.9886H13.5563V9.96048C13.5563 9.10539 13.9914 8.27193 15.385 8.27193H16.8V5.61134C16.8 5.61134 15.5161 5.40002 14.2883 5.40002C11.7245 5.40002 10.0494 6.89691 10.0494 9.60665V11.9886H7.2V15.1136H10.0494V22.6683C10.6207 22.7548 11.2063 22.8 11.8031 22.8C12.3989 22.8 12.985 22.7548 13.5563 22.6683V15.1136H16.171Z" fill="white"/>
</g>
</svg>


)

export default icon
