import React, { useState } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";

const Stepper = ({ images, activeIndex, onSelect }) => (
  <div className="mt-3">
    <ol className="relative list-none flex flex-row">
      {images.map((dot, index) => (
        <li
          key={index}
          style={{ width: 10, height: 10, marginRight: "4px" }}
          onClick={() => onSelect(index)}
          className={`inline-block rounded-full ${
            activeIndex === index ? "active bg-purple-800" : "bg-gray-300"
          }`}
        />
      ))}
    </ol>
  </div>
);

export const Carousel = ({ images }) => {
  let [activeStep, setActiveStep] = useState(0);

  return (
    <div className="flex flex-col justify-center items-center pt-5 w-full h-full">
      <SwipeableViews
        index={activeStep}
        onChangeIndex={setActiveStep}
        enableMouseEvents
      >
        {images.map((img) => (
          <img
            key={img.alt}
            className="mx-auto border border-solid border-gray-600 rounded-2xl max-h-400"
            src={img.src}
            alt={img.alt}
          />
        ))}
      </SwipeableViews>
      <Stepper
        images={images}
        activeIndex={activeStep}
        onSelect={setActiveStep}
      />
    </div>
  );
};

Carousel.propTypes = {};

Carousel.defaultProps = {};
