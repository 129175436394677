/* eslint-disable max-len */
import React from 'react'

const icon = ({ onClick, isSelected, className }) => (
  <svg onClick={onClick} className={className} width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.00001 7.41406L11.7071 1.70696L10.2929 0.292742L6.00001 4.58564L1.70712 0.292742L0.292908 1.70696L6.00001 7.41406Z" fill="black"/>  
  </svg>
)

export default icon
