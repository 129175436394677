export default {
    SET_LOADING_INDICATOR: 'SET_LOADING_INDICATOR',
    UNSET_LOADING_INDICATOR: 'UNSET_LOADING_INDICATOR',
  
    GET_RESOURCE_REQUEST: 'GET_RESOURCE_REQUEST',
    GET_RESOURCE_SUCCESS: 'GET_RESOURCE_SUCCESS',
    GET_RESOURCE_FAILURE: 'GET_RESOURCE_FAILURE',
  
    GET_DEVICE_BY_IMEI_REQUEST: 'GET_DEVICE_BY_IMEI_REQUEST',
    GET_DEVICE_BY_IMEI_SUCCESS: 'GET_DEVICE_BY_IMEI_SUCCESS',
    GET_DEVICE_BY_IMEI_FAILURE: 'GET_DEVICE_BY_IMEI_FAILURE',
  
    GET_QUOTE_REQUEST: 'GET_QUOTE_REQUEST',
    GET_QUOTE_SUCCESS: 'GET_QUOTE_SUCCESS',
    GET_QUOTE_FAILURE: 'GET_QUOTE_FAILURE',
  
    CREATE_AGREEMENT_REQUEST: 'CREATE_AGREEMENT_REQUEST',
    CREATE_AGREEMENT_SUCCESS: 'CREATE_AGREEMENT_SUCCESS',
    CREATE_AGREEMENT_FAILURE: 'CREATE_AGREEMENT_FAILURE',
  
    UPDATE_AGREEMENT_REQUEST: 'UPDATE_AGREEMENT_REQUEST',
    UPDATE_AGREEMENT_SUCCESS: 'UPDATE_AGREEMENT_SUCCESS',
    UPDATE_AGREEMENT_FAILURE: 'UPDATE_AGREEMENT_FAILURE',
  
    DETERMINE_INCIDENT_PATH_REQUEST: 'DETERMINE_INCIDENT_PATH_REQUEST',
    DETERMINE_INCIDENT_PATH_SUCCESS: 'DETERMINE_INCIDENT_PATH_SUCCESS',
    DETERMINE_INCIDENT_PATH_FAILURE: 'DETERMINE_INCIDENT_PATH_FAILURE',
  
    RESUME_SERVICE_REQUEST: 'RESUME_SERVICE_REQUEST',
    RESUME_SERVICE_REQUEST_SUCCESS: 'RESUME_SERVICE_REQUEST_SUCCESS',
    RESUME_SERVICE_REQUEST_FAILURE: 'RESUME_SERVICE_REQUEST_FAILURE',
  
    SET_FIRST_NAME: 'SET_FIRST_NAME',
    SET_LAST_NAME: 'SET_LAST_NAME',
    SET_CAPTURE_EMAIL_ADDRESS: 'SET_CAPTURE_EMAIL_ADDRESS',
    SET_IMEI: 'SET_IMEI',
    SET_CHAT_STATUS: 'SET_CHAT_STATUS',
    SET_EMAIL_ADDRESS: 'SET_EMAIL_ADDRESS',
    SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
    ADD_ADDITIONAL_REPAIR: 'ADD_ADDITIONAL_REPAIR',
    ADD_ADDITIONAL_SWAP: 'ADD_ADDITIONAL_SWAP',
    PRINT: 'PRINT',
    SET_CITY_OR_POSTCODE: 'SET_CITY_OR_POSTCODE',
    
    TELSTRA_STAY_CONNECTED_CUSTOMER_NO: 'TELSTRA_STAY_CONNECTED_CUSTOMER_NO',
    SET_CREDIT_CARD_NUMBER: 'SET_CREDIT_CARD_NUMBER',
    SET_NAME_ON_CARD: 'SET_NAME_ON_CARD',
    SET_EXPIRY_DATE_OF_CARD: 'SET_EXPIRY_DATE_OF_CARD',
    SET_CVV_CODE: 'SET_CVV_CODE',
    GET_DAY: 'GET_DAY',
    GET_MONTH: 'GET_MONTH',
    GET_YEAR: 'GET_YEAR',
    GET_PAYMENT_DETAILS: 'GET_PAYMENT_DETAILS',
    SET_MAKE_NAME: 'SET_MAKE_NAME',
    SET_MODEL_NAME: 'SET_MODEL_NAME',
    SET_COLOUR: 'SET_COLOUR',
    SET_MODEL: 'SET_MODEL',
    SET_CAPACITY: 'SET_CAPACITY',
    GET_DEVICE_DETAILS: 'GET_DEVICE_DETAILS',
    FIND_CITY_STATE_REQUEST: 'FIND_CITY_STATE_REQUEST',
    FIND_CITY_STATE_SUCCESS: 'FIND_CITY_STATE_SUCCESS',
    FIND_CITY_STATE_FAILURE: 'FIND_CITY_STATE_FAILURE',
    SET_SELECTED_PLAN_NAME: 'SET_SELECTED_PLAN_NAME',
    SET_DELIVERY_ADDRESS_CITY: 'SET_DELIVERY_ADDRESS_CITY',
    CONFIRM_DEVICE_DETAILS: 'CONFIRM_DEVICE_DETAILS',
    SET_SHOW_COMPARE_PLAN: 'SET_SHOW_COMPARE_PLAN',
    SET_CAPTURE_DEVICE_DETAILS: 'SET_CAPTURE_DEVICE_DETAILS',
    CAPTURE_CLIENT_OFFER: 'CAPTURE_CLIENT_OFFER',
    SET_PURCHASE_PLAN_DETAILS: 'SET_PURCHASE_PLAN_DETAILS',
    DELIVERY_ADDRESS_NXT_BTN_FLAG: 'DELIVERY_ADDRESS_NXT_BTN_FLAG',
    SET_SHOW_SUMMARY: 'SET_SHOW_SUMMARY',
    SET_SHOW_OFFER: 'SET_SHOW_OFFER',
    SET_SHOW_PERSONAL_DETAILS: 'SET_SHOW_PERSONAL_DETAILS',
    SET_SHOW_ADDRESS_DETAILS: 'SET_SHOW_ADDRESS_DETAILS',
    SET_SHOW_PROMO_CODE_DETAILS: 'SET_SHOW_PROMO_CODE_DETAILS',
    SET_SHOW_PAYMENT_DETAILS: 'SET_SHOW_PAYMENT_DETAILS',
    SET_SHOW_CONFIRMATION_DETAILS: 'SET_SHOW_CONFIRMATION_DETAILS',
    SET_DELIVERY_TYPE: 'SET_DELIVERY_TYPE',
    IS_ELIGIBLE_FOR_EXPEDITED_DELIVERY: 'IS_ELIGIBLE_FOR_EXPEDITED_DELIVERY',
    SET_UPGRADED_PLAN: 'SET_UPGRADED_PLAN',
    ADD_ADDITIONAL_PERSONAL_ASSIST: 'ADD_ADDITIONAL_PERSONAL_ASSIST',
    ADD_ADDITIONAL_VIRTUAL_ASSIST: 'ADD_ADDITIONAL_VIRTUAL_ASSIST',
    SHOW_GOOGLE_MAP: 'SHOW_GOOGLE_MAP',
    INTIALIZE_API_REQUEST: 'INTIALIZE_API_REQUEST',
    INTIALIZE_API_REQUEST_SUCESS: 'INTIALIZE_API_REQUEST_SUCESS',
    INTIALIZE_API_REQUEST_FAILURE: 'INTIALIZE_API_REQUEST_FAILURE',
    SET_PAYMENT_TYPE: 'SET_PAYMENT_TYPE',
    SET_APPLIED_DESCOUNT: 'SET_APPLIED_DESCOUNT',
    SET_PROMO_OFFER: 'SET_PROMO_OFFER',
    SET_SHOW_PAYMENT_OFFER: 'SET_SHOW_PAYMENT_OFFER',
    GET_SECURITY_TOKEN_REQUEST: 'GET_SECURITY_TOKEN_REQUEST',
    GET_SECURITY_TOKEN_SUCCESS: 'GET_SECURITY_TOKEN_SUCCESS',
    GET_SECURITY_TOKEN_FAILURE: 'GET_SECURITY_TOKEN_FAILURE',
    PROCESS_PAYMENT_REQUEST: 'PROCESS_PAYMENT_REQUEST',
    PROCESS_PAYMENT_SUCCESS: 'PROCESS_PAYMENT_SUCCESS',
    CHECK_ENROLLMENT_ELIGILIBITY_REQUEST: 'CHECK_ENROLLMENT_ELIGILIBITY_REQUEST',
    CHECK_ENROLLMENT_ELIGILIBITY_SUCCESS: 'CHECK_ENROLLMENT_ELIGILIBITY_SUCCESS',
    CHECK_ENROLLMENT_ELIGILIBITY_FAILURE: 'CHECK_ENROLLMENT_ELIGILIBITY_FAILURE',
    PROCESS_PAYMENT_FAILURE: 'PROCESS_PAYMENT_FAILURE',
    AUTH_CARD_REQUEST: 'AUTH_CARD_REQUEST',
    AUTH_CARD_SUCCESS: 'AUTH_CARD_SUCCESS',
    AUTH_CARD_FAILURE: 'AUTH_CARD_FAILURE',
    COLLECT_PAYMENT_REQUEST: 'COLLECT_PAYMENT_REQUEST',
    COLLECT_PAYMENT_SUCCESS: 'COLLECT_PAYMENT_SUCCESS',
    COLLECT_PAYMENT_FAILURE: 'COLLECT_PAYMENT_FAILURE',
    ENROLLMENT_REQUEST: 'ENROLLMENT_REQUEST',
    ENROLLMENT_SUCCESS: 'ENROLLMENT_SUCCESS',
    ENROLLMENT_FAILURE: 'ENROLLMENT_FAILURE',
    SET_SELECTED_DOCUMENT_FOR_VIEW: 'SET_SELECTED_DOCUMENT_FOR_VIEW',
  
    /*Login */
    SET_TERMS_CONDITION_ACCEPTED: 'SET_TERMS_CONDITION_ACCEPTED',
    SET_PRODCUT_DISCLOSURE_ACCEPTED: 'SET_PRODCUT_DISCLOSURE_ACCEPTED',
    SET_EMAIL_OR_MOBILE: 'SET_EMAIL_OR_MOBILE',
    SET_EMAIL_OR_MOBILE2: 'SET_EMAIL_OR_MOBILE2',
    SET_RECOVER_EMAIL: 'SET_RECOVER_EMAIL',
    SET_RECOVER_FIRST_NAME: 'SET_RECOVER_FIRST_NAME',
    SET_RECOVER_LAST_NAME: 'SET_RECOVER_LAST_NAME',
    SET_RECOVER_PHONE_NBR: 'SET_RECOVER_PHONE_NBR',
    ON_CLICK_CREATE_PASSWORD: 'ON_CLICK_CREATE_PASSWORD',
    GET_AGREEMENT_REQUEST: 'GET_AGREEMENT_REQUEST',
    GET_AGREEMENT_SUCCESS: 'GET_AGREEMENT_SUCCESS',
    GET_AGREEMENT_FAILURE: 'GET_AGREEMENT_FAILURE',
    GET_AGREEMENT_STATUS_REQUEST: 'GET_AGREEMENT_STATUS_REQUEST',
    GET_AGREEMENT_STATUS_SUCCESS: 'GET_AGREEMENT_STATUS_SUCCESS',
    GET_AGREEMENT_STATUS_FAILURE: 'GET_AGREEMENT_STATUS_FAILURE',
    SET_AGREEMENT_STATUS: 'SET_AGREEMENT_STATUS',
    SET_RECOVER_ACCOUNT: 'SET_RECOVER_ACCOUNT',
    SET_ACTION_TYPE: 'SET_ACTION_TYPE',
    SET_RECOVER_BY: 'SET_RECOVER_BY',
    SET_ROUTE: 'SET_ROUTE',
    SET_DATE_OF_BIRTH: 'SET_DATE_OF_BIRTH',
    SET_POLICY_NUMBER: 'SET_POLICY_NUMBER',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',
    SET_LOGIN_PASSWORD: 'SET_LOGIN_PASSWORD',
    SET_USERNAME_TYPE: 'SET_USERNAME_TYPE',
    SET_IS_MENU_OPEN: 'SET_IS_MENU_OPEN',
    SET_CREDIT_CARD_DAY: 'SET_CREDIT_CARD_DAY',
    SET_CREDIT_CARD_YEAR: 'SET_CREDIT_CARD_YEAR',
    SET_STREET_NAME: 'SET_STREET_NAME',
    SET_STREET_NO: 'SET_STREET_NO',
    SET_STATE: 'SET_STATE',
    SET_SUBURB: 'SET_SUBURB',
    SET_POSTCODE: 'SET_POSTCODE',
    
    SET_LOGIN_EMAIL: 'SET_LOGIN_EMAIL',
    OTP_REQUEST: 'OTP_REQUEST',
    OTP_REQUEST_SUCCESS: 'OTP_REQUEST_SUCCESS',
    OTP_REQUEST_FAILURE: 'OTP_REQUEST_FAILURE',
    RESET_OTP_REQUEST: 'RESET_OTP_REQUEST',
    SET_LOGIN_OTP: 'SET_LOGIN_OTP',
    VERIFY_LOGIN_OTP: 'VERIFY_LOGIN_OTP',
    VERIFY_LOGIN_OTP_SUCCESS: 'VERIFY_LOGIN_OTP_SUCCESS',
    VERIFY_LOGIN_OTP_FAILURE: 'VERIFY_LOGIN_OTP_FAILURE',
    SET_SEARCH_ADDRESS_LINE: 'SET_SEARCH_ADDRESS_LINE',
    SET_UPDATE_GOOGLE_MAP: 'SET_UPDATE_GOOGLE_MAP',
    SET_LOGIN_EMAIL: 'SET_LOGIN_EMAIL',
    OTP_REQUEST: 'OTP_REQUEST',
    OTP_REQUEST_SUCCESS: 'OTP_REQUEST_SUCCESS',
    OTP_REQUEST_FAILURE: 'OTP_REQUEST_FAILURE',
    SET_LOGIN_OTP: 'SET_LOGIN_OTP',
    SIGNOUT_REQUEST: 'SIGNOUT_REQUEST',
    SIGNOUT_REQUEST_SUCCESS: 'SIGNOUT_REQUEST_SUCCESS',
    SIGNOUT_REQUEST_FAILURE: 'SIGNOUT_REQUEST_FAILURE',
    VERIFY_LOGIN_OTP: 'VERIFY_LOGIN_OTP',
    VERIFY_LOGIN_OTP_SUCCESS: 'VERIFY_LOGIN_OTP_SUCCESS',
    VERIFY_LOGIN_OTP_FAILURE: 'VERIFY_LOGIN_OTP_FAILURE',
    /*Login */
    SET_NEW_ARRAY: 'SET_NEW_ARRAY',
    SET_COUNT: 'SET_COUNT',
    SET_DEVICE_LIST: 'SET_DEVICE_LIST',
    SET_DEVICE_IN_POSSESSION: 'SET_DEVICE_IN_POSSESSION',
    SET_SELECTED_DEVICE_IN_POSSESSION: 'SET_SELECTED_DEVICE_IN_POSSESSION',
    SET_DEVICE_LOSTSTOLEN: 'SET_DEVICE_LOSTSTOLEN',
    HIDE_PASSWORD: 'HIDE_PASSWORD',
    HIDE_RE_NEW_PASSWORD: 'HIDE_RE_NEW_PASSWORD',
  
    /* Buy Now Flow */
    BUY_NOW_SIGNUP_OTP_REQUEST: 'BUY_NOW_SIGNUP_OTP_REQUEST',
    BUY_NOW_RE_ENGAGE: 'BUY_NOW_RE_ENGAGE',
    SET_FLOW_RESTART_STATE: 'SET_FLOW_RESTART_STATE',
    BUY_NOW_SIGNUP_OTP_SUCCESS: 'BUY_NOW_SIGNUP_OTP_SUCCESS',
    BUY_NOW_SIGNUP_OTP_FAILURE: 'BUY_NOW_SIGNUP_OTP_FAILURE',
    SET_BUYNOW_LOGIN_TYPE: 'SET_BUYNOW_LOGIN_TYPE',
    SET_BUYNOW_SOCIAL_USER: 'SET_BUYNOW_SOCIAL_USER',
    SET_AMPLIFY_FLOW: 'SET_AMPLIFY_FLOW',
    HYDRATE_PREV_STATE_SOCIAL_LOGIN: 'HYDRATE_PREV_STATE_SOCIAL_LOGIN',
    LOGOUT: 'LOGOUT',
    RESET_MANUAL_DEVICE_SELECTION: 'RESET_MANUAL_DEVICE_SELECTION',
    SET_PAYMENT_PROCESS_ERROR: 'SET_PAYMENT_PROCESS_ERROR',
    GET_REENGAGE_DATA_REQUEST: 'GET_REENGAGE_DATA_REQUEST',
    GET_REENGAGE_DATA_SUCCESS: 'GET_REENGAGE_DATA_SUCCESS',
    GET_REENGAGE_DATA_FAILUER: 'GET_REENGAGE_DATA_FAILUER',
    /** Claim */
    FIND_AGREEMENT_REQUEST: 'FIND_AGREEMENT_REQUEST',
    FIND_AGREEMENT_SUCCESS: 'FIND_AGREEMENT_SUCCESS',
    FIND_AGREEMENT_FAILUER: 'FIND_AGREEMENT_FAILUER',
    SET_SELECTED_AGREEMENT_DETAILS: 'SET_SELECTED_AGREEMENT_DETAILS',
    SET_SELECTED_ENROLLED_DEVICE_DETAILS: 'SET_SELECTED_ENROLLED_DEVICE_DETAILS',
    SET_SELECTED_ENROLLED_DEVICE: 'SET_SELECTED_ENROLLED_DEVICE',
    GET_REPLACEMENT_MATRIX_REQUEST: 'GET_REPLACEMENT_MATRIX_REQUEST',
    GET_REPLACEMENT_MATRIX_SUCCESS: 'GET_REPLACEMENT_MATRIX_SUCCESS',
    GET_REPLACEMENT_MATRIX_FAILURE: 'GET_REPLACEMENT_MATRIX_FAILURE',
    PROCESS_INCIENT_REQUEST: 'PROCESS_INCIENT_REQUEST',
    PROCESS_INCIENT_SUCCESS: 'PROCESS_INCIENT_SUCCESS',
    PROCESS_INCIENT_FAILURE: 'PROCESS_INCIENT_FAILURE',
    SET_SELECTED_REPLACEMENT_DEVICE: 'SET_SELECTED_REPLACEMENT_DEVICE',
    GET_CUSTOMER_CASE_GRAPH_REQUEST: 'GET_CUSTOMER_CASE_GRAPH_REQUEST',
    GET_CUSTOMER_CASE_GRAPH_SUCCESS: 'GET_CUSTOMER_CASE_GRAPH_SUCCESS',
    GET_CUSTOMER_CASE_GRAPH_FAILURE: 'GET_CUSTOMER_CASE_GRAPH_FAILURE',
    SET_SELECTED_CUSTOMER_CASE: 'SET_SELECTED_CUSTOMER_CASE',
    SET_SELECTED_INCIDENT_DATE: 'SET_SELECTED_INCIDENT_DATE',
    SET_SELECTED_INCIDENT_DESCRIPTION: 'SET_SELECTED_INCIDENT_DESCRIPTION',
    OPEN_POP_UP: 'OPEN_POP_UP',
    CLOSE_POP_UP: 'CLOSE_POP_UP',
    SET_CANCEL_CLAIM: 'SET_CANCEL_CLAIM',
    SET_MY_CLAIMS_TAB: 'SET_MY_CLAIMS_TAB',
    START_SERVICE_REQUEST: 'START_SERVICE_REQUEST',
    START_SERVICE_SUCCESS: 'START_SERVICE_SUCCESS',
    START_SERVICE_FAILURE: 'START_SERVICE_FAILURE',
    SET_DELIVERY_INSTRUCTION: 'SET_DELIVERY_INSTRUCTION',
    SET_ADDRESS_CHANGED: 'SET_ADDRESS_CHANGED',
    GET_FULFILLMENT_OPTION_REQUEST: 'GET_FULFILLMENT_OPTION_REQUEST',
    GET_FULFILLMENT_OPTION_SUCCESS: 'GET_FULFILLMENT_OPTION_SUCCESS',
    GET_FULFILLMENT_OPTION_FAILURE: 'GET_FULFILLMENT_OPTION_FAILURE',
    SET_FULFILLMENT_OPTION_REQUEST: 'SET_FULFILLMENT_OPTION_REQUEST',
    SET_FULFILLMENT_OPTION_SUCCESS: 'SET_FULFILLMENT_OPTION_SUCCESS',
    SET_FULFILLMENT_OPTION_FAILURE: 'SET_FULFILLMENT_OPTION_FAILURE',
    SELECTED_CARD_DETAILS: 'SELECTED_CARD_DETAILS',
    SET_CARD_ACCEPTED: 'SET_CARD_ACCEPTED',
    GET_SHIPPING_ORDER_REQUEST: 'GET_SHIPPING_ORDER_REQUEST',
    GET_SHIPPING_ORDER_SUCCESS: 'GET_SHIPPING_ORDER_SUCCESS',
    GET_SHIPPING_ORDER_FAILURE: 'GET_SHIPPING_ORDER_FAILURE',
    GET_SHIPPING_METHODS_REQUEST: 'GET_SHIPPING_METHODS_REQUEST',
    GET_SHIPPING_METHODS_SUCCESS: 'GET_SHIPPING_METHODS_SUCCESS',
    GET_SHIPPING_METHODS_FAILURE: 'GET_SHIPPING_METHODS_FAILURE',
    STANDARDIZE_ADDRESS_REQUEST: 'STANDARDIZE_ADDRESS_REQUEST',
    STANDARDIZE_ADDRESS_SUCCESS: 'STANDARDIZE_ADDRESS_SUCCESS',
    STANDARDIZE_ADDRESS_FAILURE: 'STANDARDIZE_ADDRESS_FAILURE',
    UPDATE_SHIPPING_ORDER_REQUEST: 'UPDATE_SHIPPING_ORDER_REQUEST',
    UPDATE_SHIPPING_ORDER_SUCCESS: 'UPDATE_SHIPPING_ORDER_SUCCESS',
    UPDATE_SHIPPING_ORDER_FAILURE: 'UPDATE_SHIPPING_ORDER_FAILURE',
    CREATE_SERVICE_ORDER_REQUEST: 'CREATE_SERVICE_ORDER_REQUEST',
    CREATE_SERVICE_ORDER_SUCCESS: 'CREATE_SERVICE_ORDER_SUCCESS',
    CREATE_SERVICE_ORDER_FAILURE: 'CREATE_SERVICE_ORDER_FAILURE',
    UPDATE_SERVICE_ORDER_REQUEST: 'UPDATE_SERVICE_ORDER_REQUEST',
    UPDATE_SERVICE_ORDER_SUCCESS: 'UPDATE_SERVICE_ORDER_SUCCESS',
    UPDATE_SERVICE_ORDER_FAILURE: 'UPDATE_SERVICE_ORDER_FAILURE',
    SET_MOBILE_DEVICE_NUMBER: 'SET_MOBILE_DEVICE_NUMBER',
  
    GET_PCITOKEN_REQUEST: 'GET_PCITOKEN_REQUEST',
    GET_PCITOKEN_SUCCESS: 'GET_PCITOKEN_SUCCESS',
    GET_PCITOKEN_FAILURE: 'GET_PCITOKEN_FAILURE',
  
    CREATE_CHARGE_ORDER_REQUEST: 'CREATE_CHARGE_ORDER_REQUEST',
    CREATE_CHARGE_ORDER_SUCCESS: 'CREATE_CHARGE_ORDER_SUCCESS',
    CREATE_CHARGE_ORDER_FAILURE: 'CREATE_CHARGE_ORDER_FAILURE',
  
    CREATE_SERVICE_ORDER_REQUEST: 'CREATE_SERVICE_ORDER_REQUEST',
    CREATE_SERVICE_ORDER_SUCCESS: 'CREATE_SERVICE_ORDER_SUCCESS',
    CREATE_SERVICE_ORDER_FAILURE: 'CREATE_SERVICE_ORDER_FAILURE',
  
    GET_SURVEY_QUESTIONS_REQUEST: 'GET_SURVEY_QUESTIONS_REQUEST',
    GET_SURVEY_QUESTIONS_SUCCESS: 'GET_SURVEY_QUESTIONS_SUCCESS',
    GET_SURVEY_QUESTIONS_FAILURE: 'GET_SURVEY_QUESTIONS_FAILURE',
  
    SUBMIT_SURVEY_QUESTIONS_REQUEST: 'SUBMIT_SURVEY_QUESTIONS_REQUEST',
    SUBMIT_SURVEY_QUESTIONS_SUCCESS: 'SUBMIT_SURVEY_QUESTIONS_SUCCESS',
    SUBMIT_SURVEY_QUESTIONS_FAILURE: 'SUBMIT_SURVEY_QUESTIONS_FAILURE',
  
    REPAIR_TO_REFRESH_REQUEST: 'REPAIR_TO_REFRESH_REQUEST',
    REPAIR_TO_REFRESH_SUCCESS: 'REPAIR_TO_REFRESH_SUCCESS',
    REPAIR_TO_REFRESH_FAILURE: 'REPAIR_TO_REFRESH_FAILURE',
  
    SET_CONTACT_MOBILE_DEVICE_NUMBER: 'SET_CONTACT_MOBILE_DEVICE_NUMBER',
    SET_OTHER_ALTERNATIVE_DEVICE_SELECTED: 'SET_OTHER_ALTERNATIVE_DEVICE_SELECTED',
    SET_OTHER_THAN_LFL_DEVICES: 'SET_OTHER_THAN_LFL_DEVICES',
    PROCESS_CLAIM_PAYMENT_REQUEST: 'PROCESS_CLAIM_PAYMENT_REQUEST',
    PROCESS_CLAIM_PAYMENT_SUCCESS: 'PROCESS_CLAIM_PAYMENT_SUCCESS',
    PROCESS_CLAIM_PAYMENT_FAILURE: 'PROCESS_CLAIM_PAYMENT_FAILURE',
  
    SUBMIT_ORDER_REQUEST: 'SUBMIT_ORDER_REQUEST',
    SUBMIT_ORDER_SUCCESS: 'SUBMIT_ORDER_SUCCESS',
    SUBMIT_ORDER_FAILURE: 'SUBMIT_ORDER_FAILURE',
    NEW_CARD_SELECTED: 'NEW_CARD_SELECTED',
    SET_CONTACT_EMAIL_ADDRESS: 'SET_CONTACT_EMAIL_ADDRESS',
  
    CREATE_CONTACT_POINT_REQUEST: 'CREATE_CONTACT_POINT_REQUEST',
    CREATE_CONTACT_POINT_SUCCESS: 'CREATE_CONTACT_POINT_SUCCESS',
    CREATE_CONTACT_POINT_FAILURE: 'CREATE_CONTACT_POINT_FAILURE',
    GET_CONTACT_POINT_REQUEST: 'GET_CONTACT_POINT_REQUEST',
    GET_CONTACT_POINT_SUCCESS: 'GET_CONTACT_POINT_SUCCESS',
    GET_CONTACT_POINT_FAILURE: 'GET_CONTACT_POINT_FAILURE',
  
    CANCEL_SERVICE_REQUEST_REQUEST: 'CANCEL_SERVICE_REQUEST_REQUEST',
    CANCEL_SERVICE_REQUEST_SUCCESS: 'CANCEL_SERVICE_REQUEST_SUCCESS',
    CANCEL_SERVICE_REQUEST_FAILURE: 'CANCEL_SERVICE_REQUEST_FAILURE',
    SET_SELECTED_CLAIM_TYPE: 'SET_SELECTED_CLAIM_TYPE',
    CREATE_REPAIR_REQ_REQUEST: 'CREATE_REPAIR_REQ_REQUEST',
    CREATE_REPAIR_REQ_SUCCESS: 'CREATE_REPAIR_REQ_SUCCESS',
    CREATE_REPAIR_REQ_FAILURE: 'CREATE_REPAIR_REQ_FAILURE',
    SET_RATING_TEXT: 'SET_RATING_TEXT',
    /**  Claim */
  
    //Claim Payment Action types
    SET_CLAIM_NAME_ON_CARD: 'SET_CLAIM_NAME_ON_CARD',
    SET_CLAIM_EXPIRY_DATE_OF_CARD: 'SET_CLAIM_EXPIRY_DATE_OF_CARD',
    SET_CLAIM_CVV_CODE: 'SET_CLAIM_CVV_CODE',
    SET_CLAIM_CREDIT_CARD_NUMBER: 'SET_CLAIM_CREDIT_CARD_NUMBER',
    SET_NAVIGATE_TO_BACK: 'SET_NAVIGATE_TO_BACK',
    SET_ADDRESS_DELIVARY_TYPE: 'SET_ADDRESS_DELIVARY_TYPE',
    SET_NAVIGATE_TO_MY_PLAN: 'SET_NAVIGATE_TO_MY_PLAN',
    SET_NAVIGATE_TO_DELIVERY_ADDRESS: 'SET_NAVIGATE_TO_DELIVERY_ADDRESS',
    SET_NAVIGATE_TO_PREDICTIVE_ADDRESS: 'SET_NAVIGATE_TO_PREDICTIVE_ADDRESS',
    SET_NAVIGATE_TO_MANUAL_ADDRESS: 'SET_NAVIGATE_TO_MANUAL_ADDRESS',
    SET_GET_HELP: 'SET_GET_HELP',
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    SET_SHOW_FILE_CLAIM: 'SET_SHOW_FILE_CLAIM',
    GET_CITY_STATE_BY_ZIPCODE_REQUEST: 'GET_CITY_STATE_BY_ZIPCODE_REQUEST',
    GET_CITY_STATE_BY_ZIPCODE_SUCCESS: 'GET_CITY_STATE_BY_ZIPCODE_SUCCESS',
    GET_CITY_STATE_BY_ZIPCODE_FAILURE: 'GET_CITY_STATE_BY_ZIPCODE_FAILURE',
    CLEAR_CARD_DETAILS: 'CLEAR_CARD_DETAILS',
    SET_READY_TO_WAIT: 'SET_READY_TO_WAIT',
    CLOSE_REPLACEMENT_WAIT_POPUP: 'CLOSE_REPLACEMENT_WAIT_POPUP',
    SET_SELECTED_PAYMENT_METHOD: 'SET_SELECTED_PAYMENT_METHOD',
    //SignUp
    ON_SIGNUP_CLICK: 'ON_SIGNUP_CLICK',
    SET_SIGNUP_EMAIL_OR_MOBILE: 'SET_SIGNUP_EMAIL_OR_MOBILE',
    SET_SIGNUP_ACTION_TYPE: 'SET_SIGNUP_ACTION_TYPE',
    SET_SIGNUP_USERNAME_TYPE: 'SET_SIGNUP_USERNAME_TYPE',
    SET_SIGNUP_OTP: 'SET_SIGNUP_OTP',
    SET_SIGNUP_NEW_PASSWORD: 'SET_SIGNUP_NEW_PASSWORD',
    SET_SIGNUP_REENTERED_NEW_PASSWORD: 'SET_SIGNUP_REENTERED_NEW_PASSWORD',
    HIDE_SIGNUP_PASSWORD: 'HIDE_SIGNUP_PASSWORD',
    HIDE_SIGNUP_RE_NEW_PASSWORD: 'HIDE_SIGNUP_RE_NEW_PASSWORD',
    SIGNUP_ERROR: 'SIGNUP_ERROR',
    ON_SIGNUP_CLICK_CREATE_PASSWORD: 'ON_SIGNUP_CLICK_CREATE_PASSWORD',
    WRONG_REENTER_PASSWORD: 'WRONG_REENTER_PASSWORD',
    //API
    OTP_REQUEST: 'OTP_REQUEST',
    OTP_REQUEST_SUCCESS: 'OTP_REQUEST_SUCCESS',
    OTP_REQUEST_FAILURE: 'OTP_REQUEST_FAILURE',
    VERIFY_SIGNUP_OTP_REQUEST: 'VERIFY_SIGNUP_OTP_REQUEST',
    VERIFY_SIGNUP_OTP_SUCCESS: 'VERIFY_SIGNUP_OTP_SUCCESS',
    VERIFY_SIGNUP_OTP_FAILURE: 'VERIFY_SIGNUP_OTP_FAILURE',
    SEND_SIGNUP_EMAIL_OTP_REQUEST: 'SEND_SIGNUP_EMAIL_OTP_REQUEST',
    SEND_SIGNUP_EMAIL_OTP_SUCCESS: 'SEND_SIGNUP_EMAIL_OTP_SUCCESS',
    SEND_SIGNUP_EMAIL_OTP_FAILURE: 'SEND_SIGNUP_EMAIL_OTP_FAILURE',
    SEND_SIGNUP_OTP_REQUEST: 'SEND_SIGNUP_OTP_REQUEST',
    SEND_SIGNUP_OTP_REQUEST_SUCCESS: 'SEND_SIGNUP_OTP_REQUEST_SUCCESS',
    SEND_SIGNUP_OTP_REQUEST_FAILURE: 'SEND_SIGNUP_OTP_REQUEST_FAILURE',
    CHECK_IF_MDN_ALREADY_ENROLLED_REQUEST: 'CHECK_IF_MDN_ALREADY_ENROLLED_REQUEST',
    CHECK_IF_MDN_ALREADY_ENROLLED_SUCCESS: 'CHECK_IF_MDN_ALREADY_ENROLLED_SUCCESS',
    CHECK_IF_MDN_ALREADY_ENROLLED_FAILURE: 'CHECK_IF_MDN_ALREADY_ENROLLED_FAILURE',
    SIGNUP_CREATE_ACCOUNT_REQUEST: 'SIGNUP_CREATE_ACCOUNT_REQUEST',
    SIGNUP_CREATE_ACCOUNT_SUCCESS: 'SIGNUP_CREATE_ACCOUNT_SUCCESS',
    SIGNUP_CREATE_ACCOUNT_FAILURE: 'SIGNUP_CREATE_ACCOUNT_FAILURE',
    SET_WHAT_HAPPENED_TO_DEVICE: 'SET_WHAT_HAPPENED_TO_DEVICE',
    SET_MY_CLAIMS_SELECT_FEATURE: 'SET_MY_CLAIMS_SELECT_FEATURE',
    SET_MY_CLAIMS_CHANGE_ADDRESS_FLAG: 'SET_MY_CLAIMS_CHANGE_ADDRESS_FLAG',
    SET_MY_CLAIMS_CHANGE_DATE_FLAG: 'SET_MY_CLAIMS_CHANGE_DATE_FLAG',
    CLOSE_CANCEL_WAIT_POPUP: 'CLOSE_CANCEL_WAIT_POPUP',
    CLOSE_BEYOND_CANCEL_WAIT_POPUP: 'CLOSE_BEYOND_CANCEL_WAIT_POPUP',
    CLOSE_ENQUIRY_WAIT_POPUP: 'CLOSE_ENQUIRY_WAIT_POPUP',
    SET_SELECTED_ENQUIRY_DESCRIPTION: 'SET_SELECTED_ENQUIRY_DESCRIPTION',
    SET_IS_SELECTED_CUSTOMER_CASE: 'SET_IS_SELECTED_CUSTOMER_CASE',
    SET_SELECTED_CUSTOMER_CASE: 'SET_SELECTED_CUSTOMER_CASE',
    SET_SELECTED_MY_CLAIM_DELIVERY_DATE: 'SET_SELECTED_MY_CLAIM_DELIVERY_DATE',
    SET_SUBMIT_ENQUIRY: 'SET_SUBMIT_ENQUIRY',
    CLOSE_CHANGE_ADDRESS_WAIT_POPUP: 'CLOSE_CHANGE_ADDRESS_WAIT_POPUP',
    CLOSE_CHANGE_DATE_WAIT_POPUP: 'CLOSE_CHANGE_DATE_WAIT_POPUP',
    SET_MY_CLAIMS_SELECT_CLAIM: 'SET_MY_CLAIMS_SELECT_CLAIM',
    SET_MY_CLAIMS_SELECT_HISTORY: 'SET_MY_CLAIMS_SELECT_HISTORY',
    SET_SELECTED_REASON_VALUE: 'SET_SELECTED_REASON_VALUE',
    SET_SUBMIT_REASON: 'SET_SUBMIT_REASON',
    SET_CANCEL_REASON: 'SET_CANCEL_REASON',
    SET_PREDECTIVE_ADDRESS_FAILURE: 'SET_PREDECTIVE_ADDRESS_FAILURE',
    GET_DO_GET_ADDRESS: 'GET_DO_GET_ADDRESS',
    GET_DO_GET_ADDRESS_SUCCESS: 'GET_DO_GET_ADDRESS_SUCCESS',
    GET_DO_GET_ADDRESS_FAILURE: 'GET_DO_GET_ADDRESS_FAILURE',
    SET_AUSPOST_POSTCODE: 'SET_AUSPOST_POSTCODE',
    GET_AUSPOST_LIST_REQUEST: 'GET_AUSPOST_LIST_REQUEST',
    GET_AUSPOST_LIST_SUCCESS: 'GET_AUSPOST_LIST_SUCCESS',
    GET_AUSPOST_LIST_FAILURE: 'GET_AUSPOST_LIST_FAILURE',
    SET_SELECTED_REPAIR_OPTION: 'SET_SELECTED_REPAIR_OPTION',
    GET_FMIP_STATUS_REQUEST: 'GET_FMIP_STATUS_REQUEST',
    GET_FMIP_STATUS_SUCCESS: 'GET_FMIP_STATUS_SUCCESS',
    GET_FMIP_STATUS_FAILURE: 'GET_FMIP_STATUS_FAILURE',
    SHOW_STEP_TO_RETUN_DEVICE_POPUP: 'SHOW_STEP_TO_RETUN_DEVICE_POPUP',
    GENERATE_PARCEL_REQUEST: 'GENERATE_PARCEL_REQUEST',
    GENERATE_PARCEL_SUCCESS: 'GENERATE_PARCEL_SUCCESS',
    GENERATE_PARCEL_FAILURE: 'GENERATE_PARCEL_FAILURE',
    //Buy Now
    SET_IF_LIQUID_DAMAGE: 'SET_IF_LIQUID_DAMAGE',
    SET_IF_POWER_UP: 'SET_IF_POWER_UP',
    SET_IF_CAMERA_FUNCTIONAL: 'SET_IF_CAMERA_FUNCTIONAL',
    SET_IF_ANYTHING_ELSE: 'SET_IF_ANYTHING_ELSE',
    SET_IF_DISPLAY_CONDITIONAL: 'SET_IF_DISPLAY_CONDITIONAL',
    SET_IF_PHONE_IS_INSURED: 'SET_IF_PHONE_IS_INSURED',
    SET_IF_PREV_REPAIRS_STANDALONE: 'SET_IF_PREV_REPAIRS_STANDALONE',
    SET_IF_NON_GENUINE_REPAIRS: 'SET_IF_NON_GENUINE_REPAIRS',
    SET_SELECTED_FILE: 'SET_SELECTED_FILE',
    CLEAR_SELECTED_FILE: 'CLEAR_SELECTED_FILE',
    SET_NEW_FILES: 'SET_NEW_FILES',
    CLAIM_CLEAR_PHOTO_DETAILS: 'CLAIM_CLEAR_PHOTO_DETAILS',
    UPLOAD_FILE_CLICK: 'UPLOAD_FILE_CLICK',
    PRESIGN_URL_FILE_CALL: 'PRESIGN_URL_FILE_CALL',
    GET_PRESIGN_URL_FOR_UPLOAD_REQUEST: 'GET_PRESIGN_URL_FOR_UPLOAD_REQUEST',
    GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_REQUEST: 'GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_REQUEST',
    GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_SUCCESS: 'GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_SUCCESS',
    GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_FAILURE: 'GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_FAILURE',
    SET_SELECTED_IMEI_SCREENSHOT: 'SET_SELECTED_IMEI_SCREENSHOT',
    SET_SELECTED_NATIONAL_ID_SCREENSHOT: 'SET_SELECTED_NATIONAL_ID_SCREENSHOT',
    GET_PRESIGN_URL_FOR_UPLOAD_SUCCESS: 'GET_PRESIGN_URL_FOR_UPLOAD_SUCCESS',
    GET_PRESIGN_URL_FOR_UPLOAD_FAILURE: 'GET_PRESIGN_URL_FOR_UPLOAD_FAILURE',
    ON_SUBMIT_UPLOAD_DOC_CLICK: 'ON_SUBMIT_UPLOAD_DOC_CLICK',
    SET_ELIGIBILITY_QUESTIONS: 'SET_ELIGIBILITY_QUESTIONS',
    SET_IS_SWAP_CLAIM: 'SET_IS_SWAP_CLAIM',
    SET_DEVICE_SHIPMENT_TYPE: 'SET_DEVICE_SHIPMENT_TYPE',
    GET_SERVICE_FEE_REQUEST: 'GET_SERVICE_FEE_REQUEST',
    GET_SERVICE_FEE_SUCCESS: 'GET_SERVICE_FEE_SUCCESS',
    GET_SERVICE_FEE_FAILURE: 'GET_SERVICE_FEE_FAILURE',
    GET_PHOTO_ID_DETAILS_REQUEST: 'GET_PHOTO_ID_DETAILS_REQUEST',
    GET_PHOTO_ID_DETAILS_SUCCESS: 'GET_PHOTO_ID_DETAILS_SUCCESS',
    GET_PHOTO_ID_DETAILS_FAILURE: 'GET_PHOTO_ID_DETAILS_FAILURE',
    ID_DETAILS_SUCCESS: 'ID_DETAILS_SUCCESS',
    ID_DETAILS_FAILURE: 'ID_DETAILS_FAILURE',
  
    /** My Document */
    SET_SELECTED_POLICY_NO: 'SET_SELECTED_POLICY_NO',
    SET_CLAIM_ID: 'SET_CLAIM_ID',
    SET_MY_DOCUMENTS_TAB: 'SET_MY_DOCUMENTS_TAB',
    SET_MY_DOCUMENTS_NAVIGATE_TO_BACK: 'SET_MY_DOCUMENTS_NAVIGATE_TO_BACK',
    UPLOAD_PROGRESS: 'UPLOAD_PROGRESS',
    UPLOAD_FILE_TO_S3_CALL: 'UPLOAD_FILE_TO_S3_CALL',
    PRESIGN_URL_RESULT_FOR_FILE: 'PRESIGN_URL_RESULT_FOR_FILE',
    CREATE_DOCUMENT_TASK_REQUEST: 'CREATE_DOCUMENT_TASK_REQUEST',
    CREATE_DOCUMENT_TASK_SUCCESS: 'CREATE_DOCUMENT_TASK_SUCCESS',
    CREATE_DOCUMENT_TASK_FAILURE: 'CREATE_DOCUMENT_TASK_FAILURE',
    SET_POLICY_NO: 'SET_POLICY_NO',
    GET_DOCUMENT_TASK_DETAILS_REQUEST: 'GET_DOCUMENT_TASK_DETAILS_REQUEST',
    GET_DOCUMENT_TASK_DETAILS_SUCCESS: 'GET_DOCUMENT_TASK_DETAILS_SUCCESS',
    GET_DOCUMENT_TASK_DETAILS_FAILURE: 'GET_DOCUMENT_TASK_DETAILS_FAILURE',
  
    GET_REQUIRED_DOCUMENTS_LIST_REQUEST: 'GET_REQUIRED_DOCUMENTS_LIST_REQUEST',
    GET_REQUIRED_DOCUMENTS_LIST_SUCCESS: 'GET_REQUIRED_DOCUMENTS_LIST_SUCCESS',
    GET_REQUIRED_DOCUMENTS_LIST_FAILURE: 'GET_REQUIRED_DOCUMENTS_LIST_FAILURE',
  
    CLEAR_CREATE_DOCUMENT_TASK: 'CLEAR_CREATE_DOCUMENT_TASK',
    /** My Document End */
    GET_ASP_STORE_LIST_REQUEST: 'GET_ASP_STORE_LIST_REQUEST',
    GET_ASP_STORE_LIST_SUCCESS: 'GET_ASP_STORE_LIST_SUCCESS',
    GET_ASP_STORE_LIST_FAILURE: 'GET_ASP_STORE_LIST_FAILURE',
    SET_SELECETD_ASP_STORE: 'SET_SELECETD_ASP_STORE',
    SET_LOCATION_COORDINATES: 'SET_LOCATION_COORDINATES',
    SET_STORE_LIST: 'SET_STORE_LIST',
    SET_AUSPOST_LIST: 'SET_AUSPOST_LIST',
    SET_CONDITION_OF_DEVICE: 'SET_CONDITION_OF_DEVICE',
    SET_DEVICE_CONDITION_QUE_ANS: 'SET_DEVICE_CONDITION_QUE_ANS',
    SET_DEVICE_CONDITION_ACCEPTANCE: 'SET_DEVICE_CONDITION_ACCEPTANCE',
    OPEN_DEVICE_CONDITION_POPUP: 'OPEN_DEVICE_CONDITION_POPUP',
    /** My Payments Start */
    SET_MP_CREDIT_CARD_NUMBER: 'SET_MP_CREDIT_CARD_NUMBER',
    SET_MP_NAME_ON_CARD: 'SET_MP_NAME_ON_CARD',
    SET_MP_EXPIRY_DATE_OF_CARD: 'SET_MP_EXPIRY_DATE_OF_CARD',
    SET_MP_CVV_CODE: 'SET_MP_CVV_CODE',
    SELECTED_MP_CARD_DETAILS: 'SELECTED_MP_CARD_DETAILS',
    CLEAR_MP_CARD_DETAILS: 'CLEAR_MP_CARD_DETAILS',
    GET_PAYMENT_DETAILS_REQUEST: 'GET_PAYMENT_DETAILS_REQUEST',
    GET_PAYMENT_DETAILS_SUCCESS: 'GET_PAYMENT_DETAILS_SUCCESS',
    GET_PAYMENT_DETAILS_FAILUER: 'GET_PAYMENT_DETAILS_FAILUER',
    ADD_PAYMENT_METHOD_REQUEST: 'ADD_PAYMENT_METHOD_REQUEST',
    ADD_PAYMENT_METHOD_SUCCESS: 'ADD_PAYMENT_METHOD_SUCCESS',
    ADD_PAYMENT_METHOD_FAILUER: 'ADD_PAYMENT_METHOD_FAILUER',
    GET_PCISECURITYTOKEN_REQUEST: 'GET_PCISECURITYTOKEN_REQUEST',
    GET_PCISECURITYTOKEN_SUCCESS: 'GET_PCISECURITYTOKEN_SUCCESS',
    GET_PCISECURITYTOKEN_FAILURE: 'GET_PCISECURITYTOKEN_FAILURE',
    SET_MP_DELETE_SELECTED_CARD: 'SET_MP_DELETE_SELECTED_CARD',
    DELETE_PAYMENTMETHOD_REQUEST: 'DELETE_PAYMENTMETHOD_REQUEST',
    DELETE_PAYMENTMETHOD_SUCCESS: 'DELETE_PAYMENTMETHOD_SUCCESS',
    DELETE_PAYMENTMETHOD_FAILURE: 'DELETE_PAYMENTMETHOD_FAILURE',
    SAVE_PAYMENTPREFERENCE_REQUEST: 'SAVE_PAYMENTPREFERENCE_REQUEST',
    SAVE_PAYMENTPREFERENCE_SUCCESS: 'SAVE_PAYMENTPREFERENCE_SUCCESS',
    SAVE_PAYMENTPREFERENCE_FAILURE: 'SAVE_PAYMENTPREFERENCE_FAILURE',
    PAY_ENR_FEE_REQUEST: 'PAY_ENR_FEE_REQUEST',
    PAY_ENR_FEE_SUCCESS: 'PAY_ENR_FEE_SUCCESS',
    PAY_ENR_FEE_FAILURE: 'PAY_ENR_FEE_FAILURE',
    /** My Payments End */
    /** My Profile Start */
    GET_PROFILE_DETAILS_REQUEST: 'GET_PROFILE_DETAILS_REQUEST',
    GET_PROFILE_DETAILS_SUCCESS: 'GET_PROFILE_DETAILS_SUCCESS',
    GET_PROFILE_DETAILS_FAILURE: 'GET_PROFILE_DETAILS_FAILURE',
    SAVE_PROFILE_DETAILS_REQUEST: 'SAVE_PROFILE_DETAILS_REQUEST',
    SAVE_PROFILE_DETAILS_SUCCESS: 'SAVE_PROFILE_DETAILS_SUCCESS',
    SAVE_PROFILE_DETAILS_FAILURE: 'SAVE_PROFILE_DETAILS_FAILURE',
    SET_PROFILE_DETAILS: 'SET_PROFILE_DETAILS',
    SET_PROFILE_DETAILS_EDITABLE: 'SET_PROFILE_DETAILS_EDITABLE',
    SET_PROFILE_PREDECTIVE_ADDRESS_SUCCESS: 'SET_PROFILE_PREDECTIVE_ADDRESS_SUCCESS',
    SET_SEARCH_PROFILE_ADDRESS_LINE: 'SET_SEARCH_PROFILE_ADDRESS_LINE',
    GET_DO_GET_PROFILE_ADDRESS: 'GET_DO_GET_PROFILE_ADDRESS',
    GET_DO_GET_PROFILE_ADDRESS_SUCCESS: 'GET_DO_GET_PROFILE_ADDRESS_SUCCESS',
    GET_DO_GET_PROFILE_ADDRESS_FAILURE: 'GET_DO_GET_PROFILE_ADDRESS_FAILURE',
    SET_NAVIGATE_TO_PROFILE_MANUAL_ADDRESS: 'SET_NAVIGATE_TO_PROFILE_MANUAL_ADDRESS',
    SET_PROFILE_STREET_NO: 'SET_PROFILE_STREET_NO',
    SET_PROFILE_STREET_NAME: 'SET_PROFILE_STREET_NAME',
    SET_PROFILE_SUBURB: 'SET_PROFILE_SUBURB',
    SET_PROFILE_STATE: 'SET_PROFILE_STATE',
    SET_PROFILE_POSTCODE: 'SET_PROFILE_POSTCODE',
    SET_PROFILE_ADDRESS_CHANGED: 'SET_PROFILE_ADDRESS_CHANGED',
    /** My Profile End */
    SET_MENU_DISPLAY: 'SET_MENU_DISPLAY',
  
    SAVE_PAYOUT_DETAILS_REQUEST: 'SAVE_PAYOUT_DETAILS_REQUEST',
    SAVE_PAYOUT_DETAILS_SUCCESS: 'SAVE_PAYOUT_DETAILS_SUCCESS',
    SAVE_PAYOUT_DETAILS_FAILUER: 'SAVE_PAYOUT_DETAILS_FAILUER',
  
    GET_NOTIFICATION_REQUEST: 'GET_NOTIFICATION_REQUEST',
    GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
    GET_NOTIFICATION_FAILUER: 'GET_NOTIFICATION_FAILUER',
  
    CREATE_NOTIFICATION_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
    CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
    CREATE_NOTIFICATION_FAILUER: 'CREATE_NOTIFICATION_FAILUER',
  
    CREATE_HYPERWALLET_USER_REQUEST: 'CREATE_HYPERWALLET_USER_REQUEST',
    CREATE_HYPERWALLET_USER_SUCCESS: 'CREATE_HYPERWALLET_USER_SUCCESS',
    CREATE_HYPERWALLET_USER_FAILURE: 'CREATE_HYPERWALLET_USER_FAILURE',
    FILE_DOWNLOAD_REQUEST: 'FILE_DOWNLOAD_REQUEST',
    FILE_DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
    FILE_DOWNLOAD_FAILURE: 'FILE_DOWNLOAD_FAILURE',
  
    CREATE_INQUIRY_REQUEST: 'CREATE_INQUIRY_REQUEST',
    CREATE_INQUIRY_SUCCESS: 'CREATE_INQUIRY_SUCCESS',
    CREATE_INQUIRY_FAILURE: 'CREATE_INQUIRY_FAILURE',
  
    SUBMIT_PAYOUT_REQUEST: 'SUBMIT_PAYOUT_REQUEST',
    SUBMIT_PAYOUT_SUCCESS: 'SUBMIT_PAYOUT_SUCCESS',
    SUBMIT_PAYOUT_FAILURE: 'SUBMIT_PAYOUT_FAILURE',
  
    SET_PROMOTION_ID: 'SET_PROMOTION_ID',
    SET_DELETE_SELECTED_CARD: 'SET_DELETE_SELECTED_CARD',
    SET_API_ERROR: 'SET_API_ERROR',
    CLEAR_CLAIM_STATE: 'CLEAR_CLAIM_STATE',
    CLEAR_BUYNOW_STATE: 'CLEAR_BUYNOW_STATE',
    SET_ARTICLE_DATA: 'SET_ARTICLE_DATA',
    SET_BRAND_NAME: 'SET_BRAND_NAME',
    SET_SELECTED_TAB: 'SET_SELECTED_TAB',
    SET_SELECTED_OS: 'SET_SELECTED_OS',
    DISPLAY_SELECTED_QUESTION: 'DISPLAY_SELECTED_QUESTION',
    GET_DEVICE_IMAGES_REQUEST: 'GET_DEVICE_IMAGES_REQUEST',
    GET_DEVICE_IMAGES_SUCCESS: 'GET_DEVICE_IMAGES_SUCCESS',
    GET_DEVICE_IMAGES_FAILURE: 'GET_DEVICE_IMAGES_FAILURE',
    FILE_UPLOAD_ERROR: 'FILE_UPLOAD_ERROR',
    GET_SECURITY_ADVISER_TOKEN_REQUEST: 'GET_SECURITY_ADVISER_TOKEN_REQUEST',
    GET_SECURITY_ADVISER_TOKEN_SUCCESS: 'GET_SECURITY_ADVISER_TOKEN_SUCCESS',
    GET_SECURITY_ADVISER_TOKEN_FAILURE: 'GET_SECURITY_ADVISER_TOKEN_FAILURE',
    SET_CLAIM_BUTTON_STATUS: 'SET_CLAIM_BUTTON_STATUS',
  
    REDIRECT_FROM_BUYNOW: 'REDIRECT_FROM_BUYNOW',
    CLEAR_REQUIRED_DOC: 'CLEAR_REQUIRED_DOC',
  
    APP_STATE: 'APP_STATE',
    SET_CHAT_INSTANCE: 'SET_CHAT_INSTANCE',
  
    setKeepUpdatedViaFB: 'setKeepUpdatedViaFB',
    setKeepUpdatedViaWhatsapp: 'setKeepUpdatedViaWhatsapp',
  
    RESOLVE_NOTIFICATION_REQUEST: 'RESOLVE_NOTIFICATION_REQUEST',
    RESOLVE_NOTIFICATION_SUCCESS: 'RESOLVE_NOTIFICATION_SUCCESS',
    RESOLVE_NOTIFICATION_FAILUER: 'RESOLVE_NOTIFICATION_FAILUER',
  
    SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
    CLEAR_STATE: 'CLEAR_STATE',
    CLEAR_ADDRESS: 'CLEAR_ADDRESS',
    SET_REASON_FOR_NO_VIDEO: 'SET_REASON_FOR_NO_VIDEO',
  
    RESET_IMEI_FILE: 'RESET_IMEI_FILE',
  
    POST_LOGGER: 'POST_LOGGER',
  
    SET_BATTERY_CAPACITY: 'SET_BATTERY_CAPACITY',
    GET_BATTERY_WARRANTY_REQUEST: 'GET_BATTERY_WARRANTY_REQUEST',
    GET_BATTERY_WARRANTY_SUCCESS: 'GET_BATTERY_WARRANTY_SUCCESS',
    GET_BATTERY_WARRANTY_FAILURE: 'GET_BATTERY_WARRANTY_FAILURE',
  
    SET_ENABLE_UPLOAD_BUTTON: 'SET_ENABLE_UPLOAD_BUTTON',
    CLEAR_PHOTO_DETAILS: 'CLEAR_PHOTO_DETAILS',
    SET_DATA_CLEARED: 'SET_DATA_CLEARED',
    SET_OTHER_FEEDBACK: 'SET_OTHER_FEEDBACK',
  
    // chat action constants start
    RESET_CHAT: 'RESET_CHAT',
    RESET_CHATBOT: 'RESET_CHATBOT',
    SET_CHATBOT_LOGOUT_URL: 'SET_CHATBOT_LOGOUT_URL',
    SET_CHATBOT_MINIMIZED: 'SET_CHATBOT_MINIMIZED',
    SET_VISITOR: 'SET_VISITOR',
    SET_CONVERSATION_ID: 'SET_CONVERSATION_ID',
    SET_ACTIVE_REQUEST_ID: 'SET_ACTIVE_REQUEST_ID',
    SET_CHAT_STATUS: 'SET_CHAT_STATUS',
    SET_CHAT_CONNECT: 'SET_CHAT_CONNECT',
    SET_CHAT_REASON: 'SET_CHAT_REASON',
    SET_CHAT_INPUT_VISIBILITY: 'SET_CHAT_INPUT_VISIBILITY',
    SET_ALLOW_ADDRESS_CHAT_CONNECT: 'SET_ALLOW_ADDRESS_CHAT_CONNECT',
    SET_ALLOW_PAYMENT_CHAT_CONNECT: 'SET_ALLOW_PAYMENT_CHAT_CONNECT',
    SET_ALLOW_NRIC_CHAT_CONNECT: 'SET_ALLOW_NRIC_CHAT_CONNECT',
    SET_CHAT_ASSISTED: 'SET_CHAT_ASSISTED',
    ADD_JOURNEY_MSG: 'ADD_JOURNEY_MSG',
    SET_API_PROGRESS: 'SET_API_PROGRESS',
    RESET_API_PROGRESS: 'RESET_API_PROGRESS',
    SET_SHOW_CHAT_INPUT: 'SET_SHOW_CHAT_INPUT',
    CREATE_VISITOR: 'CREATE_VISITOR',
    SEND_APPSYNC_MSG: 'SEND_APPSYNC_MSG',
    UPDATE_CHAT_CONNECT: 'UPDATE_CHAT_CONNECT',
    // chat action constants end
  
    SET_UTM_ID: 'SET_UTM_ID',
    SET_UTM_URL: 'SET_UTM_URL',
    SET_GACLIENT_ID: 'SET_GACLIENT_ID',
  
    SAVE_VIDEO_REASON_REQUEST: 'SAVE_VIDEO_REASON_REQUEST',
    SAVE_VIDEO_REASON_SUCCESS: 'SAVE_VIDEO_REASON_SUCCESS',
    SAVE_VIDEO_REASON_FAILURE: 'SAVE_VIDEO_REASON_FAILURE',
    //Receipt
    SET_SELECTED_RECEIPT_SCREENSHOT: 'SET_SELECTED_RECEIPT_SCREENSHOT',
    CLEAR_RECEIPT_DETAILS: 'CLEAR_RECEIPT_DETAILS',
    GET_RECEIPT_DETAILS_SUCCESS: 'GET_RECEIPT_DETAILS_SUCCESS',
    GET_RECEIPT_DETAILS_FAILURE: 'GET_RECEIPT_DETAILS_FAILURE',
    GET_RECEIPT_DETAILS_REQUEST: 'GET_RECEIPT_DETAILS_REQUEST',
    SET_IS_NEW_PHONE: 'SET_IS_NEW_PHONE',
    SET_NEW_PHONE:'SET_NEW_PHONE',
  
    GET_PROMO_OFFERS_REQUEST: 'GET_PROMO_OFFERS_REQUEST',
    GET_PROMO_OFFERS_SUCCESS: 'GET_PROMO_OFFERS_SUCCESS',
    GET_PROMO_OFFERS_FAILURE: 'GET_PROMO_OFFERS_FAILURE',
    CLEAR_PROMO_ERROR: 'CLEAR_PROMO_ERROR',
  
    SET_PROGRESS_BAR_VALUE: 'SET_PROGRESS_BAR_VALUE',
  
    SET_IMEI_VALUE: 'SET_IMEI_VALUE',
    SET_MY_PHONE: 'SET_MY_PHONE',
  
    UPDATE_DEVICE_INQUIRY_REQUEST: 'UPDATE_DEVICE_INQUIRY_REQUEST',
    UPDATE_DEVICE_INQUIRY_SUCCESS: 'UPDATE_DEVICE_INQUIRY_SUCCESS',
    UPDATE_DEVICE_INQUIRY_FAILURE: 'UPDATE_DEVICE_INQUIRY_FAILURE',
  
    CLEAR_UPDATE_DEVICE_STATE: 'CLEAR_UPDATE_DEVICE_STATE',
    CLEAR_UPLOAD_DOCUMENT_STATE: 'CLEAR_UPLOAD_DOCUMENT_STATE',
  
    SET_SELECTED_DEVICE_REASON_VALUE: 'SET_SELECTED_DEVICE_REASON_VALUE',
  
    CLEAR_PAYOUT_DETAILS: 'CLEAR_PAYOUT_DETAILS',
    CLEAR_PAYOUT: 'CLEAR_PAYOUT',
    SET_DISABLED_FMIP: 'SET_DISABLED_FMIP',
    SEND_REFERAL_EMAIL_REQUEST: 'SEND_REFERAL_EMAIL_REQUEST',
    SEND_REFERAL_EMAIL_SUCCESS: 'SEND_REFERAL_EMAIL_SUCCESS',
    SEND_REFERAL_EMAIL_FAILURE: 'SEND_REFERAL_EMAIL_FAILURE',
    SET_REASON_FOR_POLICY_CANCELLATION: 'SET_REASON_FOR_POLICY_CANCELLATION',
  
    CANCEL_POLICY_DEATILS: 'CANCEL_POLICY_DEATILS',
    CANCEL_POLICY_DEATILS_SUCCESS: 'CANCEL_POLICY_DEATILS_SUCCESS',
    CANCEL_POLICY_DEATILS_FAILURE: 'CANCEL_POLICY_DEATILS_FAILURE',
  
    CANCEL_POLICY: 'CANCEL_POLICY',
    CANCEL_POLICY_SUCCESS: 'CANCEL_POLICY_SUCCESS',
    CANCEL_POLICY_FAILURE: 'CANCEL_POLICY_FAILURE',
  
    CREATE_CONTACTUS_INQUIRY_REQUEST: 'CREATE_CONTACTUS_INQUIRY_REQUEST',
    CREATE_CONTACTUS_INQUIRY_SUCCESS: 'CREATE_CONTACTUS_INQUIRY_SUCCESS',
    CREATE_CONTACTUS_INQUIRY_FAILURE: 'CREATE_CONTACTUS_INQUIRY_FAILURE',
  
    CREATE_CONTACTUS_COMPLAINT_REQUEST: 'CREATE_CONTACTUS_COMPLAINT_REQUEST',
    CREATE_CONTACTUS_COMPLAINT_SUCCESS: 'CREATE_CONTACTUS_COMPLAINT_SUCCESS',
    CREATE_CONTACTUS_COMPLAINT_FAILURE: 'CREATE_CONTACTUS_COMPLAINT_FAILURE',
  
    SET_CONTACTUS_SUBMIT_ENQUIRY: 'SET_CONTACTUS_SUBMIT_ENQUIRY',
    SET_CONTACTUS_SUBMIT_COMPLAINT: 'SET_CONTACTUS_SUBMIT_COMPLAINT',
  
    CLEAR_CANCEL_PLAN_DETAILS: 'CLEAR_CANCEL_PLAN_DETAILS',
  
    GE_PAGE_SOURCE: 'GE_PAGE_SOURCE',
  
    SET_CONTACTUS_SUBMIT_DONE: 'SET_CONTACTUS_SUBMIT_DONE',
    SET_CONTACTUS_CUSTOMER: 'SET_CONTACTUS_CUSTOMER',
  
    CLEAR_UPLOADED_FILE: 'CLEAR_UPLOADED_FILE',
    SET_CONTACTUS_ERROR: 'SET_CONTACTUS_ERROR',
  
    CREATE_INTERACTION_REQUEST: 'CREATE_INTERACTION_REQUEST',
    CREATE_INTERACTION_SUCCESS: 'CREATE_INTERACTION_SUCCESS',
    CREATE_INTERACTION_FAILURE: 'CREATE_INTERACTION_FAILURE',
    CLEAR_VERIFY_LOGIN_OTP: 'CLEAR_VERIFY_LOGIN_OTP',
  
    RESET_SEND_REFERAL_EMAIL: 'RESET_SEND_REFERAL_EMAIL',
    SET_OTHER_CANCEL_REASON: 'SET_OTHER_CANCEL_REASON',
    GET_SHIPPING_ORDER_AND_METHODS_REQUEST: 'GET_SHIPPING_ORDER_AND_METHODS_REQUEST',
    CREATE_CHATBOT_MESSAGE_REQUEST: 'CREATE_CHATBOT_MESSAGE_REQUEST',
    CREATE_CHATBOT_MESSAGE_SUCCESS: 'CREATE_CHATBOT_MESSAGE_SUCCESS',
    CREATE_CHATBOT_MESSAGE_FAILURE: 'CREATE_CHATBOT_MESSAGE_FAILURE',
    SELECT_CHATBOT_SUGGESTION: 'SELECT_CHATBOT_SUGGESTION',
    CHATBOT_STATUS: 'CHATBOT_STATUS',
    SET_CHATBOT_TRANSFER_REASON: 'SET_CHATBOT_TRANSFER_REASON',
    SET_ACTIVE_CHAT: 'SET_ACTIVE_CHAT',
  
    GET_AGREEMENT_DETAILS_REQUEST: 'GET_AGREEMENT_DETAILS_REQUEST',
    GET_AGREEMENT_DETAILS_SUCCESS: 'GET_AGREEMENT_DETAILS_SUCCESS',
    GET_AGREEMENT_DETAILS_FAILURE: 'GET_AGREEMENT_DETAILS_FAILURE',
  
    SET_QR_SCANNED_FROM_DESKTOP: 'SET_QR_SCANNED_FROM_DESKTOP',
    GET_ALL_QUOTE_REQUEST: 'GET_ALL_QUOTE_REQUEST',
    GET_ALL_QUOTE_SUCCESS: 'GET_ALL_QUOTE_SUCCESS',
    GET_ALL_QUOTE_FAILURE: 'GET_ALL_QUOTE_FAILURE',
  
    SET_RECEIPT_PROVIDER: 'SET_RECEIPT_PROVIDER',
    CLEAR_PLAN_DETAILS:'CLEAR_PLAN_DETAILS',
  
    CLEAR_OTP_SENT: 'CLEAR_OTP_SENT',
    SET_VHC_TAGS: 'SET_VHC_TAGS',
    CLEAR_PROMO_DETAILS: 'CLEAR_PROMO_DETAILS',
    SET_MODEL_QUERY_PARAM:'SET_MODEL_QUERY_PARAM',
  
    SET_UTM_SOURCE:'SET_UTM_SOURCE',
    SET_UTM_MEDIUM:'SET_UTM_MEDIUM',
    SET_UTM_CAMPAIGN:'SET_UTM_CAMPAIGN',
    SET_UTM_ID:'SET_UTM_ID',
    SET_UTM_CONTENT:'SET_UTM_CONTENT',
  
    SET_AUTH_CARD: 'SET_AUTH_CARD'
  }
  