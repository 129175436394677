/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg className={className} width="90" height="160" viewBox="0 0 90 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M85.16 25.2109H5.06995V129.101H85.16V25.2109Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M86.1899 25.2109V15.1108C86.1899 10.1108 82.51 6.05078 77.97 6.05078H69.76L61.9299 15.2607C61.1995 16.1476 60.2841 16.8641 59.2476 17.3599C58.2112 17.8556 57.0788 18.1188 55.9299 18.1309H34.33C33.1809 18.1202 32.0481 17.8577 31.0114 17.3618C29.9748 16.8659 29.0595 16.1486 28.33 15.2607L20.4899 6.05078H12.28C7.73997 6.05078 4.05994 10.1108 4.05994 15.1108V25.1809L86.1899 25.2109Z" fill="#6E767D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M4.03003 129.109V144.919C4.03003 149.919 7.71 153.919 12.25 153.919H77.97C82.51 153.919 86.19 149.919 86.19 144.919V129.109H4.03003Z" fill="#6E767D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M69.7601 6.10938L61.93 15.3193C61.1996 16.2062 60.2841 16.9227 59.2477 17.4185C58.2112 17.9142 57.0788 18.1774 55.93 18.1895H34.33C33.1809 18.1788 32.0481 17.9163 31.0115 17.4204C29.9748 16.9245 29.0595 16.2072 28.33 15.3193L20.49 6.10938H69.7601Z" fill="#D5D6DA"/>
    <path d="M77.97 5.05005H70.08C69.9763 5.0181 69.8686 5.00121 69.7601 5H20.46C20.3516 5.00201 20.2439 5.01888 20.14 5.05005H12.25C7.15 5.05005 3 9.57014 3 15.1401V144.91C3 150.47 7.15 155 12.25 155H77.97C83.07 155 87.22 150.47 87.22 144.91V15.1101C87.22 9.57011 83.07 5.05005 77.97 5.05005ZM67.41 7.17017L61.1 14.5901C60.472 15.3538 59.6849 15.9713 58.7937 16.3997C57.9025 16.828 56.9287 17.0567 55.94 17.0701H34.33C33.3403 17.0568 32.3655 16.8271 31.4741 16.397C30.5826 15.9668 29.7962 15.3466 29.17 14.5801L22.86 7.17017H67.41ZM85.15 144.91C85.15 149.33 81.93 152.91 77.97 152.91H12.25C8.25 152.91 5.07001 149.31 5.07001 144.91V15.1101C5.07001 10.6901 8.29 7.11011 12.25 7.11011H19.92L27.47 15.9802C28.2996 16.9856 29.3389 17.7975 30.5153 18.3589C31.6917 18.9203 32.9766 19.2177 34.28 19.2302H55.94C57.2435 19.2184 58.5287 18.9211 59.7051 18.3596C60.8816 17.7982 61.9209 16.9862 62.75 15.9802L70.33 7.11011H78.0001C82.0001 7.11011 85.18 10.7101 85.18 15.1101L85.15 144.91Z" fill="#333F48"/>
    <path d="M45.11 148.109C48.7441 148.109 51.69 145.163 51.69 141.529C51.69 137.895 48.7441 134.949 45.11 134.949C41.476 134.949 38.53 137.895 38.53 141.529C38.53 145.163 41.476 148.109 45.11 148.109Z" fill="white"/>
    <path d="M45.11 149.109C43.6029 149.109 42.1297 148.662 40.8766 147.825C39.6235 146.988 38.6468 145.798 38.0701 144.405C37.4933 143.013 37.3424 141.481 37.6364 140.002C37.9304 138.524 38.6562 137.167 39.7219 136.101C40.7875 135.035 42.1453 134.31 43.6234 134.016C45.1015 133.722 46.6337 133.872 48.026 134.449C49.4184 135.026 50.6085 136.002 51.4458 137.256C52.2831 138.509 52.73 139.982 52.73 141.489C52.73 143.51 51.9272 145.448 50.4982 146.877C49.0691 148.306 47.131 149.109 45.11 149.109ZM45.11 135.949C44.0119 135.949 42.9385 136.275 42.0256 136.885C41.1127 137.495 40.4013 138.363 39.9815 139.377C39.5617 140.392 39.4524 141.509 39.6674 142.585C39.8824 143.662 40.412 144.651 41.1891 145.427C41.9663 146.203 42.9561 146.731 44.0334 146.944C45.1106 147.157 46.2269 147.045 47.2408 146.624C48.2548 146.202 49.1209 145.489 49.7295 144.575C50.3382 143.661 50.662 142.587 50.66 141.489C50.6495 140.025 50.0597 138.625 49.0198 137.594C47.9798 136.563 46.5742 135.986 45.11 135.989V135.949Z" fill="#333F48"/>
    <path d="M85.15 26.2395H5.07001C4.79683 26.2395 4.53484 26.1312 4.34167 25.938C4.14851 25.7448 4.04004 25.4829 4.04004 25.2097C4.04004 24.9365 4.14851 24.6744 4.34167 24.4812C4.53484 24.288 4.79683 24.1797 5.07001 24.1797H85.15C85.4231 24.1797 85.6852 24.288 85.8784 24.4812C86.0715 24.6744 86.18 24.9365 86.18 25.2097C86.18 25.4829 86.0715 25.7448 85.8784 25.938C85.6852 26.1312 85.4231 26.2395 85.15 26.2395Z" fill="#333F48"/>
    <path d="M85.15 130.109H5.07001C4.79551 130.109 4.53223 130 4.33813 129.806C4.14403 129.612 4.03503 129.349 4.03503 129.074C4.03503 128.8 4.14403 128.536 4.33813 128.342C4.53223 128.148 4.79551 128.039 5.07001 128.039H85.15C85.4245 128.039 85.6878 128.148 85.8819 128.342C86.076 128.536 86.185 128.8 86.185 129.074C86.185 129.349 86.076 129.612 85.8819 129.806C85.6878 130 85.4245 130.109 85.15 130.109Z" fill="#333F48"/>
    <path d="M14.42 57.6602C14.1548 57.6602 13.9004 57.5547 13.7129 57.3672C13.5254 57.1797 13.42 56.9254 13.42 56.6602V37.6602C13.42 37.3949 13.5254 37.1404 13.7129 36.9529C13.9004 36.7653 14.1548 36.6602 14.42 36.6602H33.42C33.6853 36.6602 33.9396 36.7653 34.1271 36.9529C34.3147 37.1404 34.42 37.3949 34.42 37.6602C34.42 37.9254 34.3147 38.1797 34.1271 38.3672C33.9396 38.5547 33.6853 38.6602 33.42 38.6602H15.42V56.6602C15.42 56.9254 15.3147 57.1797 15.1271 57.3672C14.9396 57.5547 14.6853 57.6602 14.42 57.6602Z" fill="#8223D2"/>
    <path d="M75.8099 57.6602C76.0752 57.6602 76.3296 57.5547 76.5171 57.3672C76.7046 57.1797 76.8099 56.9254 76.8099 56.6602V37.6602C76.8099 37.3949 76.7046 37.1404 76.5171 36.9529C76.3296 36.7653 76.0752 36.6602 75.8099 36.6602H56.8099C56.5447 36.6602 56.2904 36.7653 56.1028 36.9529C55.9153 37.1404 55.8099 37.3949 55.8099 37.6602C55.8099 37.9254 55.9153 38.1797 56.1028 38.3672C56.2904 38.5547 56.5447 38.6602 56.8099 38.6602H74.8099V56.6602C74.8099 56.9254 74.9154 57.1797 75.1029 57.3672C75.2904 57.5547 75.5447 57.6602 75.8099 57.6602Z" fill="#8223D2"/>
    <path d="M14.42 96.6406C14.1548 96.6406 13.9004 96.7461 13.7129 96.9336C13.5254 97.1211 13.42 97.3754 13.42 97.6406V116.641C13.42 116.906 13.5254 117.16 13.7129 117.348C13.9004 117.535 14.1548 117.641 14.42 117.641H33.42C33.6853 117.641 33.9396 117.535 34.1271 117.348C34.3147 117.16 34.42 116.906 34.42 116.641C34.42 116.375 34.3147 116.121 34.1271 115.934C33.9396 115.746 33.6853 115.641 33.42 115.641H15.42V97.6406C15.42 97.3754 15.3147 97.1211 15.1271 96.9336C14.9396 96.7461 14.6853 96.6406 14.42 96.6406Z" fill="#8223D2"/>
    <path d="M75.8099 96.6406C76.0752 96.6406 76.3296 96.7461 76.5171 96.9336C76.7046 97.1211 76.8099 97.3754 76.8099 97.6406V116.641C76.8099 116.906 76.7046 117.16 76.5171 117.348C76.3296 117.535 76.0752 117.641 75.8099 117.641H56.8099C56.5447 117.641 56.2904 117.535 56.1028 117.348C55.9153 117.16 55.8099 116.906 55.8099 116.641C55.8099 116.375 55.9153 116.121 56.1028 115.934C56.2904 115.746 56.5447 115.641 56.8099 115.641H74.8099V97.6406C74.8099 97.3754 74.9154 97.1211 75.1029 96.9336C75.2904 96.7461 75.5447 96.6406 75.8099 96.6406Z" fill="#8223D2"/>
    <path d="M16.52 79.6503L19.52 75.7802C19.7218 75.5316 19.8931 75.2598 20.03 74.9704C20.1207 74.741 20.1682 74.4967 20.17 74.2501C20.1843 73.9576 20.0919 73.67 19.91 73.4403C19.8096 73.3401 19.689 73.2623 19.5562 73.2123C19.4235 73.1623 19.2816 73.1412 19.14 73.1503C19.0034 73.1455 18.8673 73.1688 18.74 73.2186C18.6127 73.2685 18.497 73.344 18.4 73.4403C18.1939 73.6622 18.0703 73.948 18.05 74.2501L16.65 73.9901C16.6695 73.5824 16.801 73.1881 17.03 72.8502C17.2547 72.5093 17.5651 72.2334 17.93 72.0502C18.3126 71.8514 18.739 71.7517 19.17 71.7601C19.5077 71.7403 19.8459 71.7895 20.164 71.9047C20.4821 72.0199 20.7733 72.1988 21.02 72.4303C21.2428 72.6642 21.4161 72.9406 21.5295 73.2431C21.6429 73.5455 21.6941 73.8676 21.68 74.1903C21.6725 74.9191 21.4188 75.624 20.96 76.1903L18.96 78.8703H21.91V80.2601H16.57L16.52 79.6503Z" fill="#8223D2"/>
    <path d="M23.1899 74.1999H24.6799V75.1999C24.8973 74.8832 25.1804 74.6171 25.5099 74.4198C25.8102 74.2329 26.1563 74.1325 26.5099 74.1298C27.0043 74.1026 27.4894 74.2715 27.86 74.5998C28.0372 74.7748 28.1744 74.9862 28.2625 75.2192C28.3505 75.4521 28.3871 75.7014 28.3699 75.9499V80.2697H26.9299V76.3998C26.9442 76.2634 26.9302 76.1255 26.8889 75.9948C26.8476 75.864 26.7799 75.7433 26.6899 75.6398C26.6079 75.5574 26.5094 75.4934 26.4008 75.4521C26.2921 75.4107 26.176 75.3929 26.0599 75.3998C25.8045 75.4061 25.5612 75.5099 25.3799 75.6899C25.1427 75.9231 24.9674 76.2116 24.8699 76.5297C24.7387 76.9607 24.6746 77.4093 24.6799 77.8598V80.2697H23.1899V74.1999Z" fill="#8223D2"/>
    <path d="M30.45 79.4998C29.9612 78.8473 29.717 78.0441 29.76 77.23C29.718 76.4129 29.962 75.6067 30.45 74.95C30.8103 74.5633 31.2784 74.2937 31.7936 74.176C32.3088 74.0584 32.8476 74.0979 33.34 74.2898C33.589 74.3909 33.809 74.5524 33.98 74.7598V71.7598H35.47V80.2498H33.98V79.6299C33.8464 79.8515 33.6472 80.0263 33.41 80.1299C33.098 80.2791 32.7558 80.3543 32.41 80.3499C32.0395 80.3723 31.6688 80.3071 31.3283 80.1594C30.9877 80.0117 30.6868 79.7856 30.45 79.4998ZM33.72 78.5898C33.9744 78.1819 34.1093 77.7108 34.1093 77.23C34.1093 76.7492 33.9744 76.2779 33.72 75.8699C33.6047 75.7141 33.4537 75.5882 33.2797 75.5029C33.1057 75.4177 32.9137 75.3755 32.72 75.3799C32.5266 75.3779 32.3355 75.4213 32.1619 75.5063C31.9883 75.5914 31.8369 75.7159 31.72 75.8699C31.4653 76.2759 31.3302 76.7455 31.3302 77.2249C31.3302 77.7042 31.4653 78.1738 31.72 78.5798C31.8364 78.7351 31.9874 78.8612 32.161 78.948C32.3345 79.0348 32.5259 79.0798 32.72 79.0798C32.9133 79.0818 33.1045 79.0384 33.2781 78.9534C33.4517 78.8683 33.6031 78.7438 33.72 78.5898Z" fill="#8223D2"/>
    <path d="M40.25 71.8501H43.41C43.7738 71.8211 44.1395 71.8685 44.4839 71.989C44.8284 72.1095 45.1438 72.3005 45.41 72.55C45.7978 72.9161 46.0616 73.3942 46.1646 73.9175C46.2675 74.4408 46.2043 74.9833 45.9841 75.469C45.7638 75.9547 45.3973 76.3594 44.9359 76.6267C44.4744 76.894 43.941 77.0107 43.41 76.9602H41.81V80.25H40.25V71.8501ZM43.35 75.52C43.5001 75.5377 43.6521 75.5212 43.7948 75.4717C43.9375 75.4221 44.0671 75.3409 44.174 75.2341C44.2808 75.1273 44.362 74.9977 44.4116 74.855C44.4611 74.7123 44.4777 74.5602 44.46 74.4101C44.4793 74.2593 44.464 74.1061 44.4152 73.9621C44.3664 73.8182 44.2854 73.6871 44.1784 73.5791C44.0714 73.4711 43.9411 73.3889 43.7976 73.3389C43.6541 73.2888 43.501 73.2721 43.35 73.29H41.81V75.52H43.35Z" fill="#8223D2"/>
    <path d="M47.33 71.7598H48.8199V75.1799C49.0184 74.8581 49.2932 74.5902 49.6199 74.3999C49.9607 74.205 50.3474 74.1049 50.7399 74.1099C51.2414 74.0845 51.734 74.2487 52.1199 74.5698C52.2945 74.7484 52.4293 74.9619 52.5155 75.1963C52.6017 75.4307 52.6373 75.6808 52.6199 75.9299V80.2498H51.1299V76.46C51.1537 76.1694 51.076 75.8795 50.91 75.6399C50.823 75.5489 50.7168 75.4783 50.5992 75.4333C50.4816 75.3884 50.3555 75.3702 50.23 75.3799C49.9813 75.3824 49.741 75.4707 49.5499 75.6299C49.3092 75.836 49.1266 76.1015 49.02 76.3999C48.8762 76.8178 48.8084 77.2582 48.8199 77.7V80.2498H47.33V71.7598Z" fill="#8223D2"/>
    <path d="M55.33 79.941C54.8763 79.6773 54.5083 79.2883 54.27 78.8208C54.0247 78.3272 53.9012 77.7821 53.91 77.231C53.9012 76.6766 54.0246 76.1281 54.27 75.6309C54.5112 75.1681 54.8788 74.7831 55.33 74.5208C55.8322 74.236 56.4029 74.0942 56.98 74.1109C57.5541 74.093 58.1218 74.2348 58.6199 74.5208C59.0754 74.7777 59.4443 75.1641 59.6799 75.6309C59.9333 76.1256 60.0604 76.6753 60.0499 77.231C60.0604 77.7835 59.9333 78.3298 59.6799 78.8208C59.448 79.2928 59.0784 79.6834 58.6199 79.941C58.1201 80.2226 57.5536 80.3642 56.98 80.3509C56.4034 80.3626 55.834 80.2212 55.33 79.941ZM58.08 78.5408C58.3602 78.1628 58.5047 77.7014 58.4899 77.231C58.503 76.7578 58.3589 76.2935 58.08 75.9109C57.9528 75.7405 57.7864 75.6033 57.5949 75.511C57.4034 75.4188 57.1925 75.3741 56.98 75.3809C56.7661 75.3742 56.5537 75.4188 56.3605 75.511C56.1674 75.6032 55.9992 75.7404 55.8699 75.9109C55.5939 76.2944 55.4532 76.7588 55.47 77.231C55.4516 77.7003 55.5925 78.1618 55.8699 78.5408C55.997 78.7145 56.1645 78.8544 56.358 78.9485C56.5514 79.0426 56.765 79.0881 56.98 79.0809C57.1968 79.0907 57.4126 79.0464 57.6081 78.9522C57.8036 78.8579 57.9726 78.7166 58.1 78.5408H58.08Z" fill="#8223D2"/>
    <path d="M61.41 74.1999H62.9V75.1999C63.1174 74.8832 63.4005 74.6171 63.73 74.4198C64.0303 74.2329 64.3764 74.1325 64.73 74.1298C65.2244 74.1026 65.7095 74.2715 66.08 74.5998C66.2572 74.7748 66.3945 74.9862 66.4825 75.2192C66.5705 75.4521 66.6072 75.7014 66.59 75.9499V80.2697H65.11V76.3998C65.1243 76.2634 65.1103 76.1255 65.069 75.9948C65.0277 75.864 64.96 75.7433 64.87 75.6398C64.788 75.5574 64.6894 75.4934 64.5808 75.4521C64.4722 75.4107 64.356 75.3929 64.24 75.3998C63.9846 75.4061 63.7413 75.5099 63.56 75.6899C63.3228 75.9231 63.1475 76.2116 63.05 76.5297C62.9188 76.9607 62.8548 77.4093 62.86 77.8598V80.2697H61.37L61.41 74.1999Z" fill="#8223D2"/>
    <path d="M73.7 77.711H69.53C69.5654 78.0889 69.7317 78.4425 70.0001 78.711C70.1352 78.8328 70.2931 78.9263 70.4648 78.9864C70.6365 79.0465 70.8185 79.0718 71.0001 79.0608C71.2966 79.0683 71.5887 78.9884 71.84 78.8309C72.1003 78.647 72.3129 78.4036 72.46 78.1209L73.66 78.5608C73.4514 79.1114 73.0717 79.5806 72.5767 79.8992C72.0817 80.2178 71.4974 80.369 70.91 80.3309C70.3293 80.3476 69.7572 80.1872 69.27 79.8709C68.8335 79.5781 68.4873 79.1695 68.27 78.691C68.0664 78.2171 67.9614 77.7067 67.9614 77.191C67.9614 76.6752 68.0664 76.1648 68.27 75.691C68.4873 75.2124 68.8335 74.8036 69.27 74.5108C69.755 74.1896 70.3286 74.0288 70.91 74.0508C71.4739 74.0316 72.0298 74.1889 72.5001 74.5008C72.9068 74.7862 73.2282 75.1769 73.43 75.6309C73.6267 76.075 73.729 76.5551 73.73 77.0408L73.7 77.711ZM72.18 76.5308C72.117 76.2194 71.9681 75.932 71.7501 75.701C71.6342 75.5917 71.4972 75.5069 71.3477 75.4519C71.1982 75.397 71.0392 75.3727 70.8801 75.3809C70.578 75.3774 70.2842 75.4801 70.05 75.6709C69.8045 75.9023 69.6338 76.2017 69.56 76.5308H72.18Z" fill="#8223D2"/>
    </svg>
    
)

export default icon
