import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";
function IMEINotRead() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
      )
    const metadata = workflowState['config']?.metadata['ImeiNotRead']
    const contactMethodCheckbox = findById(metadata, 'contactMethodCheckbox')
    const btnNext = findById(metadata, 'btnNext')
    const [contactMethod, setContactMethod] = useState()
    const [selectedFile, setSelectedFile] = useState()
    const upload = findById(metadata, 'upload')
    upload.args = {
        ...upload.args,
        handleChange: setSelectedFile,
        selectedFile: selectedFile
    }

    contactMethodCheckbox.args = {
        ...contactMethodCheckbox.args,
        value: contactMethod,
        onSelect: setContactMethod
    }
    btnNext.args = {
        ...btnNext.args,
        enabled: contactMethod !== undefined
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default IMEINotRead;