import Workflow from "../workflow/workflow";
import Desktop from "./desktop";
import DeviceDetection from "./device-detection";
import AutoDetection from "./auto-detection";
import ManualDetection from "./manual-detection";
import { Route } from 'react-router-dom';
import QuoteOffer from "./quote-offer";
import GetQuote from "./get-quote";
import Login from "./login";
import EmailOTP from "./email-otp";
import Registeration from "./registeration";
import { useEffect, useContext } from "react";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import { WorkflowContext } from "../workflow/workflow-context";
import NationalidUpload from "./nationalid-upload";
import NationalidUploadSkip from "./nationalid-upload-skipped";
import ReceiptidUpload from "./receipt-upload";
import ReceiptidUploadSkip from "./receipt-upload-skipped";
import VideoCapture from "./video-capture";
import VideoNotSure from "./video-notsure";
import BypassIMEIInstructions from "./bypass-imei-instructions";
import IMEIInstructions from "./imei-instructions";
import IMEIUploadSuccess from "./imei-upload-success";
import IMEIUploadSuccess2 from "./imei-upload-success2";
import IMEIVideo from "./imei-video";
import IMEINotRead from "./imei-not-read";
import IMEINotFound from "./imei-not-found";
import IMEIDifferentDevice from "./imei-different-device";
import IMEIDeviceUnsupported from "./imei-device-unsupported";
import IMEIMismatchContactUs from "./imei-mismatch-contactus";
import PolicyDetails from "./policy-details";
import LetsGetPrepared from "./letsgetprepared";
import LetsGetPrepared2 from "./letsgetprepared2";
import LetsGetPrepared3 from "./letsgetprepared3";
import TwoPhone from "./two-phone";
import DeviceLinked from "./two-phone-device-linked";
import ProceedPolicy from "./proceed-policy";
import TestLink from "./test-link";
import NoVideoReason from "./no-video-reason";
import QRScan from "./two-phone-qr-code";

// const states = [
//   {
//     step: "device-detection",
//     next: [
//       {
//         step: "desktop",
//         condition: [
//           {
//             src: "device-detection.isDesktop",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       },
//       {
//         step: "auto-detection",
//         condition: [
//           {
//             src: "device-detection.auto",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       },
//       {
//         step: "manual-detection"
//       },
//     ],
//   },
//   {
//     step: 'auto-detection',
//     next: [{
//       step: "quote-offer",
//       condition: [
//         {
//           src: "auto-detection.DeviceSelected",
//           operator: "equal",
//           value: true,
//         },
//       ],
//     }, {
//       step: "manual-detection",
//       condition: [
//         {
//           src: "auto-detection.DeviceSelected",
//           operator: "equal",
//           value: false,
//         },
//       ],
//     }]
//   },
//   {
//     step: 'manual-detection',
//     next: [
//       {
//         step: "quote-offer",
//         condition: [
//           {
//             src: "manual-detection.DeviceSelected",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'desktop',
//     next: [
//       {
//         step: "routes",
//         condition: [
//           {
//             src: "desktop.navigateRoutes",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'quote-offer',
//     next: [
//       {
//         step: "get-quote",
//         condition: [
//           {
//             src: "quote-offer.PlanSelected",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'get-quote',
//     next: [
//       {
//         step: "login",
//         condition: [
//           {
//             src: "get-quote.QuoteSelected",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'login',
//     next: [
//       {
//         step: "email-otp",
//         condition: [
//           {
//             src: "login.loggedIn",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'email-otp',
//     next: [
//       {
//         step: "registeration",
//         condition: [
//           {
//             src: "email-otp.verifiedOtp",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'registeration',
//     next: [
//       {
//         step: "nationalid-upload",
//         condition: [
//           {
//             src: "registeration.navigateTo",
//             operator: "equal",
//             value: 'nationalid-upload',
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'nationalid-upload',
//     next: [
//       {
//         step: "nationalid-skipped",
//         condition: [
//           {
//             src: "nationalid-upload.navigateTo",
//             operator: "equal",
//             value: 'NationalidUploadSkipped',
//           },
//         ],
//       },
//       {
//         step: "receipt-upload",
//         condition: [
//           {
//             src: "nationalid-upload.navigateTo",
//             operator: "equal",
//             value: 'ReceiptUpload',
//           },
//         ],
//       },
//       {
//         step: "video-capture",
//         condition: [
//           {
//             src: "nationalid-upload.navigateTo",
//             operator: "equal",
//             value: 'VideoCapture',
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'nationalid-skipped',
//     next: [
//       {
//         step: "receipt-upload",
//         condition: [
//           {
//             src: "nationalid-skipped.navigateTo",
//             operator: "equal",
//             value: 'ReceiptUpload',
//           },
//         ],
//       },
//       {
//         step: "video-capture",
//         condition: [
//           {
//             src: "nationalid-skipped.navigateTo",
//             operator: "equal",
//             value: 'VideoCapture',
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'receipt-upload',
//     next: [
//       {
//         step: "receipt-skipped",
//         condition: [
//           {
//             src: "receipt-upload.navigateTo",
//             operator: "equal",
//             value: 'ReceiptUploadSkipped',
//           },
//         ],
//       },
//       {
//         step: "video-capture",
//         condition: [
//           {
//             src: "receipt-upload.navigateTo",
//             operator: "equal",
//             value: 'videoCapture',
//           },
//         ],
//       },
//       {
//         step: "bypass-imei-instructions",
//         condition: [
//           {
//             src: "receipt-upload.navigateTo",
//             operator: "equal",
//             value: 'bypassImeissInstructions',
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'receipt-skipped',
//     next: [
//       {
//         step: "video-capture",
//         condition: [
//           {
//             src: "receipt-skipped.isSkipped",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
    
//     step: 'bypass-imei-instructions',
//     next: [
//       {
//         step: "policy-details",
//         condition: [
//           {
//             src: "bypass-imei-instructions.navigateToPolicy",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'video-capture',
//     next: [
//       {
//         step: "video-notsure",
//         condition: [
//           {
//             src: "video-capture.notSureVideo",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       },
//       {
//         step: "imei-instructions",
//         condition: [
//           {
//             src: "video-capture.navigateTo",
//             operator: "equal",
//             value: 'imei-instructions',
//           },
//         ],
//       },
//       {
//         step: "letsgetprepared",
//         condition: [
//           {
//             src: "video-capture.navigateTo",
//             operator: "equal",
//             value: 'letsgetprepared',
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'video-notsure',
//     next: [
//       {
//         step: "video-capture",
//         condition: [
//           {
//             src: "video-notsure.returnVideoCapture",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       },
//       {
//         step: "reason-for-no-video",
//         condition: [
//           {
//             src: "video-notsure.notSureToVideoOptionSelected",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'reason-for-no-video',
//     next: [
//       {
//         step: "video-capture",
//         condition: [
//           {
//             src: "reason-for-no-video.returnVideoCapture",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'letsgetprepared',
//     next: [
//       {
//         step: "letsgetprepared/2",
//         condition: [
//           {
//             src: "letsgetprepared.LetsGetPreparedV2",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'letsgetprepared/2',
//     next: [
//       {
//         step: "letsgetprepared/3",
//         condition: [
//           {
//             src: "letsgetprepared/2.LetsGetPreparedV3",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'letsgetprepared/3',
//     next: [
//       {
//         step: "two-phone/qr-code",
//         condition: [
//           {
//             src: "letsgetprepared/3.navigateToQRCode",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'imei-instructions',
//     next: [
//       {
//         step: "imei-upload-success",
//         condition: [
//           {
//             src: "imei-instructions.imeiUploadSuccess",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'imei-upload-success',
//     next: [
//       {
//         step: "imei-upload-success/2",
//         condition: [
//           {
//             src: "imei-upload-success.imeiUploadSuccessV2",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   },
//   {
//     step: 'imei-upload-success/2',
//     next: [
//       {
//         step: "imei-video",
//         condition: [
//           {
//             src: "imei-upload-success/2.imeiVideo",
//             operator: "equal",
//             value: true,
//           },
//         ],
//       }
//     ]
//   }
  
// ];

// const statesMap = states.reduce((agr, step) => (agr[step.step] = step, agr), {})

function EnrollmentWorkflow() {

  const matchCondition = (state, step) => {
    if (step.condition) {
      return step.condition.map(condition => {
        let source = condition.src.split('.').reduce((agr, item) => agr[item], state);
        if (condition.operator === 'equal') {
          return condition.value === source;
        }
        return false;
      }).reduce((agr, item) => agr && item);
    }
    return true;
  }

  const getNextStep = (workflow, state, step) => {
    console.log(state, step);
    let statesMap = workflow.reduce((agr, step) => (agr[step.step] = step, agr), {})
    let currentStep = statesMap[step];
    console.log(step, currentStep);
    if (currentStep.next.length === 1) {
      return currentStep.next[0].step;
    }
    let nextStep = currentStep.next.find(step => matchCondition(state, step))?.step;
    return nextStep;
  }

  const initialization = (state, setState) => {
    API[ActionTypes.INTIALIZE_API_REQUEST]().then(data => {
      setState({ ...state, config: data.data.InitializeResponse });
    })
  };

  return (
    <Workflow initialStep="device-detection" initialization={initialization} getNextStep={getNextStep}>
      <Route path="device-detection" element={<DeviceDetection />} />
      <Route path="desktop" element={<Desktop />} />
      <Route path="auto-detection" element={<AutoDetection />} />
      <Route path="manual-detection" element={<ManualDetection />} />
      <Route path="quote-offer" element={<QuoteOffer />} />
      <Route path="get-quote" element={<GetQuote />} />
      <Route path="login" element={<Login />} />
      <Route path="email-otp" element={<EmailOTP />} />
      <Route path="registeration" element={<Registeration />} />
      <Route path="nationalid-upload" element={<NationalidUpload />} />
      <Route path="nationalid-skipped" element={<NationalidUploadSkip />} />
      <Route path="receipt-upload" element={<ReceiptidUpload />} />
      <Route path="receipt-skipped" element={<ReceiptidUploadSkip />} />
      <Route path="video-capture" element={<VideoCapture />} />
      <Route path="video-notsure" element={<VideoNotSure />} />
      <Route path="reason-for-no-video" element={<NoVideoReason />} />
      <Route path="bypass-imei-instructions" element={<BypassIMEIInstructions />} />
      <Route path="imei-instructions" element={<IMEIInstructions />} />
      <Route path="imei-upload-success" element={<IMEIUploadSuccess />} />
      <Route path="imei-upload-success/2" element={<IMEIUploadSuccess2 />} />
      <Route path="imei-video" element={<IMEIVideo />} />
      <Route path="imei-not-read" element={<IMEINotRead />} />
      <Route path="imei-not-found" element={<IMEINotFound />} />
      <Route path="imei-different-device" element={<IMEIDifferentDevice />} />
      <Route path="imei-device-unsupported" element={<IMEIDeviceUnsupported />} />
      <Route path="imei-mismatch-contactus" element={<IMEIMismatchContactUs />} />
      <Route path="policy-details" element={<PolicyDetails />} />
      <Route path="proceed-policy" element={<ProceedPolicy />} />
      <Route path="letsgetprepared" element={<LetsGetPrepared />} />
      <Route path="letsgetprepared/2" element={<LetsGetPrepared2 />} />
      <Route path="letsgetprepared/3" element={<LetsGetPrepared3 />} />
      <Route path="two-phone" element={<TwoPhone />} />
      <Route path="two-phone/qr-code" element={<QRScan />} />
      <Route path="two-phone/device-linked" element={<DeviceLinked />} />
      <Route path="routes" element={<TestLink />} />

      <Route path="*" element={<h3>Not matching route found</h3>} />

    </Workflow>
  )
}

export default EnrollmentWorkflow;