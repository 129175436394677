/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg className={className} width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.1829 5.17382L14.0866 14.9724H11.5522L13.6486 5.17382H16.1829ZM28.7551 14.8095H31L29.0412 5.17382H26.9689C26.5035 5.17382 26.1105 5.45202 25.9359 5.88076L22.2948 14.8095H24.8431L25.3492 13.3706H28.4629L28.7551 14.8095ZM25.9959 11.4425L27.3028 7.74148L28.0549 11.4425H25.9959ZM22.5406 11.6909C22.5509 9.1381 19.1032 8.99748 19.1272 7.85712C19.1343 7.51007 19.4563 7.14123 20.1602 7.04676C20.5098 6.99989 21.471 6.9639 22.5625 7.47982L22.9898 5.42891C22.4038 5.21015 21.6493 5 20.7112 5C18.3036 5 16.6086 6.31481 16.5944 8.19744C16.5792 9.58966 17.804 10.3663 18.7273 10.8294C19.6761 11.3036 19.995 11.6072 19.991 12.0311C19.9847 12.6804 19.2338 12.9664 18.5334 12.9778C17.3084 12.9977 16.5977 12.6382 16.0311 12.3669L15.5896 14.4861C16.1588 14.7547 17.2092 14.988 18.2991 15C20.8579 15 22.5322 13.7012 22.5406 11.6909ZM12.505 5.17382L8.53802 14.8949H5.9495L3.99759 7.13678C3.87891 6.65887 3.77594 6.48409 3.41527 6.28281C2.82748 5.95488 1.85531 5.64712 1 5.45622L1.0585 5.17382H5.22451C5.75549 5.17382 6.23315 5.53676 6.35366 6.16481L7.38474 11.7905L9.93272 5.17382H12.505Z" fill="#182E66" />
    </svg>
)

export default icon
