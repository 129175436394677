import React, { useState } from "react";
import PropTypes from "prop-types";

export const Tabs = ({ tabs ,activeTab,setActiveTab }) => {
  return (
    <>
      <div className="flex flex-row w-full h-15">
        {tabs.map((tab, index) => (
          <button
          className="w-full h-full border-none flex flex-col items-center relative py-2 focus:outline-none"
            key={index}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
          >
            <span className={`${activeTab === index ?'text-purple-700 font-bold':'text-gray-400 font-normal'} flex items-center justify-center text-lg relative`} active={activeTab === index}>{tab.title}</span>
            <span className={`${activeTab === index ?'border-black':'border-gray-100'} absolute top-0 left-0 w-full h-full border-b-2 `} active={activeTab === index} />
          </button>
        ))}
      </div>
      {tabs[activeTab].render()}
    </>
  );
};

Tabs.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
};

Tabs.defaultProps = {
  enabled: true,
};
