import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import axios from "axios";
import Promise from 'bluebird'

function IMEIInstructions() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['ImeiInstructions']
  const upload = findById(metadata, 'upload')
  const [loaded, uploadProgressState] = useState(0)

  let agreementDetails = workflowState['CreateAgreementResponse'];

  let ImeiInstrDetails = workflowState['imei-instructions']


  useEffect(() => {
    let UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
        AgreementRequestStatus: 'VHC_MIRROR_INSTR1',
      }
    }
    API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
      updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
    })
  }, [])

  const uploadToS3 = (url, document, formData) => {
    return axios({
      method: 'put',
      url: url,
      headers: {
        Accept: 'application/json',
        'Content-Type': document.type,
      },
      data: document,
      onUploadProgress: (progressEvent) => {
        const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        uploadProgressState(progress)
      },
      formData: formData,
    })
      .then((response) => {
        //setNavigateNext(true)
        let UpdateAgreementRequest = {
          CreateAgreementActivityRequest: {
            AgreementRequestId: agreementDetails?.AgreementRequestId,
            IMEIBucket: ImeiInstrDetails?.PresignUrlResult?.[0]?.Bucket,
            IMEIPath: ImeiInstrDetails?.PresignUrlResult?.[0]?.Path1,
          }
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
          updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        })
        return completeCurrentStep(currentStep, {
          imeiUploadSuccess: true
        })
      })
      .catch((error) => {
      })
  }

  let handleChange = (e) => {
    updateState({
      ...workflowState, [currentStep]: {
        ...workflowState[currentStep],
        selectedImeiScreenShot: [...e.target.files]
      }
    });
  }

  // useEffect(() => {
  //   if (!navigateNext && !state?.buyNow?.selectedReceiptProvider) {
  //     actions.resetIMEIFile()
  //   }
  // }, [navigateNext])

  useEffect(() => {
    if (ImeiInstrDetails?.selectedImeiScreenShot?.length > 0) {
      getPresignUrl()
    }
  }, [ImeiInstrDetails?.selectedImeiScreenShot])

  useEffect(() => {
    if (ImeiInstrDetails?.PresignUrlResult?.length > 0) {
      Promise.map(ImeiInstrDetails?.selectedImeiScreenShot, (document, index) => {
        const formData = new FormData()
        formData.append('file', document)
        let filterUrl = ImeiInstrDetails?.PresignUrlResult?.filter((u) => u.Path1.split('/')[2] === document.name)
        return uploadToS3(filterUrl[0].PreSignedURL, document, formData)
      })
    }
  }, [ImeiInstrDetails?.PresignUrlResult])

  const getPresignUrl = (e) => {
    let files = ImeiInstrDetails?.selectedImeiScreenShot.map((file) => {
      return {
        ClaimId: agreementDetails?.AgreementRequestId || '111111111',
        FileName: file.name,
        FolderType: 'buy-now',
      }
    })
    let getPresignUrlRequest = {
      FileUploadRequest: {
        Files: files,
      },
    }
    API[ActionTypes.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST](getPresignUrlRequest).then(data => {
      updateState({
        ...workflowState, getPresignUrlResponse: data.data.Files, [currentStep]: {
          ...workflowState[currentStep],
          PresignUrlResult: data.data.Files
        }
      });
    })
  }

  upload.args = {
    ...upload.args,
    handleChange: e => handleChange(e),
    selectedFile: ImeiInstrDetails?.selectedImeiScreenShot?.[0]?.name,
    //progress: loaded,
    //removeFile: removeFile
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default IMEIInstructions;