import React from 'react'
import PropTypes from 'prop-types'
import IconCollapse from '../../icons/icon-collapse'
import IconExpand from '../../icons/icon-expand'
import { Image } from '../image/image'

export const Expandable = ({
  expanded,
  setIsOpen,
  marginClasses,
  title,
  description,
  subtitle,
  subsubtitle,
  isBorder,
  ...props
}) => {
  return (
    <div className={`${!isBorder ? 'pb-0' : 'p-4 shadow-sm bg-white border rounded-lg'} ${marginClasses}`}>
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between items-center'>
          <div className={`${!isBorder ? 'mb-0' : 'mb-2'} text-base font-semibold`}>{title}</div>
          <span
            className='inline-block mx-2 cursor-pointer'
            onClick={() => setIsOpen(!expanded)}


          >
            {
              !expanded ? <Image image={IconExpand} /> : <Image image={IconCollapse} />
            }
          </span>
        </div>
        <div
          className={`text-sm text-purple-700 ${subtitle ? 'mb-2' : ''}`}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <div
          className='text-sm'
          dangerouslySetInnerHTML={{ __html: subsubtitle }}
        />
        {expanded && (
          <div
            className='text-sm'
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </div>
  )
}

Expandable.propTypes = {
  /**
   * Is the control expand or collapse
   */
  isBorder: PropTypes.bool,
  expanded: PropTypes.bool,
  setIsOpen: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  subtitle: PropTypes.string,
  subsubtitle: PropTypes.string
}

Expandable.defaultProps = {
  isBorder: true,
  expanded: false,
  setIsOpen: undefined,
  title: 'Tiltle',
  description: 'description',
  subtitle: '',
  subsubtitle: ''
}
