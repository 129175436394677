import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

function IMEIMismatchContactUs() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['ImeiMismatchContactUs']
   
    return <Stack orientation='vertical' metadata={metadata} />
}

export default IMEIMismatchContactUs;