import { useContext, useEffect, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { createKeyValuePair, createModelKeyValuePair, toPascalCase, find, findById, uniqFilter, getPrice, filterDeviceSpecs } from '../enrollment-workflow/helper'
import API from '../../services'
import ActionTypes from '../../ActionTypes'

function ManualDetection() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )


  const metadata =  workflowState['config']?.metadata['ManualDetection']

  const [selectedDeviceDetails, setSelectedDeviceDetails] = useState({
    selectedMake: undefined,
    selectedModel: undefined,
    selectedDeviceColor: undefined,
    selectedDeviceCapacity: undefined,
    paymentMethod: 'Annual',
    isNewPhone: true,
    discountApplied: ['DIGITAL PROMOTIONAL DISCOUNT'],
    modelOptions: [],
    capacityOptions: [],
    colourOptions: []
  })
  let setSelectedDevice = (device) => {
    setSelectedDeviceDetails({
      ...selectedDeviceDetails,
      ...device
    })
  }
  const switchBtn = findById(metadata, 'switchBtn')
  const btnNext = findById(metadata, 'btnNext')
  const selectMake = findById(metadata, 'selectMake')
  const selectModel = findById(metadata, 'selectModel')
  const phnColor = findById(metadata, 'phnColor')
  const phnSize = findById(metadata, 'phnSize')
  const radioBtn = findById(metadata, 'radioBtn')
  const priceContent = findById(metadata, 'priceContent')
  const capacityTooltip = findById(metadata, 'capacityTooltip')

  let monthlyFee, yearlyFee, desimalNumber, formattedPrice;

  let deviceData = workflowState['config']?.DeviceDetails
  let makeOptions = workflowState['config']?.makeOptions;
  let _makeOptions = makeOptions?.map((ele) => {
    return {
      label: ele.label?.toPascalCase(),
      value: ele.value,
    }
  })
  let _modelOptions = selectedDeviceDetails?.modelOptions?.map((ele) => {
    return {
      label: ele.label.toPascalCase().replace('Iphone', 'iPhone'),
      value: ele.value,
    }
  })
  let _capacityOptions = selectedDeviceDetails?.capacityOptions?.map((ele) => {
    return {
      label: `${ele.label}GB`,
      value: ele.value,
    }
  })
  selectedDeviceDetails?.modelOptions?.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
  selectedDeviceDetails?.capacityOptions?.sort((a, b) => (+a.label > +b.label ? 1 : -1))

  let createAgreement = async () => {
    const CreateAgreementRequest = {
      CreateAgreementRequest: {
        FirstName: '',
        Flow: 'COOLADE',
        InteractionLineId: workflowState['config']?.InteractionLineId,
        ClientId: workflowState['config']?.ClientId,
        ClientChannelId: workflowState['config']?.ClientChannelId,
        PromotionId: '',
        GAClientId: '',
        UTMId: '',
        AgreementRequestStatus: 'INSURANCE_DUTY',
      },
    }
    API[ActionTypes.CREATE_AGREEMENT_REQUEST](CreateAgreementRequest).then(data => {
      updateState({ ...workflowState, CreateAgreementResponse: data.data.CreateAgreementResponse });
    })
  }
  // let getDeviceSizeColor = (selectedModel) => {
  //   let { deviceSizes, deviceColors } = filterDeviceSpecs(detectedModels, selectedModel, '', 'coolade')
  //   deviceSizes?.sort((a, b) => (+a > +b ? 1 : -1))
  //   return { deviceSizes, deviceColors }
  // }
  let getAllQuotes = (selectedDevice) => {
    /* get all quotes request */
    let filterAssets = []
    if (selectedDevice?.selectedModel) {
      // let { deviceSizes } = getDeviceSizeColor(selectedDevice?.selectedModel)
      filterAssets = deviceData?.filter(
        ({ AssetMakeName, Family, Capacity }) => AssetMakeName?.toUpperCase() === selectedDevice?.selectedMake?.toUpperCase() &&
          Family === selectedDevice?.selectedModel && Capacity === selectedDevice?.lowestCapacity
      )
    }
    const GetAllQuotesRequest = {
      GetAllQuotesRequest: {
        ClientId: workflowState['config']?.ClientId,
        AssetCatlogIds: filterAssets?.map(v => v.AssetCatlogId),
        ClientOfferName: "LITE"
      }
    }
    API[ActionTypes.GET_ALL_QUOTE_REQUEST](GetAllQuotesRequest).then(data => {
      updateState({ ...workflowState, GetAllQuotesResponse: data.data });
    })
    /* get all quotes request */
  }
  useEffect(() => {
    createAgreement()
    getAllQuotes({
      //selectedMake: detectedBrand,
      //selectedModel: _options[0].label,
    })
  }, [])
  let clientFeatures = workflowState['config']?.ClientFeatures
  let monthLowLitePrice = clientFeatures.filter(c => c.AttributeName == 'LOWESTENRFEEMONTHLY' && c.ClientOfferName == 'LITE').map(ele => ele.AttributeValue)
  let annualPrice = clientFeatures.filter(c => c.AttributeName == 'LOWESTENRFEEANNUALLY' && c.ClientOfferName == 'LITE').map(ele => ele.AttributeValue);


  if (workflowState['GetAllQuotesResponse']) {
    let lowLitePrice = workflowState['GetAllQuotesResponse']?.quoteData?.sort((a, b) => (+a.YearlyEnrollmentFee > +b.YearlyEnrollmentFee ? 1 : -1))
    let discount = selectedDeviceDetails?.discountApplied
    let otherDiscount = discount?.filter((v) => v !== 'DIGITAL PROMOTIONAL DISCOUNT').filter((v) => v !== 'NEWPH_DISC')
    let pricing = selectedDeviceDetails?.selectedModel ? lowLitePrice?.[0] : { EnrollmentFee: monthLowLitePrice[0], YearlyEnrollmentFee: annualPrice[0] }
    monthlyFee = getPrice('MONTHLY', pricing, selectedDeviceDetails?.isNewPhone, otherDiscount[0], workflowState['GetAllQuotesResponse']?.PromotionalOffer)
    yearlyFee = getPrice('YEARLY', pricing, selectedDeviceDetails?.isNewPhone, otherDiscount[0], workflowState['GetAllQuotesResponse']?.PromotionalOffer)
    const price = selectedDeviceDetails?.paymentMethod?.toUpperCase() === 'MONTHLY' ? monthlyFee : yearlyFee
    formattedPrice = price?.indexOf('.') > -1 ? price?.split('.') : [price, '00']
    desimalNumber = formattedPrice[1] && formattedPrice[1]
    desimalNumber = desimalNumber ? (desimalNumber.length === 1 ? desimalNumber + '0' : desimalNumber) : '00'
  }

  const confirmDeviceDetails = () => {
    let confirmedDeviceDetails = deviceData?.filter(
      ({ AssetMakeName, Family, Capacity, ClientColor }) => AssetMakeName?.toUpperCase() === selectedDeviceDetails?.selectedMake?.toUpperCase() &&
        Family === selectedDeviceDetails?.selectedModel && Capacity === selectedDeviceDetails?.selectedDeviceCapacity?.replace('GB', '') &&
        ClientColor === selectedDeviceDetails?.selectedDeviceColor
    )[0]
    let agreementDetails = workflowState['CreateAgreementResponse'];
    const GetQuoteRequest = {
      GetQuoteRequest: {
        BaseItemId: confirmedDeviceDetails?.ItemId,
        ClientId: workflowState['config']?.ClientId,
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AssetCatlogId: confirmedDeviceDetails?.AssetCatlogId,
      },
    }
    const UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AssetCatalogId: confirmedDeviceDetails?.AssetCatlogId,
        ItemId: confirmedDeviceDetails?.ItemId,
        IsNewPhone: selectedDeviceDetails?.isNewPhone,
        AgreementRequestStatus: 'DEVICE_SELECTION',
      },
    }
    API[ActionTypes.GET_QUOTE_REQUEST](GetQuoteRequest).then(data => {
      updateState({ ...workflowState, GetQuoteResponse: data.data.plans });
      API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
        updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        return completeCurrentStep(currentStep, {
          Make: selectedDeviceDetails?.selectedMake,
          Model: selectedDeviceDetails?.selectedModel,
          Color: selectedDeviceDetails?.selectedDeviceColor,
          Size: selectedDeviceDetails?.selectedDeviceCapacity,
          AutoDetected: false,
          DeviceSelected: true,
          isNewPhone: selectedDeviceDetails?.isNewPhone,
          discountApplied: selectedDeviceDetails?.discountApplied,
          confirmedDeviceDetails: confirmedDeviceDetails
        })
      })
    })
  }
  const setSelectedModel = model => {
    let familyOptionsWhole = find(selectedDeviceDetails?.modelOptionsWhole, 'Family', model)
    let colourOptions = createKeyValuePair(uniqFilter(familyOptionsWhole, 'ClientColor'))
    let capacityOptions = createKeyValuePair(uniqFilter(familyOptionsWhole, 'Capacity'))

    setSelectedDevice({
      selectedModel: model,
      selectedDeviceColor: undefined,
      selectedDeviceCapacity: undefined,
      isNewPhone: undefined,
      familyOptionsWhole: familyOptionsWhole,
      colourOptions: colourOptions,
      capacityOptions: capacityOptions
    })
    getAllQuotes({
      selectedMake: selectedDeviceDetails?.selectedMake,
      selectedModel: model,
      lowestCapacity: capacityOptions[0].value
    })
  }
  switchBtn.args = {
    ...switchBtn.args,
    checked: selectedDeviceDetails?.paymentMethod == 'Monthly' ? true : false,
    onChange: s => setSelectedDevice({
      paymentMethod: s ? 'Monthly' : 'Annual',
      discountApplied: !s ? [...selectedDeviceDetails?.discountApplied.filter(v => v != 'DIGITAL PROMOTIONAL DISCOUNT'), 'DIGITAL PROMOTIONAL DISCOUNT'] : [...selectedDeviceDetails?.discountApplied.filter(v => v != 'DIGITAL PROMOTIONAL DISCOUNT')]
    })
  }
  phnColor.args = {
    ...phnColor.args,
    options: selectedDeviceDetails?.colourOptions,
    value: selectedDeviceDetails?.selectedDeviceColor,
    disabled: !selectedDeviceDetails?.selectedMake || !selectedDeviceDetails?.selectedModel || !selectedDeviceDetails?.selectedDeviceCapacity,
    onSelect: s => setSelectedDevice({
      selectedDeviceColor: s,
      isNewPhone: undefined,
    })
  }
  phnSize.args = {
    ...phnSize.args,
    options: _capacityOptions,
    value: selectedDeviceDetails?.selectedDeviceCapacity,
    disabled: !selectedDeviceDetails?.selectedMake || !selectedDeviceDetails?.selectedModel,
    onSelect: s => setSelectedDevice({
      selectedDeviceCapacity: s,
      selectedDeviceColor: undefined,
      isNewPhone: undefined,
    })
  }
  radioBtn.args = {
    ...radioBtn.args,
    value: selectedDeviceDetails?.isNewPhone,
    onSelect: s => setSelectedDevice({
      isNewPhone: s == "true" ? true : false,
      discountApplied: s == "true" ? [...selectedDeviceDetails?.discountApplied.filter(v => v != 'NEWPH_DISC'), 'NEWPH_DISC'] : [...selectedDeviceDetails?.discountApplied.filter(v => v != 'NEWPH_DISC')]
    })
  }
  selectMake.args = {
    ...selectMake.args,
    value: selectedDeviceDetails?.selectedMake,
    options: _makeOptions,
    onSelect: s => setSelectedDevice({
      selectedMake: s,
      modelOptionsWhole: find(deviceData, 'AssetMakeName', s),
      modelOptions: createModelKeyValuePair(uniqFilter(find(deviceData, 'AssetMakeName', s), 'Family'))
    })
  }
  selectModel.args = {
    ...selectModel.args,
    value: selectedDeviceDetails?.selectedModel,
    options: _modelOptions,
    disabled: !selectedDeviceDetails?.selectedMake,
    onSelect: s => setSelectedModel(s)
  }
  if (formattedPrice) {
    priceContent.args = {
      ...priceContent.args,
      content: '${value}{decimal}'.replace('{value}', formattedPrice?.[0]).replace('{decimal}', desimalNumber && `.${desimalNumber}${selectedDeviceDetails?.isNewPhone ? '*' : ''}`)
    }
  }
  // let capacityTooltipText = `<div>
  //                     <p className="font-bold  mb-2">
  //                       ${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' ? "iPhone's Capacity" : selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? "Samsung's Capacity" : "Oppo's Capacity"}
  //                     </p>
  //                     <p className="mb-0">
  //                       <span className="font-bold">1.</span>${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' || selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? "Select" : "Go to"} <span className="font-bold">'Settings'</span>
  //                     </p>
  //                     <p className="mb-0">
  //                       <span className="font-bold">2.</span> Select 
  //                       <span className="font-bold">${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' ? "'About'" : selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? "'Device Care'" : "'Storage'"}</span>
  //                     </p>
  //                     <p className="mb-0">
  //                       <span className="font-bold">3.</span> See 
  //                       <span className="font-bold">${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' || selectedDeviceDetails?.selectedMake?.toUpperCase() === 'SAMSUNG' ? '' : 'Phone'} 
  //                       ${selectedDeviceDetails?.selectedMake?.toUpperCase() === 'APPLE' ? 'Capacity' : 'Storage'}
  //                       </span> - e.g. 64GB
  //                     </p>
  //                     <p className="font-bold mt-27 mb-m5">Why is this important?</p>
  //                     <p className="">
  //                       If your phone needs replacing due to damage or lost / theft, Asurion needs to know the full
  //                       specifications so we can provide a "like for like" replacement.
  //                     </p></div>`
  // capacityTooltip.args = {
  //   ...capacityTooltip.args,
  //   tooltipText: capacityTooltipText
  // }
  btnNext.args = {
    ...btnNext.args,
    enabled:
      selectedDeviceDetails?.selectedMake &&
        selectedDeviceDetails?.selectedModel &&
        selectedDeviceDetails?.selectedDeviceColor &&
        selectedDeviceDetails?.selectedDeviceCapacity &&
        selectedDeviceDetails?.isNewPhone != undefined
        ? true
        : false,
    onClick: e => confirmDeviceDetails()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default ManualDetection
