
/* eslint-disable max-len */
import React from 'react'

const icon = ({ onClick, isSelected, className, fill }) => (
  <svg onClick={onClick} className={className} width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13 18.0003C13 18.5533 13.447 19.0002 14 19.0002H18C18.553 19.0002 19 18.5533 19 18.0003V12.0003H21C21.404 12.0003 21.77 11.7562 21.924 11.3832C22.078 11.0082 21.993 10.5782 21.707 10.2933L16.707 5.29325C16.316 4.90225 15.684 4.90225 15.293 5.29325L10.293 10.2933C10.007 10.5782 9.92199 11.0082 10.076 11.3832C10.23 11.7562 10.596 12.0003 11 12.0003H13V18.0003ZM17 11.0003V17.0003H15V11.0003C15 10.4473 14.553 10.0002 14 10.0002H13.414L16 7.41425L18.586 10.0002H18C17.447 10.0002 17 10.4473 17 11.0003Z" fill="#8223D2" />
    <path d="M10 25H22C23.104 25 24 24.102 24 23V21H22V23H10V21H8V23C8 24.102 8.896 25 10 25Z" fill="#8223D2" />
  </svg>

)

export default icon
