import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

function VideoNotSure() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['VideoNotSure']
    const notSureVideoRadio = findById(metadata, 'notSureVideoRadio')
    const btnNext = findById(metadata, 'btnNext')
    const returnVideoCapture = findById(metadata, 'return-video-capture')


    const [notSureToVideoOption, setNotSureToVideoOption] = useState()
    console.log('notSureToVideoOption', notSureToVideoOption)
    notSureVideoRadio.args = {
        ...notSureVideoRadio.args,
        value: notSureToVideoOption,
        onSelect: setNotSureToVideoOption
    }
    returnVideoCapture.args = {
        ...returnVideoCapture.args,
        onClick: s => {
            return completeCurrentStep(currentStep, {
                returnVideoCapture: true
            })
        }
    }
    btnNext.args = {
        ...btnNext.args,
        enabled: notSureToVideoOption !== undefined ? true : false,
        onClick: e => {
            return completeCurrentStep(currentStep, {
                notSureToVideoOptionSelected: true,
                notSureToVideoOption: notSureToVideoOption
            })
        }
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default VideoNotSure;