import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";

function LetsGetPrepared2() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
      )
    const metadata = workflowState['config']?.metadata['LetsGetPreparedV2']
    const btnNext = findById(metadata, 'btnNext')
    let agreementDetails = workflowState['CreateAgreementResponse'];

    const updateAgreement = () => {
        const UpdateAgreementRequest = {
            CreateAgreementActivityRequest: {
                AgreementRequestId: agreementDetails?.AgreementRequestId,
                AgreementRequestStatus: 'VHC_2PHONE_INSTR2',
            },
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
            updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
            return completeCurrentStep(currentStep, {
                LetsGetPreparedV3: true
            })
        })
    }

    btnNext.args = {
        ...btnNext.args,
        onClick: s => updateAgreement()
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default LetsGetPrepared2;