import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MetadataContext } from "../metadata-context";

const orientationDirectionMap = {
  horizontal: {
    normal: "flex-row",
    reverse: "flex-row-reverse",
  },
  vertical: {
    normal: "flex-col",
    reverse: "flex-col-reverse",
  },
};

export const Stack = ({
  orientation,
  direction,
  metadata,
  other,
  border,
  backgroundColor,
  margin,
  padding,
  children,
  ...props
}) => {
  const { renderItem } = useContext(MetadataContext);

  let flexClass =
    orientationDirectionMap[orientation || "horizontal"][direction || "normal"];
  if (metadata) {
    return (
      <div className={`flex ${flexClass} ${other} ${margin} ${padding} ${border} ${backgroundColor}`}>
        {metadata.map((item) => renderItem(item))}
      </div>
    );
  }
  return <div className={`flex ${flexClass}`}>{children}</div>;
};

Stack.propTypes = {
  /**
   * Will child controls stacked horizontally or vertically
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),

  /**
   * Will controls flow in normal direction i.e. left to right and top to bottom or in reverse direction
   */
  direction: PropTypes.oneOf(["normal", "reverse"]),

  /**
   * Metadata provides JSON to render child controls. Children property would be ignored if metadata is provided
   */
  metadata: PropTypes.arrayOf(PropTypes.object),
};

Stack.defaultProps = {
  orientation: "horizontal",
  direction: "normal",
};
