import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../button/button'

export const PhoneSizes = ({ deviceSizes, onClick, phoneSize, ...props }) => {
  return (
    <div className='flex flex-wrap mb-5'>
      {deviceSizes.map(s => {
        return (
          <div key={s.size} className='px-2 py-1 flex flex-col items-center'>
            <Button
              label={s.size}
              borderClasses='rounded-full'
              paddingClasses='p-1 px-3'
              colorClasses={
                s.size === phoneSize
                  ? 'bg-purple-700 text-white'
                  : 'bg-gray-100 text-black'
              }
              onClick={() => onClick(s)}
            />
          </div>
        )
      })}
    </div>
  )
}

PhoneSizes.propTypes = {
  onClick: PropTypes.func,
  deviceSizes: PropTypes.arrayOf(PropTypes.object)
}

PhoneSizes.defaultProps = {
  onClick: undefined,
  deviceSizes: [{ size: '64GB' }, { size: '128GB' }]
}
