import { Text } from "./text/text"
import { Button, GoogleButton, FacebookButton } from "./button/button"
import { Input } from "./input/input"
import { Stack } from "./stack/stack"
import { Select } from "./select/select"
import { List } from "./list/list"
import { PhoneSizes } from "./phone-sizes/phone-sizes"
import { PhoneColors } from "./phone-colors/phone-colors"
import { Modal } from "./modal/modal"
import { ToggleSwitch } from "./toggle-switch/toggle-switch"
import { Link } from "./link/link"
import { Radio } from "./radio/radio"
import { Tooltip } from "./tooltip/tooltip"
import { Otp } from "./otp/otp"
import { Expandable } from "./expandable/expandable"

import { FileUploader } from "./file-uploader/file-uploader"
import { Icon } from './icon/icon'
import { Image } from './image/image'
import { AsyncSelect } from './async-select/async-select'
import { TextArea } from './textarea/textarea'

import { Carousel } from './carousel/carousel'
import { Checkbox } from './checkbox/checkbox'
import { Header } from './header/header'
import { Tabs } from './tabs/tabs'
import { PriceSection } from "./enrollment-workflow/quote-offer/price-section"
import { Carts } from "./carts/carts"
import FAQs from "./enrollment-workflow/quote-offer/faq"
import { QRField } from "./qr-code/qr-code"

export const ControlLocator = {
    Text: (args) => <Text {...args} />,
    Button: (args) => <Button {...args} />,
    Input: (args) => <Input {...args} />,
    Panel: (args) => <Stack {...args} />,
    Stack: (args) => <Stack {...args} />,
    Select: (args) => <Select {...args} />,
    List: (args) => <List {...args} />,
    GoogleButton: (args) => <GoogleButton {...args} />,
    FacebookButton: (args) => <FacebookButton {...args} />,
    Modal: (args) => <Modal {...args} />,
    PhoneColors: (args) => <PhoneColors {...args} />,
    PhoneSizes: (args) => <PhoneSizes {...args} />,
    ToggleSwitch: (args) => <ToggleSwitch {...args} />,
    Link: (args) => <Link {...args} />,
    Radio: (args) => <Radio {...args} />,
    Tooltip: (args) => <Tooltip {...args} />,
    Otp: (args) => <Otp {...args} />,
    FileUploader: (args) => <FileUploader {...args} />,
    Icon: (args) => <Icon {...args} />,
    Image: (args) => <Image {...args} />,
    AsyncSelect: (args) => <AsyncSelect {...args} />,
    TextArea: (args) => <TextArea {...args} />,
    Carousel: (args) => <Carousel {...args} />,
    Checkbox: (args) => <Checkbox {...args} />,
    Expandable: (args) => <Expandable {...args} />,
    Header: (args) => <Header {...args} />,
    Tabs: (args) => <Tabs {...args} />,
    PriceSection: (args) => <PriceSection {...args} />,
    Carts: (args) => <Carts {...args} />,
    FAQs: (args) => <FAQs {...args} />,
    QRField: (args) => <QRField {...args} />
}