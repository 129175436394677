import axios from 'axios'
import types from './ActionTypes'
import { Auth } from 'aws-amplify'
import { AuthService } from './auth.service'
const auth = new AuthService()

const chatbotApiUrl = process.env.REACT_APP_CHATBOT_API_URL

const http = axios.create({
  baseURL: '/',
  responseType: 'json',
})

http.interceptors.request.use(
  async (config) => {
    let user
    try {
      user = await Auth.currentAuthenticatedUser()
    } catch (error) {
      console.log('Auth Error : ', error)
    }

    console.log(process.env);
    
    let headers = {
      Accept: 'application/json',
      'Asurion-channel': 'online',
      'Asurion-enduser': 'DigitalUser',
      'Asurion-lineofbusiness': 'MOBILITY',
      'Asurion-region': 'APAC-SEA',
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_API_KEY,
      'Asurion-client': 'Australia_Digital',
      'Accept-Language': 'en-US',
      Authorization: user?.signInUserSession?.idToken?.jwtToken,
    }
    config.headers = { ...config.headers, ...headers }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const API = {}

async function signIn(username, password) {
  return await Auth.signIn(username, password)
}

async function signOut() {
  return await Auth.signOut()
}

export async function updateUserAttribute(data) {
  return http.post('/login/api/preauth', data)
}

API[types.INTIALIZE_API_REQUEST] = (data) => http.get(`registration/api/initialize/v2`)
API[types.FIND_CITY_STATE_REQUEST] = (data) => http.get(`registration/api/masterdata/getcities`)
API[types.GET_DEVICE_BY_IMEI_REQUEST] = (data) => http.get(`registration/api/quote/get-device/${data.imei}`)
API[types.UPDATE_DEVICE_INQUIRY_REQUEST] = (data) => http.post(`/claim/api/update-imei-inquiry`, data)
API[types.GET_QUOTE_REQUEST] = (data) => http.post(`registration/api/quote/get-quote/v2`, data)
API[types.GET_ALL_QUOTE_REQUEST] = (data) => http.post(`registration/api/quote/get-all-quotes`, data)
API[types.CREATE_AGREEMENT_REQUEST] = (data) => http.post(`/registration/api/request/v2 `, data)
API[types.UPDATE_AGREEMENT_REQUEST] = (data) =>
  http.post(`/registration/api/request/v2/${data.CreateAgreementActivityRequest.AgreementRequestId}`, data)
API[types.GET_SECURITY_TOKEN_REQUEST] = (data) => http.post(`/registration/api/payment/token/v2`, data)
API[types.AUTH_CARD_REQUEST] = (data) => http.post(`/registration/api/payment/authcard`, data)
API[types.COLLECT_PAYMENT_REQUEST] = (data) => http.post(`/registration/api/payment/collect`, data)
API[types.ENROLLMENT_REQUEST] = (data) => http.post(`/registration/api/enrollment/standalone`, data)
API[types.PROCESS_PAYMENT_REQUEST] = (data) => http.post(`/registration/api/payment/process`, data)
API[types.CHECK_ENROLLMENT_ELIGILIBITY_REQUEST] = (data) =>
  http.post(`/registration/api/rule/enrollmenteligibility`, data)
API[types.GET_AGREEMENT_DETAILS_REQUEST] = (data) =>
  http.get(`/registration/api/getdetails/${data.GetAgreementDetailsRequest.AgreementRequestId}?submit=Y`, data)
/*Login */
API[types.GET_AGREEMENT_REQUEST] = (data) => http.post(`registration/api/getagreement`, data)
API[types.GET_AGREEMENT_STATUS_REQUEST] = (data) => http.post(`registration/api/getagreementstatus`, data)
API[types.GET_REENGAGE_DATA_REQUEST] = (data) =>
  http.get(`registration/api/getreengagedata/${data.GetReengageDataRequest.AgreementRequestId}`, data)
API[types.LOGIN_REQUEST] = (data) => http.get(`registration/api/getagreement/${data.loginId}`)
API[types.LOGIN_REQUEST] = (data) => {
  return signIn(data.username, data.password)
}
API[types.OTP_REQUEST] = (email) => {
  return auth.signIn(email)
}
API[types.SIGNOUT_REQUEST] = () => {
  return auth.signOut()
}
API[types.BUY_NOW_SIGNUP_OTP_REQUEST] = (data) => {
  return auth.signUp(data.loginEmail, data.firstName)
}
API[types.VERIFY_LOGIN_OTP] = (otp) => {
  return auth.answerCustomChallenge(otp)
}
/*Login */

/**Claim */
API[types.FIND_AGREEMENT_REQUEST] = (data) => http.post(`claim/api/findagreement`, data)
API[types.GET_CUSTOMER_CASE_GRAPH_REQUEST] = (data) => http.get(`claim/api/details/${data.ClientAccountId}`)
API[types.START_SERVICE_REQUEST] = (data) => http.post(`claim/api/startservicerequest`, data)
API[types.PROCESS_INCIENT_REQUEST] = (data) => http.post(`claim/api/processincident`, data)
API[types.GET_FULFILLMENT_OPTION_REQUEST] = (data) => http.get(`claim/api/fulfillmentoptions?servicerequestid=${data}`)
API[types.SET_FULFILLMENT_OPTION_REQUEST] = (data) =>
  http.post(
    `claim/api/fulfillmentoptions?servicerequestid=${data.SetFulfillmentOptionParameters.ServiceRequestId}`,
    data
  )
API[types.GET_REPLACEMENT_MATRIX_REQUEST] = (data) =>
  http.get(
    `claim/api/replacement?servicerequestid=${data.servicerequestid}&maxnlflreplacements=${data.maxnlflreplacements}`
  )
API[types.GET_SHIPPING_ORDER_REQUEST] = (data) =>
  http.get(`claim/api/shippingorder/${data.ServiceOrderId}?servicerequestid=${data.ServiceRequestId}`)
API[types.GET_SHIPPING_METHODS_REQUEST] = (data) =>
  http.post(`claim/api/shippingmethods?serviceorderid=${data.ServiceOrderId}`, data)
API[types.STANDARDIZE_ADDRESS_REQUEST] = (data) => http.post(`claim/api/standardizeaddress`, data)
API[types.GET_DO_GET_ADDRESS] = (data) => http.post(`claim/api/dogetaddressqas`, data)
API[types.UPDATE_SHIPPING_ORDER_REQUEST] = (data) =>
  http.put(`claim/api/shippingorder/${data.UpdateShippingOrderParameters.ShippingOrder.ServiceOrderId}`, data)
API[types.CREATE_SERVICE_ORDER_REQUEST] = (data) => http.post(`claim/api/serviceorder`, data)
API[types.UPDATE_SERVICE_ORDER_REQUEST] = (data) =>
  http.post(`claim/api/updateserviceorder/${data.ReplaceServiceOrderParameter.ServiceOrderId}`, data)
API[types.GET_PCITOKEN_REQUEST] = (data) => http.post(`/payment/api/token`, data)
API[types.CREATE_CHARGE_ORDER_REQUEST] = (data) => http.post(`claim/api/chargeorder/${data.ServiceOrderId}`, data)
API[types.PROCESS_CLAIM_PAYMENT_REQUEST] = (data) =>
  http.post(
    `claim/api/processpayment/${data.ProcessPaymentParameters.ServiceOrderId}/${data.ProcessPaymentParameters.ChargeOrderId}`,
    data
  )
API[types.SUBMIT_ORDER_REQUEST] = (data) =>
  http.post(`claim/api/submitorder/${data.SubmitServiceOrderParameters.ServiceOrderId}`, data)
API[types.CREATE_CONTACT_POINT_REQUEST] = (data) => http.post(`claim/api/contactpoints`, data)
API[types.GET_CONTACT_POINT_REQUEST] = (data) =>
  http.get(`claim/api/contactpoints?clientaccountid=${data.clientaccountid}`)
API[types.CANCEL_SERVICE_REQUEST_REQUEST] = (data) =>
  http.post(`claim/api/cancelservicerequest/${data.CancelServiceRequestParams.ServiceRequestId}`, data)
API[types.GET_CITY_STATE_BY_ZIPCODE_REQUEST] = (data) => http.get(`registration/api/citystate/${data.zipcode}`)
API[types.GET_FMIP_STATUS_REQUEST] = (data) => http.post(`/claim/api/fmipstatus`, data)
API[types.GENERATE_PARCEL_REQUEST] = (data) => http.post(`claim/api/generateparcellabel`, data)
API[types.GET_SERVICE_FEE_REQUEST] = (data) => http.get(`/claim/api/servicefees?servicerequestid=${data}`)
API[types.GET_PROFILE_DETAILS_REQUEST] = (data) => http.get(`/registration/api/profiledetails/${data}`)
API[types.SAVE_PROFILE_DETAILS_REQUEST] = (data) => http.post(`/registration/api/profiledetails`, data)
API[types.CREATE_REPAIR_REQ_REQUEST] = (data) => http.post(`claim/api/repairrequest/create`, data)
API[types.GET_DO_GET_PROFILE_ADDRESS] = (data) => http.post(`claim/api/dogetaddressqas`, data)
API[types.GET_SURVEY_QUESTIONS_REQUEST] = (data) => http.post(`/claim/api/survey`, data)
API[types.SUBMIT_SURVEY_QUESTIONS_REQUEST] = (data) => http.post(`/claim/api/survey/submit`, data)
API[types.GET_PHOTO_ID_DETAILS_REQUEST] = (data) => http.post(`/claim/api/photoiddetails`, data)
API[types.REPAIR_TO_REFRESH_REQUEST] = (data) => http.post(`/claim/api/repairrefresh`, data)
API[types.DETERMINE_INCIDENT_PATH_REQUEST] = (data) => http.post(`/claim/api/determineincidentpath`, data)
API[types.RESUME_SERVICE_REQUEST] = (data) =>
  http.post(`/claim/api/resumeservicerequest/${data.ServiceRequestId}`, data.ResumeServiceRequestParameters)
API[types.GET_BATTERY_WARRANTY_REQUEST] = (data) => http.get(`/claim/api/battery/eligibility?imei=${data.IMEI}`)
// API[types.GET_PROMO_OFFERS_REQUEST] = (data) => http.get(`registration/api/getreferaldetails/${data.PromoCode}/${data.planName}`)
API[types.GET_PROMO_OFFERS_REQUEST] = (data) =>
  http.get(
    `registration/api/getreferaldetails/${data.PromoCode}/${data.planName}/${data.assetCatalog}/${data.PaymentTanure}`
  )
API[types.SEND_REFERAL_EMAIL_REQUEST] = (data) => http.post(`/claim/api/sendemail`, data)
API[types.CREATE_INTERACTION_REQUEST] = (data) => http.post(`/claim/api/interaction`, data)
/**Claim */

/** SignUp */
API[types.VERIFY_SIGNUP_OTP_REQUEST] = (data) => http.post(`registration/api/otp/verify/v2`, data)
API[types.SEND_SIGNUP_EMAIL_OTP_REQUEST] = (data) => http.post('login/api/otp/sendemailotp', data)
API[types.SEND_SIGNUP_OTP_REQUEST] = (data) => http.post(`registration/api/otp/send/v2`, data)
API[types.CHECK_IF_MDN_ALREADY_ENROLLED_REQUEST] = (data) => http.post(`registration/api/mdn/isenrolled`, data)
API[types.SIGNUP_CREATE_ACCOUNT_REQUEST] = (data) => http.post(`login/api/createaccount`, data)
API[types.GET_AUSPOST_LIST_REQUEST] = (data) =>
  http.get(`/auspost/api/location/${data.postcode}?lat=${data.lat}&long=${data.lng}`)
API[types.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST] = (data) => http.post(`/claim/api/filesystem/upload`, data)
API[types.GET_ASP_STORE_LIST_REQUEST] = (data) => http.get(`/claim/api/getasplist/${data.lat}/${data.lng}/${data.Make}`)
API[types.GET_ASSET_DETAILS_FROM_IMEI_SCREENSHOT_REQUEST] = (data) => http.post(`/claim/api/assetdetails`, data)
API[types.CREATE_DOCUMENT_TASK_REQUEST] = (data) => http.post(`/claim/api/filesystem/createtask`, data)
API[types.GET_DOCUMENT_TASK_DETAILS_REQUEST] = (data) =>
  http.get(`/claim/api/filesystem/getdocumenttaskdetails/${data.policyno}`)
API[types.GET_REQUIRED_DOCUMENTS_LIST_REQUEST] = (data) => {
  if (data.policyNumber && !data.caseNumber) {
    return http.get(`/claim/api/document/getrequireddocuments?&policynumber=${data.policyNumber || ''}`)
  } else {
    return http.get(
      `/claim/api/document/getrequireddocuments?casenumber=${data.caseNumber || ''}&policynumber=${
        data.policyNumber || ''
      }`
    )
  }
}

/**MyPayments */
API[types.ADD_PAYMENT_METHOD_REQUEST] = (data) => http.post(`/payment/api/paymentmethod`, data)
API[types.GET_PAYMENT_DETAILS_REQUEST] = (data) =>
  http.get(`/payment/api/details/${data}?timestamp=${new Date().getTime()}`)
API[types.GET_PCISECURITYTOKEN_REQUEST] = (data) => http.post(`/payment/api/token`, data)
API[types.DELETE_PAYMENTMETHOD_REQUEST] = (data) => http.delete(`/payment/api/paymentmethod`, { data })
API[types.SAVE_PAYMENTPREFERENCE_REQUEST] = (data) => http.post(`/payment/api/preference`, data)
API[types.PAY_ENR_FEE_REQUEST] = (data) => http.post(`/payment/api/payenrfee`, data)
/**MyPayments End */

API[types.SAVE_PAYOUT_DETAILS_REQUEST] = (data) => http.post(`/claim/api/creattransfermethod`, data)
API[types.SUBMIT_PAYOUT_REQUEST] = (data) => http.post(`/claim/api/payreimbursement`, data)
API[types.GET_NOTIFICATION_REQUEST] = (data) => http.post(`/claim/api/getnotifications`, data)
API[types.CREATE_HYPERWALLET_USER_REQUEST] = (data) => http.post(`claim/api/createuser`, data)

API[types.CREATE_NOTIFICATION_REQUEST] = (data) => http.post(`claim/api/createnotification`, data)
API[types.RESOLVE_NOTIFICATION_REQUEST] = (data) => http.post(`claim/api/resolvenotification`, data)

//*MyClaims */
API[types.CREATE_INQUIRY_REQUEST] = (data) => http.post(`/claim/api/inquiry`, data)
API[types.GET_SECURITY_ADVISER_TOKEN_REQUEST] = (data) => http.post(`/claim/api/securityadvisertoken`, data)

API[types.SAVE_VIDEO_REASON_REQUEST] = (data) => http.post(`/claim/api/saveattributes`, data)

// Logger asyn API
API[types.POST_LOGGER] = (data) => {
  return http.post(`/api/v1/logger`, { LoggerRequest: data })
}
API[types.CANCEL_POLICY_DEATILS] = (data) => http.get(`registration/api/agreement/cancel/details/${data.agreementId}`)
API[types.CANCEL_POLICY] = (data) =>
  http.post(`registration/api/agreement/cancel/${data.CancelPremiumBillingAgreementRequest.agreementId}`, data)

//Contact Us
API[types.CREATE_CONTACTUS_INQUIRY_REQUEST] = (data) => http.post(`/claim/api/inquiry`, data)
API[types.CREATE_CONTACTUS_COMPLAINT_REQUEST] = (data) => http.post(`/claim/api/complaints`, data)

API[types.CREATE_CHATBOT_MESSAGE_REQUEST] = (data) => {
  return http.post(chatbotApiUrl, data)
}

export default API
