import { useContext, useEffect } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";
import Step1Image from '../../assets/2 phone step 1.png'
import Step2Image from '../../assets/2 phone step 2.png'
import Step3Image from '../../assets/2 phone step 3.png'
import Step4Image from '../../assets/2 phone step 4.png'
import Step5Image from '../../assets/2 phone step 5.png'
import Step6Image from '../../assets/2 phone step 6.png'
import API from "../../services";
import ActionTypes from "../../ActionTypes";

function LetsGetPrepared3() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['LetsGetPreparedV3']
    const letsgetPreparedV3Img = findById(metadata, 'letsgetPreparedV3Img')
    const btnNext = findById(metadata, 'btnNext')

    let agreementDetails = workflowState['CreateAgreementResponse'];

    useEffect(() => {
        const UpdateAgreementRequest = {
            CreateAgreementActivityRequest: {
                AgreementRequestId: agreementDetails?.AgreementRequestId,
                AgreementRequestStatus: 'VHC_2PHONE_INSTR3',
            },
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
            updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        })
    }, [])

    letsgetPreparedV3Img.args = {
        ...letsgetPreparedV3Img.args,
        images: [
            { src: Step1Image, alt: 'slide-1' },
            { src: Step2Image, alt: 'slide-2' },
            { src: Step3Image, alt: 'slide-3' },
            { src: Step4Image, alt: 'slide-4' },
            { src: Step5Image, alt: 'slide-5' },
            { src: Step6Image, alt: 'slide-6' }
        ]
    }

    btnNext.args = {
        ...btnNext.args,
        enabled: true,
        onClick: s => {
            return completeCurrentStep(currentStep, {
                navigateToQRCode: true
            })
        }
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default LetsGetPrepared3;