import { useContext, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { findById } from '../enrollment-workflow/helper'
import API from '../../services'
import ActionTypes from '../../ActionTypes'

function GetQuote() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )

  const metadata = workflowState['config']?.metadata['GetQuote']
  let agreementDetails = workflowState['CreateAgreementResponse'];

  const btnNext = findById(metadata, 'btnNext')
  const updateAgreement = () => {
    const UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AgreementRequestStatus: 'GET_YOUR_QUOTE',
      },
    }
    API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
      updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
      return completeCurrentStep(currentStep, {
        QuoteSelected: true
      })
    })
  }
  btnNext.args = {
    ...btnNext.args,
    onClick: e => updateAgreement()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default GetQuote
