/* eslint-disable max-len */
import React from 'react'

const deleteIcon = ({ onClick, isSelected }) => (
  <svg onClick={onClick} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7856 2.5V4.16667H19.2618V5.83333H2.83325V4.16667H8.30944V2.5H13.7856Z" fill="#8223D2"/>
    <path d="M10.1348 10H8.30944V15H10.1348V10Z" fill="#8223D2"/>
    <path d="M11.9602 10H13.7856V15H11.9602V10Z" fill="#8223D2"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.48405 18.3333C5.47734 18.3333 4.65865 17.5858 4.65865 16.6667V6.66667H17.4364V16.6667C17.4364 17.5858 16.6177 18.3333 15.611 18.3333H6.48405ZM15.611 8.33333H6.48405V16.6667H15.6119L15.611 8.33333Z" fill="#8223D2"/>
  </svg>
)

export default deleteIcon
