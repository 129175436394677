import { useContext, useEffect, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { findById } from '../enrollment-workflow/helper'
import API from '../../services'
import ActionTypes from '../../ActionTypes'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'

function Login() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const params = new URLSearchParams(global.window.location.search)
  let requestId = decodeURIComponent(params.get('id') || '')
  const navigate = useNavigate();

  const [selectedDetails, setSelectedDetails] = useState({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    userNameType: undefined,
    loginType: undefined,
    socialUser: undefined,
    buyNowReengage: undefined
  })
  let metadata = workflowState['config']?.metadata['Login']
  let txtFirstName = findById(metadata, 'txtFirstName')
  let txtLastName = findById(metadata, 'txtLastName')
  let txtEmail = findById(metadata, 'txtEmail')
  let btnGoogle = findById(metadata, 'btnGoogle')
  let btnFacebook = findById(metadata, 'btnFacebook')
  let btnNext = findById(metadata, 'btnNext')
  let DynamoDBData;
  let agreementDetails = workflowState['CreateAgreementResponse'];
  let loginDetails = workflowState['login'];
  const agreementStatus = loginDetails?.agreementStatus
  const agreementError = loginDetails?.agreementError
  const OTPError = loginDetails?.OTPError
  const OTPSent = loginDetails?.OTPSent

  const updateAgreement = () => {
    const UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AgreementRequestStatus: 'EMAIL_SIGN_IN',
      },
    }
    API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
      updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
    })
  }

  const onEmailSubmit = () => {
    let loggedInEmail = selectedDetails?.email
    if (DynamoDBData && loggedInEmail.toLowerCase() !== DynamoDBData?.EmailAddress?.toLowerCase()) {
      //actions.setFlowRestartState()
      //history.push(``)
      //return
    }
    if (!DynamoDBData) {
      updateAgreement()
    }
    const userNameType = 'EMAIL'
    const getAgreementRequest = {
      LoginEmailId: selectedDetails?.email,
      ContactType: userNameType,
      ClientName: workflowState['config']?.ClientName,
      ClientId: workflowState['config']?.ClientId,
      flowName: 'COOLADE',
    }
    setSelectedDetails({
      ...selectedDetails,
      ...{
        userNameType: userNameType,
        loginType: 'normal'
      }
    })
    API[ActionTypes.GET_AGREEMENT_STATUS_REQUEST](getAgreementRequest).then(data => {
      updateState({
        ...workflowState, getAgreementResponse: data.data, [currentStep]: {
          ...workflowState[currentStep],
          firstName: selectedDetails?.firstName,
          lastName: selectedDetails?.lastName,
          email: selectedDetails?.email,
          agreements: undefined,
          agreementData: undefined,
          agreementFound: true,
          agreementStatus: 'FOUND',
        }
      });

    }).catch(e => {
      updateState({
        ...workflowState, getAgreementError: e.response.data, [currentStep]: {
          ...workflowState[currentStep],
          firstName: selectedDetails?.firstName,
          lastName: selectedDetails?.lastName,
          email: selectedDetails?.email,
          agreementError: e.response.data,
          agreementFound: false,
          agreementStatus: 'NOT_FOUND',

        }
      });
    })
  }
  let buyNowSignIn = () => {
    API[ActionTypes.OTP_REQUEST](selectedDetails?.email).then(data => {
      updateState({
        ...workflowState, OtpResponse: data
      })
      //navigate("/email-otp")
      return completeCurrentStep(currentStep, {
        loggedIn: true,
        OTPError: false,
        firstName: selectedDetails?.firstName,
        lastName: selectedDetails?.lastName,
        email: selectedDetails?.email,
        agreements: undefined,
        agreementData: undefined,
        agreementFound: true,
        agreementStatus: 'FOUND',
        userNameType: selectedDetails?.userNameType,
        loginType: selectedDetails?.loginType,
        socialUser: selectedDetails?.socialUser,
        buyNowReengage: selectedDetails?.buyNowReengage
      })
    })
      .catch(error => {
        updateState({
          ...workflowState, OTPError: error, [currentStep]: {
            ...workflowState[currentStep],
            OTPError: true,
            loggedIn: false
          }
        })
      })
    //  navigate("/email-otp")
  }
  let buyNowSocialSignIn = () => {
    navigate("/registeration")
  }

  let buyNowReengage = () => {
    setSelectedDetails({
      ...selectedDetails,
      ...{
        buyNowReengage: true,
      }
    })
    navigate("/reengage")
  }
  useEffect(() => {
    //  if (!navigateNext || agreementStatus === 'INIT') return false

    let isReengageFlow = Boolean(agreementError?.DynamoDBdata)
    let isAgreementNotFound = agreementStatus === 'NOT_FOUND' || agreementStatus === 'INIT'
    let isCoolade = agreementError?.DynamoDBdata?.Flow === 'COOLADE'
    let errorMsg = agreementError?.data?.message
    let userExists = OTPError?.code === 'UsernameExistsException'

    if (selectedDetails?.loginType === 'social') {
      if (isAgreementNotFound && errorMsg && isReengageFlow && isCoolade) {
        buyNowReengage()
      } else {
        buyNowSocialSignIn() // social login
      }
    } else if (selectedDetails?.loginType === 'normal') {
      if (userExists || OTPSent) {
        buyNowSignIn() // existing user login
      } else {
        API[ActionTypes.BUY_NOW_SIGNUP_OTP_REQUEST]({ loginEmail: selectedDetails?.email, firstName: selectedDetails?.firstName }).then(data => {
          updateState({
            ...workflowState, signupOtpResponse: data, [currentStep]: {
              ...workflowState[currentStep],
              OTPSent: true
            }
          })
        })
          .catch(error => {
            updateState({
              ...workflowState, signupOtpError: error, [currentStep]: {
                ...workflowState[currentStep],
                OTPSent: false,
                OTPError: error
              }
            })
          });// normal signup -> signs in via OTPsent case
      }
    }
  }, [agreementStatus, agreementError, OTPError, OTPSent])

  // after login via social, this function checks if user session exists, and then calls agreement API
  useEffect(async () => {
    const user = await Auth.currentAuthenticatedUser()
    setSelectedDetails({
      ...selectedDetails,
      ...{
        socialUser: user,
        loginType: 'social'
      }
    })

    if (user?.attributes?.identities) {
      setUserInfo(user)
    }
  }, [])

  let setUserInfo = (user) => {
    let {
      attributes: { email },
    } = user

    if (!requestId) {
      setSelectedDetails({
        ...selectedDetails,
        ...{
          email: email,
        }
      })
      onEmailSubmit(email)
    }
  }
  let persistSocialLoginState = () => {
    localStorage.setItem('loginType', 'social')
    localStorage.setItem('prevState', JSON.stringify(workflowState))
  }

  let handleSocialLogin = (opt) => {
    let _firstName = selectedDetails?.firstName?.trim()
    let _lastName = selectedDetails?.lastName?.trim()
    // if (!_firstName || !_lastName) {
    //   if (!_firstName && !errors?.firstName) {
    //     setFirstNameError(true)
    //   }
    //   if (!_lastName && !errors?.lastName) {
    //     setLastNameError(true)
    //   }
    // } else {
    persistSocialLoginState()
    Auth.federatedSignIn({ provider: opt })
    //}
  }
  txtFirstName.args = {
    ...txtFirstName.args,
    value: selectedDetails?.firstName,
    onChange: (name) => setSelectedDetails({
      ...selectedDetails,
      ...{
        firstName: name
      }
    })
  }

  txtLastName.args = {
    ...txtLastName.args,
    value: selectedDetails?.lastName,
    onChange: (name) => setSelectedDetails({
      ...selectedDetails,
      ...{
        lastName: name
      }
    })
  }

  txtEmail.args = {
    ...txtEmail.args,
    value: selectedDetails?.email,
    onChange: (email) => setSelectedDetails({
      ...selectedDetails,
      ...{
        email: email
      }
    })
  }

  btnGoogle.args = {
    ...btnGoogle.args,
    onClick: e => handleSocialLogin('Google')
  }

  btnFacebook.args = {
    ...btnFacebook.args,
    onClick: e => handleSocialLogin('Facebook')
  }

  btnNext.args = {
    ...btnNext.args,
    enabled: selectedDetails?.firstName && selectedDetails?.lastName && selectedDetails?.email ? true : false,
    onClick: e => onEmailSubmit()
  }

  return <Stack orientation='vertical' metadata={metadata} />
}

export default Login
