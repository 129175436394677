import React from 'react'
import PropTypes from 'prop-types'

const sizemap = {
  xs: 'xs',
  sm: 'sm',
  md: 'base',
  lg: 'lg',
  xl: 'xl',
  '3xl': '3xl',
  '2xl': '2xl',
  '5xl': '5xl'
}

const stylemap = {
  highlighted: 'font-bold text-purple-700',
  supressed: 'text-gray-400',
  normal: 'text-black',
  bold: 'font-bold',
  error: 'text-xs text-red-600'
}

export const Text = ({
  style,
  size,
  wrap,
  content,
  colorClasses,
  justification,
  marginClasses,
  paddingClasses,
  ...props
}) => {
  let fontSize = `text-${sizemap[size]}`
  return (
    <span
      className={`${fontSize} ${stylemap[style] ||
        ''} ${colorClasses} ${justification} ${marginClasses} ${paddingClasses}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}

Text.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  style: PropTypes.oneOf(['highlighted', 'supressed', 'normal', 'bold', 'error']),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '5xl']),

  /**
   * Text contents
   */
  content: PropTypes.string.isRequired
}

Text.defaultProps = {
  style: 'normal',
  size: 'md',
  content: 'This is text content'
}
