import { useContext, useEffect, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { checkCardType, findById } from '../enrollment-workflow/helper'
import API from '../../services'
import ActionTypes from '../../ActionTypes'
import { useNavigate } from 'react-router-dom'
import { PaymentClient, Env } from '@backoffice/fast-payments-client-js-sdk'

function Registeration() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const navigate = useNavigate();
  let metadata = workflowState['config']?.metadata['Registeration']
  const headerText = findById(metadata, 'headerText')
  const promocode = findById(metadata, 'promocode')
  const applyPromo = findById(metadata, 'applyPromo')
  const promosection = findById(metadata, 'promosection')
  const paymentheader = findById(metadata, 'payment-header')
  const cardNumber = findById(metadata, 'card-number')
  const nameOnCard = findById(metadata, 'name-on-card')
  const expiry = findById(metadata, 'expiry')
  const cvv = findById(metadata, 'cvv')
  const mdn = findById(metadata, 'mobile-number')
  const addressSelect = findById(metadata, 'address-select')
  const confirmPayment = findById(metadata, 'confirm-payment')
  const promoSuccess = findById(metadata, 'promo-success')
  const referalText = findById(metadata, 'referal-text')
  const promoError = findById(metadata, 'promo-error')

  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({
    promoCode: undefined,
    creditCardNumber: undefined,
    creditCardName: undefined,
    expiryDateonCard: undefined,
    cvvOnCard: undefined,
    mobileNumber: undefined
  })
  const deviceDetection = workflowState['device-detection']
  const autoDetection = workflowState['auto-detection']
  const ManualSelection = workflowState['manual-detection']
  const quoteOffer = workflowState['quote-offer']
  let agreementDetails = workflowState['CreateAgreementResponse'];
  let loginDetails = workflowState['login'];
  let registerationDetails = workflowState['registeration'];
  const [selectedValue, setSelectedValue] = useState()

  const [SearchAddressLine, setSearchAddressLine] = useState('')
  const [PredectiveAddress, setPredectiveAddress] = useState([])
  //const address = registerationDetails && registerationDetails.address
  const clearAddress = registerationDetails?.clearAddress
  let addr = SearchAddressLine
  const fullAddress = registerationDetails ? `${registerationDetails.StreetNo ? `${registerationDetails.StreetNo},` : ''}${registerationDetails.StreetName ? ` ${registerationDetails.StreetName},` : ''
    }${registerationDetails.Suburb ? ` ${registerationDetails.Suburb},` : ''}${registerationDetails.PostCode ? ` ${registerationDetails.PostCode},` : ''}${registerationDetails.State ? ` ${registerationDetails.State}` : ''
    }`.trim() : ''
  const [inputValue, setInputValue] = useState(fullAddress)
  let [isMdnError, setIsMdnError] = useState(false)
  const [POBoxAddress, setPOBoxAddress] = useState(false)

  const detectionData = deviceDetection?.auto ? autoDetection : ManualSelection

  const selectedPlanName = quoteOffer?.selectedPlanName
  const deviceName = detectionData?.AutoDetected ? `${detectionData.Model}, ${detectionData.Size}` :
    `${detectionData.Make} ${detectionData.Model}, ${detectionData.Size}`

  const billingContact = {
    name: {
      first: loginDetails?.firstName,
      last: loginDetails?.lastName,
    },
    address: {
      address1: undefined,
      address2: undefined,
      city: undefined,
      state: undefined,
      country: 'AU',
      zip: 3000,
    },
    locale: {
      countryCodeISO3166: 'AU',
      languageCodeISO639: 'en',
    },
    contactInfo: {
      phone: selectedPaymentDetails?.mobileNumber,
      email: loginDetails?.email,
    },
  }
  const month = selectedPaymentDetails?.expiryDateonCard?.split('/')?.[0]
  const year = selectedPaymentDetails?.expiryDateonCard?.split('/')?.[1]
  const creditCardInfo = {
    number: selectedPaymentDetails?.creditCardNumber,
    securityCode: selectedPaymentDetails?.cvvOnCard,
    expiration: { month: +month, year: +`20${year}` },
  }
  let SecurityToken = workflowState['GetSecurityTokenResponse']?.token
  let EncryptionKey = workflowState['GetSecurityTokenResponse']?.EncryptionKey
  const isFormValid =
    selectedPaymentDetails?.creditCardNumber &&
      selectedPaymentDetails?.creditCardName &&
      selectedPaymentDetails?.expiryDateonCard &&
      selectedPaymentDetails?.cvvOnCard &&
      quoteOffer?.selectedPaymentMethod &&
      selectedPaymentDetails?.mobileNumber ? true : false
  //validExpiryDate &&

  useEffect(() => {
    API[ActionTypes.GET_SECURITY_TOKEN_REQUEST]({
      GetSecurityTokenRequest: {
        AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
        FirstName: loginDetails?.firstName,
        LastName: loginDetails?.lastName,
        EmailAddress: loginDetails?.email,
        InteractionLineId: agreementDetails && agreementDetails.InteractionLineId,
      },
    }).then(data => {
      updateState({ ...workflowState, GetSecurityTokenResponse: data.data.GetSecurityTokenResponse });
    })
  }, [])

  let addressStandardizationRequest = {
    QASParams: {
      ClientId: workflowState['config']?.ClientId,
      IsIntuitiveAddress: true,
      Address: {
        Address1: addr.replace('undefined', ''),
        Address2: '',
        Address3: '',
      },
    },
    InteractionLineId: workflowState['config']?.InteractionLineId,
  }
  const availableAddress = (inputValue) => {
    if (inputValue.length >= 3) {
      return API[ActionTypes.STANDARDIZE_ADDRESS_REQUEST](addressStandardizationRequest)
        .then((resp) => {
          console.log("resp", resp.data.StandardizeAddressResponse)
          let response = resp.data.StandardizeAddressResponse;
          let PredectiveAddress = !response?.PickListAddress?.Moniker
            ? response?.PickListAddress
            : [response?.PickListAddress]
          if (!resp) Promise.resolve({ options: [] })
          if (response?.PickListAddress?.Picklist == 'No matches') {
            setPredectiveAddress(PredectiveAddress)
            return Promise.resolve({ options: PredectiveAddress || [] })
          }
          setPredectiveAddress(PredectiveAddress)
          return Promise.resolve({
            options: PredectiveAddress || [],
          })
        })
        .catch((_) => {
          //FAILURE ACTION
          //handleAddressPredictionFailed()
        })
    } else {
      return Promise.resolve(registerationDetails?.PredectiveAddress || [])
    }
  }
  const promiseOptions = () => {
    return availableAddress(inputValue).then((res) => {
      return res
    })
  }
  const handleInputChange = (value) => {
    setSearchAddressLine(value)
    setInputValue(value)
  }

  const handleChange = (value) => {
    // actions.clearAddress(false);
    const doGetAddressRequest = {
      DoGetAddressQASParams: {
        attributes: {
          FullAddress: value?.attributes?.FullAddress,
        },
        Moniker: value?.Moniker,
        Score: value?.Score,
      },
      InteractionLineId: workflowState['config']?.InteractionLineId,
      idBuyFlow: true,
    }
    API[ActionTypes.GET_DO_GET_ADDRESS](doGetAddressRequest)
      .then((resp) => {
        let CorrectedAddress = resp.data?.AddressResult?.CorrectedAddress
        updateState({
          ...workflowState,
          doGetAddressResponse: resp.data,
          [currentStep]: {
            ...workflowState[currentStep],
            StandardizedAddress: resp.data,
            StreetNo: CorrectedAddress?.AddressLine1,
            StreetName: CorrectedAddress?.AddressLine2,
            PostCode: CorrectedAddress?.PostalCode,
            Suburb: CorrectedAddress?.CityName?.toPascalCase(),
            State: CorrectedAddress?.StateProvinceCode,
            showRightAddressPopup: true,
            CountryCode: CorrectedAddress?.CountryCode,
            isAddressNotStandardized: false,
            doGetSuccess: true,
            clearAddress: false,
            PredectiveAddress: PredectiveAddress
          }
        })
      })
    setSelectedValue(value)
    //  setValue('fullAddress', value);
    //actions.clearAddress(false);
  }
  let POAddress
  const ValidateAddress = () => {
    let keywords =
      'p.o.|po|post|post office|pobox|diplomaticpo|postal|fleetpo|fleet po|post.off.|postoff|postoffice|postofficebox|correos|oficina|apo|fpo|dpo|po|pob|a.p.o.|f.p.o.|f.p.o|d.p.o.|apartado|aptdo|buzon|callbox|caller|gpobox|poboxs-1190|correos|caja postal|casilla postal|p.o.box|fleetpostoffice|fleetp.o.|diplomaticpostoffice|diplomatic.p.o.|Police Box'
    keywords = keywords.split('|')
    let mapp = 'po'
    let addressArray1 = registerationDetails?.StreetNo?.split(' ')
    let addressArray2 = registerationDetails?.StreetName?.split(' ')
    addressArray1 &&
      addressArray1.map((addresss) => {
        if (addresss.toLowerCase() == mapp.toLowerCase()) {
          POAddress = true
        }
      })
    addressArray2 &&
      addressArray2.map((addresss) => {
        if (addresss && addresss.toLowerCase() == mapp.toLowerCase()) {
          POAddress = true
        }
      })
    keywords.map((key) => {
      key = key.toLowerCase()
      if (registerationDetails?.StreetNo?.toLowerCase()?.includes(key)) {
        POAddress = true
      }
      if (registerationDetails?.StreetName?.toLowerCase()?.includes(key)) {
        POAddress = true
      }
    })
    return POAddress
  }
  const performBgLibraryOperartions = () => {
    if (workflowState['authCardResponse']) {
      return completeCurrentStep(currentStep, {
        ...workflowState[currentStep],
        navigateTo: 'nationalid-upload',
        promoCode: selectedPaymentDetails?.promoCode,
        creditCardNumber: selectedPaymentDetails?.creditCardNumber,
        creditCardName: selectedPaymentDetails?.creditCardName,
        expiryDateonCard: selectedPaymentDetails?.expiryDateonCard,
        cvvOnCard: selectedPaymentDetails?.cvvOnCard,
        mobileNumber: selectedPaymentDetails?.mobileNumber
      })
    }
    if (process.env.REACT_APP_BG_TRANSACTION_ENV === 'DEV') {
      return completeCurrentStep(currentStep, {
        ...workflowState[currentStep],
        navigateTo: 'nationalid-upload',
        promoCode: selectedPaymentDetails?.promoCode,
        creditCardNumber: selectedPaymentDetails?.creditCardNumber,
        creditCardName: selectedPaymentDetails?.creditCardName,
        expiryDateonCard: selectedPaymentDetails?.expiryDateonCard,
        cvvOnCard: selectedPaymentDetails?.cvvOnCard,
        mobileNumber: selectedPaymentDetails?.mobileNumber
      })
    }
    let isPoAddress = ValidateAddress()
    setPOBoxAddress(isPoAddress)
    if (!isPoAddress) {
      const paymentClient = new PaymentClient({ env: process.env.REACT_APP_BG_TRANSACTION_ENV === 'PROD' ? Env.prod : Env.qa })
      return Promise.all([
        paymentClient.addSession(SecurityToken, {
          encryptionKey: EncryptionKey,
          appName: 'ENRPORTAL',
          currency: 'AUD',
        }),
        paymentClient.addBillingContactInfo(billingContact),
        paymentClient.addCreditCardInfo(creditCardInfo),
      ])
        .then(() => {
          return paymentClient.processPayment()
        })
        .then((processPaymentResponse) => {
          //if (agreementStatus && agreementStatus === 'INIT') actions.setAgreementStatus('FOUND')
          API[ActionTypes.AUTH_CARD_REQUEST]({
            AuthCardRequest: {
              AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
            },
          }).then(resp => {
            updateState({
              ...workflowState, authCardResponse: resp.data.AuthCardResponse
            })
          })
            .catch(error => {
              updateState({
                ...workflowState, authCardPaymentError: error.response.data
              })
            })
        })
        .catch((error) => {
          updateState({
            ...workflowState, [currentStep]: {
              ...workflowState[currentStep],
              paymentProcessError: error.message,
              // promoCode: selectedPaymentDetails?.promoCode,
              // creditCardNumber: selectedPaymentDetails?.creditCardNumber,
              // creditCardName: selectedPaymentDetails?.creditCardName,
              // expiryDateonCard: selectedPaymentDetails?.expiryDateonCard,
              // cvvOnCard: selectedPaymentDetails?.cvvOnCard,
              // mobileNumber: selectedPaymentDetails?.mobileNumber
            }
          })
        })
    }
  }

  useEffect(() => {
    if (workflowState['IsMDNEnrolledError']?.data?.message == 'ALREADY-ENROLLED') {
      setIsMdnError(true)
    } else if (workflowState['IsMDNEnrolledResponse']) {
      performBgLibraryOperartions()
    }
  }, [workflowState['IsMDNEnrolledResponse'], workflowState['IsMDNEnrolledError']])

  useEffect(() => {
    if (selectedPaymentDetails?.mobileNumber === '') {
      setIsMdnError(false)
    }
  }, [selectedPaymentDetails?.mobileNumber])

  useEffect(() => {
    if (workflowState['authCardResponse'] && workflowState['authCardResponse']?.BillingOrderId) {
      let number = selectedPaymentDetails?.mobileNumber.startsWith('+') ? selectedPaymentDetails?.mobileNumber.split('-') : ['+61', selectedPaymentDetails?.mobileNumber];
      let CountryCallingCode = number[0]
      let PhoneNumber = number[1]

      let UpdateAgreementRequest = {
        CreateAgreementActivityRequest: {
          AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
          AgreementRequestStatus: 'PAYMENT_CONFIRMATION',
          CardType: checkCardType(selectedPaymentDetails?.creditCardNumber).CardType,
          PaymentTanure: quoteOffer?.selectedPaymentMethod,
          PromoCodeEntered: selectedPaymentDetails?.promoData && selectedPaymentDetails.promoData?.isReferal ? selectedPaymentDetails?.promoCode : '',
          DiscountsApplied: detectionData?.discountsApplied,
          PhoneNumber: selectedPaymentDetails?.mobileNumber.startsWith('0') ? selectedPaymentDetails?.mobileNumber : selectedPaymentDetails?.mobileNumber?.split('-')?.join('-0')?.split('-')[1],
          CountryCallingCode: CountryCallingCode,
          AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
          InteractionLineId: agreementDetails && agreementDetails.InteractionLineId,
          Address1: registerationDetails?.StreetNo,
          Address2: registerationDetails?.StreetName,
          City: registerationDetails?.Suburb,
          Suburb: registerationDetails?.Suburb,
          State: registerationDetails?.State,
          Postal: registerationDetails?.PostCode,
          Country: 'AUS',
          Model: detectionData?.Model,
          Make: detectionData?.Make,
          SelectedPlan: selectedPlanName,
        }
      }
      API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
        updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        return completeCurrentStep(currentStep, {
          ...workflowState[currentStep],
          navigateTo: 'nationalid-upload',
          promoCode: selectedPaymentDetails?.promoCode,
          creditCardNumber: selectedPaymentDetails?.creditCardNumber,
          creditCardName: selectedPaymentDetails?.creditCardName,
          expiryDateonCard: selectedPaymentDetails?.expiryDateonCard,
          cvvOnCard: selectedPaymentDetails?.cvvOnCard,
          mobileNumber: selectedPaymentDetails?.mobileNumber
        })
      })
      //history.push(`/nationalIdUpload`)
    }
  }, [workflowState['authCardResponse']])

  const validateMDN = () => {
    API[ActionTypes.CHECK_IF_MDN_ALREADY_ENROLLED_REQUEST]({
      IsAlreadyEnrolledRequest: {
        MDN: selectedPaymentDetails?.mobileNumber?.startsWith('0') ? selectedPaymentDetails?.mobileNumber : selectedPaymentDetails?.mobileNumber?.split('-').join('-0'),
      }
    }).then(resp => {
      updateState({
        ...workflowState, IsMDNEnrolledResponse: resp.data
      })
    })
      .catch(error => {
        updateState({
          ...workflowState, IsMDNEnrolledError: error.response.data
        })
      })
  }

  const applyPromoCode = () => {
    //promosection.args.metadata.pop()
    API[ActionTypes.GET_PROMO_OFFERS_REQUEST]({
      PromoCode: selectedPaymentDetails?.promoCode,
      planName: selectedPlanName?.toUpperCase(),
      assetCatalog: detectionData?.confirmedDeviceDetails?.AssetCatlogId,
      PaymentTanure: quoteOffer?.selectedPaymentMethod?.toUpperCase()
    }).then(data => {
      const promoResponse = data.data.ReferalDetailsResponse;
      updateState({ ...workflowState, promoResponse: promoResponse });
      // const successPrmomo = {
      //   "type": "Stack",
      //   "args": {
      //     "backgroundColor": "bg-blue-50",
      //     "other": "flex-flex-1",
      //     "margin": "mt-4",
      //     "padding": "p-3",
      //     "orientation": "vertical",
      //     "metadata": [
      //       {
      //         "type": "Text",
      //         "args": {
      //           "style": "highlighted",
      //           "size": "sm",
      //           "content": `Referral: ${promoResponse?.FirstName} ${promoResponse?.LastName}`,
      //           "marginClasses": "mb-1"
      //         }
      //       },
      //       {
      //         "type": "Text",
      //         "args": {
      //           "style": "normal",
      //           "size": "sm",
      //           "content": 'You and your friend will be eligible to receive an 8.33% (1 month) discount off the premium at your next renewal. You must retain your policy for a minimum period of 6 months.',
      //         }
      //       }
      //     ]
      //   }
      // }
      // promosection.args.metadata.push(successPrmomo)

      promoSuccess.args = {
        ...promoSuccess.args,
        other: 'flex-flex-1'
      }
      referalText.args = {
        ...referalText.args,
        content: `Referral: ${promoResponse?.FirstName} ${promoResponse?.LastName}`,
      }
    }).catch(error => {
      updateState({ ...workflowState, promoError: error });
      promoError.args = {
        ...promoError.args,
        other: 'flex-flex-1'
      }
      // promosection.args.metadata.push(
      //   {
      //     "type": "Stack",
      //     "args": {
      //       "margin": "mt-2",
      //       "other":"hidden",
      //       "orientation": "vertical",
      //       "metadata": [
      //         {
      //           "type": "Text",
      //           "args": {
      //             "style": "error",
      //             "size": "sm",
      //             "content": 'Sorry, the referral/promo code has expired or invalid',
      //             "marginClasses": "mb-1"
      //           }
      //         }
      //       ]
      //     }
      //   }
      // )
    })
  }
  headerText.args = {
    ...headerText.args,
    content: `${selectedPlanName} coverage is a great choice for ${deviceName}`
  }
  paymentheader.args = {
    ...paymentheader.args,
    content: `Your <span class="font-bold">${quoteOffer?.selectedPaymentMethod} payment</span> of <span class="font-bold">$65.71</span> will not be processed until your policy is approved`
  }
  addressSelect.args = {
    ...addressSelect.args,
    value: selectedValue,
    defaultInputValue: fullAddress,
    isSearchable: true,
    getOptionLabel: (e) => e?.PartialAddress,
    getOptionValue: (e) => e?.PartialAddress,
    loadOptions: () => promiseOptions(),
    isSearchable: true,
    placeholder: "Please enter your home address", //Postcode or Suburb or Town
    placeholdercolor: "#6E767D",
    name: "fullAddress",
    fieldName: "fullAddress",
    debounceTimeout: 500,
    showIndicator: false,
    onInputChange: handleInputChange,
    onChange: handleChange
  }
  promocode.args = {
    ...promocode.args,
    value: selectedPaymentDetails?.promoCode,
    onChange: (v) => setSelectedPaymentDetails({
      ...selectedPaymentDetails,
      ...{
        promoCode: v
      }
    })
  }
  cardNumber.args = {
    ...cardNumber.args,
    value: selectedPaymentDetails?.creditCardNumber,
    onChange: (v) => setSelectedPaymentDetails({
      ...selectedPaymentDetails,
      ...{
        creditCardNumber: v
      }
    })
  }
  nameOnCard.args = {
    ...nameOnCard.args,
    value: selectedPaymentDetails?.creditCardName,
    onChange: (v) => setSelectedPaymentDetails({
      ...selectedPaymentDetails,
      ...{
        creditCardName: v
      }
    })
  }
  expiry.args = {
    ...expiry.args,
    value: selectedPaymentDetails?.expiryDateonCard,
    onChange: (v) => setSelectedPaymentDetails({
      ...selectedPaymentDetails,
      ...{
        expiryDateonCard: v
      }
    })
  }
  cvv.args = {
    ...cvv.args,
    value: selectedPaymentDetails?.cvvOnCard,
    onChange: (v) => setSelectedPaymentDetails({
      ...selectedPaymentDetails,
      ...{
        cvvOnCard: v
      }
    })
  }
  mdn.args = {
    ...mdn.args,
    value: selectedPaymentDetails?.mobileNumber,
    onChange: (v) => setSelectedPaymentDetails({
      ...selectedPaymentDetails,
      ...{
        mobileNumber: v
      }
    })
  }

  applyPromo.args = {
    ...applyPromo.args,
    enabled:
      selectedPaymentDetails?.promoCode
        ? true
        : false,
    onClick: e => applyPromoCode()
  }
  confirmPayment.args = {
    ...confirmPayment.args,
    enabled: isFormValid && registerationDetails?.StandardizedAddress && !registerationDetails?.isAddressNotStandardized ? true : false,
    onClick: e => validateMDN()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default Registeration
