const findById = (metadata, id) => {
  let control = metadata.find(item => item.id === id)
  if (!control) {
    let stacks = metadata.filter(item => item.type === 'Stack')
    for (let stack of stacks) {
      if (stack?.args?.metadata) {
        let internalControl = findById(stack?.args?.metadata, id)
        if (internalControl) {
          return internalControl
        }
      }
    }
  }
  return control
}

function filterDeviceSpecs(deviceList, model, spec = null, flow = null) {
  let deviceModels = uniqFilter(deviceList, 'Family') // uniq filter deviceList by family
  let selectedModel = find(deviceList, 'Family', model) // find the selected model from deviceList

  let deviceColors, deviceSizes = []

  if (flow === 'coolade') {
    let color = spec //

    deviceColors = uniqFilter(selectedModel, 'ClientColor') // then use it to filter colors from size
    let selectedColor = find(selectedModel, 'ClientColor', deviceColors[0]) // and also use it to find selected size
    deviceSizes = uniqFilter(selectedColor, 'Capacity') // then use it to filter colors from size
  } else {
    let capacity = spec

    deviceSizes = uniqFilter(selectedModel, 'Capacity') // use selectedModel to find it's capacities
    let selectedSize = find(selectedModel, 'Capacity', capacity) // and also use it to find selected size

    deviceColors = uniqFilter(selectedSize, 'ClientColor') // then use it to filter colors from size
  }

  return {
    deviceModels,
    deviceSizes,
    deviceColors,
  }
}

let returnDAXSupportedDevices = (modelsByMake, devices) => {
  return modelsByMake.filter((model) => {
    return devices.some((device) => {
      return model.Family?.toUpperCase() === device.name.toUpperCase()
    })
  })
}

function toPascalCase() {
  return this.replace(/\w\S*/g, (t) => {
    return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
  })
}

let formatAppleModel = (model) => {
  let modelArr = model.split(' ')
  let iphone = modelArr[0]
  let name = model.split('IPHONE')
  iphone = iphone.charAt(0).toLowerCase() + iphone.charAt(1).toUpperCase() + iphone.substr(2).toLowerCase()
  return `${iphone} ${name.join(' ').toUpperCase()}`
}

let formatModelName = (make = '', model = '') => {
  let uppercaseMake = make.toUpperCase()
  let formattedModel = model.toUpperCase().replace(`${uppercaseMake} `, '')
  if (uppercaseMake === 'APPLE') formattedModel = formatAppleModel(formattedModel)
  else formattedModel = formattedModel.toPascalCase()
  return formattedModel
}

let formatCapacity = (capacity = '') => {
  return capacity?.toUpperCase().indexOf('GB') > -1 ? capacity : `${capacity}GB`
}
let createKeyValuePair = (targetArray) => {
  return targetArray.map((currentValue) => {
    return { label: currentValue, value: currentValue }
  })
}
let createModelKeyValuePair = (targetArray) => {
  return targetArray.map((currentValue) => {
    return { label: currentValue.replace(/samsung/gi, '').replace(/apple/gi, ''), value: currentValue }
  })
}
let uniqFilter = (targetArray, compareKey) => {
  let filterdData = targetArray.map((currentObj) => currentObj[compareKey])
  return uniq(filterdData)
}

let find = (targetArray = [], compareKey, compareValue) => {
  let filterdData = targetArray.filter((currentObj) => currentObj[compareKey] === compareValue)
  return uniq(filterdData)
}

let uniq = (targetArray = []) => {
  return [...new Set(targetArray)]
}

const getPrice = (PaymentTenure, planDetailsOfSelectedPlan = {}, isNewPhone, otherDiscount, getAllQuotePromotionOffers) => {
  let price =
    PaymentTenure == 'YEARLY' ? planDetailsOfSelectedPlan?.yearlyEnrollmentFee || planDetailsOfSelectedPlan?.YearlyEnrollmentFee : planDetailsOfSelectedPlan?.enrollmentFee || planDetailsOfSelectedPlan?.EnrollmentFee
  let offers = planDetailsOfSelectedPlan?.PromotionalOffer || getAllQuotePromotionOffers
  let AnnualDiscount = offers?.filter((v) => v.promotionalOfferCode === 'DIGITAL PROMOTIONAL DISCOUNT')[0]
  let NewPhnDiscount = offers?.filter((v) => v.promotionalOfferCode === 'NEWPH_DISC')[0]
  let OtherDiscount = offers?.filter((v) => v.promotionalOfferCode === otherDiscount)[0]
  // let OtherDiscount = offers?.filter((v) => v.promotionalOfferCode === 'dgpromo5')[0]
  let discountValue = 0
  if (PaymentTenure == 'YEARLY') {
    if (isNewPhone && NewPhnDiscount) {
      discountValue = discountValue + NewPhnDiscount?.discountValue
    }
    if (AnnualDiscount) {
      discountValue = discountValue + AnnualDiscount?.discountValue
    }
    if (OtherDiscount) {
      discountValue = discountValue + OtherDiscount?.discountValue
    }
  } else if (PaymentTenure == 'MONTHLY') {
    if (isNewPhone && NewPhnDiscount) {
      discountValue = discountValue + NewPhnDiscount?.discountValue
    }
    if (OtherDiscount) {
      discountValue = discountValue + OtherDiscount?.discountValue
    }
  }
  price = price - (price * discountValue) / 100
  return price?.toFixed(2)
}
const CARD_IMG = {
  VISA: require('../../assets/img-visa2.png'),
  MASTER: require('../../assets/img-master-2.png'),
  AMEX: require('../../assets/img-amex.png'),
}
const checkCardType = (cardNumber) => {
  const cleanCardNumber = cardNumber && cardNumber.split(' ').join('')
  const CARD_TYPES = {
    VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MASTER: /^5[1-5][0-9]{14}$/,
    AMEX: /^3[47][0-9]{13}$/,
    DISC: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  }
  let cardType = Object.keys(CARD_TYPES).find((cardType) => CARD_TYPES[cardType].test(cleanCardNumber))
  return {
    CardType: cardType,
    CardImg: CARD_IMG[cardType],
  }
}
const validateFileType = (file) => {
  let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i
  return allowedExtensions.exec(file)
}
const formatMobile = (phoneNo) => {
  if (!phoneNo) {
    return ''
  }
  switch (phoneNo.length) {
    case 10:
      return phoneNo.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
    case 11:
      return phoneNo.replace(/(\d{4})(\d{3})(\d{4})/, '$1 $2 $3')
    case 9:
      return phoneNo.replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3')
    case 8:
      return phoneNo.replace(/(\d{4})(\d{4})/, '$1 $2')
    default:
      return phoneNo
  }
}

export { find, findById, formatModelName, formatCapacity, getPrice, filterDeviceSpecs, returnDAXSupportedDevices, toPascalCase, createKeyValuePair, uniqFilter, createModelKeyValuePair, checkCardType, validateFileType, formatMobile }