import React from 'react'
import PropTypes from 'prop-types'

export const TextArea = ({
  id,
  name,
  rows,
  cols,
  onChange,
  placeholder,
  value,
  Mandatory,
  label,
  enabled,
  ...props
}) => {
  return (
    <div>
      <label htmlFor={name} className=''>
        {label}
        {Mandatory && <span className='text-red-600 ml-1'>*</span>}
      </label>
      <textarea
        className='border flex flex-col p-2 outline-none rounded w-full mt-1'
        id={id}
        value={value}
        name={name}
        rows={rows}
        cols={cols}
        disabled={!enabled}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      >
        {value}
      </textarea>
      <p className='text-sm text-gray-500 mb-5 float-right mt-1'>{`${500 - value.length}  characters`}</p>
    </div>
  )
}

TextArea.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  Mandatory: PropTypes.bool,
  label: PropTypes.string
}

TextArea.defaultProps = {
  enabled: true,
  placeholder: '',
  Value: '',
  Mandatory: false,
  onChange: undefined,
  label: 'Textarea'
}
