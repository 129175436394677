import { useContext } from "react";
import {WorkflowContext} from "../workflow/workflow-context"

function TwoPhone() {
    const [currentStep, completeCurrentStep] = useContext(WorkflowContext)

    return <div>
        <h1>TwoPhone</h1>
    </div>
}

export default TwoPhone;