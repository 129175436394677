import React, { useContext, useState } from 'react'
import { Stack } from '../../stack/stack'
import { findById } from '../helper'
import { WorkflowContext } from '../../workflow/workflow-context'
import { useEffect } from 'react'

function LitePlan({ selectedPlanFeatures, updateAgreement }) {
  
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['LitePlan']
  const btnSelect = findById(metadata, 'btnSelect')
  const liteCarts = findById(metadata, 'lite-carts-1')
  const liteCarts2 = findById(metadata, 'lite-carts-2')

  liteCarts.args = {
    ...liteCarts.args,
    data: selectedPlanFeatures?.cards
  }
  liteCarts2.args = {
    ...liteCarts2.args,
    data: selectedPlanFeatures?.keyPointsCards
  }
  btnSelect.args = {
    ...btnSelect.args,
    onClick: e => updateAgreement()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default LitePlan;