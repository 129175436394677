/* eslint-disable max-len */
import React from 'react'

const icon = ({ isSelected, className, onClick }) => (
  <svg className={className} onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M12 12C9.24249 12 7 9.75751 7 6.99998C7 4.24374 9.24249 2 12 2C14.7575 2 17 4.24374 17 6.99998C17 9.75751 14.7575 12 12 12ZM12 4.49999C10.6213 4.49999 9.50005 5.61998 9.50005 6.99998C9.50005 8.37872 10.6213 9.50001 12 9.50001C13.3788 9.50001 14.5 8.37872 14.5 6.99998C14.5 5.61998 13.3788 4.49999 12 4.49999ZM2 22V20.7143C2 16.1719 6.11249 13 12 13C17.8875 13 22 16.1719 22 20.7143V22H2ZM4.69874 19.4286H19.3025C18.555 17.104 15.7613 15.5714 12.0013 15.5714C8.24123 15.5714 5.44624 17.104 4.69874 19.4286Z" fill="black"/>
</svg>

)

export default icon
