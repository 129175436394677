import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

export const Otp = ({otpLength = 4, onChange = (e=>{}), enabled}) => {
  
  const [activeInput, setActiveInput] = useState(0)
  const [otpValues, setOTPValues] = useState(new Array(otpLength).fill(''))
  const inputsRef = useRef([...Array(4)].map(() => React.createRef()))
  
  const re = /^[0-9\b]+$/

  const setValue = (value, index) => {
    if (value === '' || re.test(value)) {
      const updatedOTPValues = [...otpValues]
      updatedOTPValues[index] = value || ''
      setOTPValues(updatedOTPValues)
      onChange(updatedOTPValues)
    }
  }

  const onFocus = e => {
    setActiveInput(e.target.tabIndex - 1)
    if (otpValues[e.target.tabIndex - 1]) {
      e.target.select()
    }
  }

  const onKeyUp = e => {
    if (
      e.key !== 'Tab' &&
      otpValues[activeInput] &&
      otpValues[activeInput] === e.target.value &&
      re.test(e.key)
    ) {
      inputsRef?.current[activeInput + 1]?.current?.focus()
    }
  }

  return (
    <div className='flex justify-center mb-4'>
      {otpValues.map((v, i) => {
        return (
          <div key={i}>
            <input type="text"
              ref={inputsRef.current[i]}
              className='w-10 h-10 border border-blue-300 mr-2 text-center rounded'
              value={v}
              readOnly={!enabled}
              maxLength='1'
              tabIndex={i + 1}
              onChange={e => setValue(e?.target?.value, i)}
              onFocus={onFocus}
              onKeyUp={onKeyUp}
            />
          </div>
        )
      })}
    </div>
  )
}

Otp.propTypes = {
  onChange: PropTypes.func,
  otpLength: PropTypes.number,
  enabled: PropTypes.bool
}

Otp.defaultProps = {
  onChange: () => {},
  otpLength: 4,
  enabled: true,
}
