/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg className={className} width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.01">
            <rect x="1" width="29.8614" height="19.75" fill="white" />
        </g>
        <rect x="11.9932" y="3.44141" width="7.87727" height="12.8659" fill="#FF5F00" />
        <path d="M12.8045 9.87417C12.8026 7.36394 13.9549 4.99221 15.9295 3.44235C12.575 0.805999 7.75762 1.19001 4.86304 4.3245C1.96847 7.459 1.96847 12.2916 4.86304 15.4261C7.75762 18.5606 12.575 18.9446 15.9295 16.3083C13.9543 14.7579 12.8019 12.3852 12.8045 9.87417Z" fill="#EB001B" />
        <path fillRule="evenodd" clipRule="evenodd" d="M28.3864 14.6815V14.9451H28.3364V14.6815H28.2295V14.627H28.5V14.6815H28.3864ZM28.9114 14.627V14.9451H28.8545V14.7042L28.7659 14.911H28.7045L28.6159 14.7042V14.9451H28.5568V14.627H28.6386L28.7341 14.8542L28.8295 14.627H28.9114Z" fill="#F79E1B" />
        <path d="M29.1682 9.87537C29.1681 13.008 27.3794 15.8655 24.5618 17.2344C21.7441 18.6034 18.3922 18.2433 15.9295 16.3072C17.9033 14.756 19.0557 12.3846 19.0557 9.87424C19.0557 7.36388 17.9033 4.99246 15.9295 3.44128C18.3922 1.50517 21.7441 1.14512 24.5618 2.51403C27.3794 3.88295 29.1681 6.74052 29.1682 9.8731V9.87537Z" fill="#F79E1B" />
    </svg>

)

export default icon
