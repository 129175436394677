import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById, formatModelName } from "./helper";
import IconLetsGetPrepared from '../../icons/icon-imgLetGetPrepared'
import API from "../../services";
import ActionTypes from "../../ActionTypes";

function IMEIUploadSuccess() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['ImeiUploadSuccess']
  const headerText = findById(metadata, 'headerText')
  const btnNext = findById(metadata, 'btnNext')


  let autoDetection = workflowState['auto-detection'];
  const deviceDetection = workflowState['device-detection']
  const ManualSelection = workflowState['manual-detection']
  let agreementDetails = workflowState['CreateAgreementResponse'];

  const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
  const Make = detectionData.Make;
  const Model = detectionData.Model;

  let formattedModelName = formatModelName(Make, Model)
  const updateAgreement = () => {
    const UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails?.AgreementRequestId,
        AgreementRequestStatus: 'VHC_MIRROR_INSTR2',
      },
    }
    API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
      updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
      return completeCurrentStep(currentStep, {
        imeiUploadSuccessV2: true
      })
    })
  }
  headerText.args = {
    ...headerText.args,
    content: `On your <span class='text-purple-700'>${Make} ${formattedModelName}<span>`
  }
  btnNext.args = {
    ...btnNext.args,
    onClick: s => updateAgreement()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default IMEIUploadSuccess;