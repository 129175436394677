import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { useNavigate } from "react-router-dom";
import { findById } from "./helper";

function NationalidUploadSkip() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const navigate = useNavigate();
    const deviceDetection = workflowState['device-detection']
    const autoDetection = workflowState['auto-detection']
    const ManualSelection = workflowState['manual-detection']
    const quoteOffer = workflowState['quote-offer']
    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const isNewPhone = detectionData?.isNewPhone
    const metadata = workflowState['config']?.metadata['NationalIdUploadSkip']
    const btnNext = findById(metadata, 'btnNext')
    const navigateTo = isNewPhone ? 'ReceiptUpload' : 'VideoCapture'
    btnNext.args = {
        ...btnNext.args,
        onClick: e => {
            return completeCurrentStep(currentStep, {
                navigateTo: navigateTo
            })
        }
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default NationalidUploadSkip;