import React, { useContext } from 'react'
import { Stack } from '../../stack/stack'
import { findById } from '../helper'
import { WorkflowContext } from '../../workflow/workflow-context'

function PrestigePlan({ selectedPlanFeatures,updateAgreement }) {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['PrestigePlan']
  const btnSelect = findById(metadata, 'btnSelect')
  const prestigeCarts = findById(metadata, 'prestige-carts-1')

  prestigeCarts.args = {
    ...prestigeCarts.args,
    data: selectedPlanFeatures?.cards
  }
  btnSelect.args = {
    ...btnSelect.args,
    onClick: e => updateAgreement()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default PrestigePlan;