/* eslint-disable max-len */
import React from 'react'

const greenTick = ({ onClick, isSelected, className }) => (
  <svg onClick={onClick} class={className} width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="10.1904" cy="10.0007" rx="6.79365" ry="6.66667" fill="#00B574"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M9.64458 12.6185L8.83582 13.4518L6.00513 10.5352L6.8139 9.70183L9.64458 12.6185ZM13.6884 6.78516L14.4972 7.61849L9.64458 12.6185L8.83581 11.7852L13.6884 6.78516Z" fill="white"/>
  </svg>
)

export default greenTick
