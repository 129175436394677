import React, { useContext } from 'react'
import { Stack } from '../../stack/stack'
import { findById } from '../helper'
import { WorkflowContext } from '../../workflow/workflow-context'

function ClassicPlan({ selectedPlanFeatures,updateAgreement }) {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['ClassiPlan']

  const btnSelect = findById(metadata, 'btnSelect')
  const classicCarts = findById(metadata, 'classic-carts-1')
  const classicCarts2 = findById(metadata, 'classic-carts-2')

  classicCarts.args = {
    ...classicCarts.args,
    data: selectedPlanFeatures?.cards
  }
  classicCarts2.args = {
    ...classicCarts2.args,
    data: selectedPlanFeatures?.keyPointsCards
  }
  btnSelect.args = {
    ...btnSelect.args,
    onClick: e => updateAgreement()
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default ClassicPlan;