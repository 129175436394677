import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import initAmplifyConfig from "../enrollment-workflow/amplify-config";

const WorkflowContext = createContext({})


const { Provider } = WorkflowContext

const useWorkflow = (initialStep) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [workflowState, setWorkflowState] = useState({});
  const navigate = useNavigate();

    const completeCurrentStep = (getNextStep, step, data) => {
        let newWFState = { ...workflowState, [step]: data };
        setWorkflowState(s => ({...s, ...newWFState}))
        console.log('Workflow', workflowState?.config?.workflow, data.workflow)
        let nextStep = getNextStep(workflowState?.config?.workflow || data.workflow, newWFState, step);
        setCurrentStep(nextStep);
        navigate(nextStep);
      };
    
      const updateState = (state) => {
        setWorkflowState(s => ({...s, ...state}))
      }
      console.log("workflowState",workflowState)
      return [currentStep, completeCurrentStep, workflowState, updateState]
}


const WorkflowProvider = ({ getNextStep, initialStep, initialization, children }) => {
  initAmplifyConfig('coolade')

  const [currentStep, completeCurrentStep, workflowState, updateState] = useWorkflow(initialStep)
  // useEffect(() => {
  //   initialization(workflowState, updateState);
  // }, [])
  return <Provider value={ [currentStep, (step, data) => completeCurrentStep(getNextStep, step, data), workflowState, updateState] }>{children}</Provider>
}

export { WorkflowContext, WorkflowProvider }