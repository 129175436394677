import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById, formatCapacity, formatModelName, getPrice } from "./helper";

function ReceiptidUploadSkip() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
      )
    const metadata = workflowState['config']?.metadata['ReceiptUploadSkip']
    const btnNext = findById(metadata, 'btnNext')
    const modelname = findById(metadata, 'modelname')
    const paymentText = findById(metadata, 'payment-text')
    const priceValue = findById(metadata, 'price-value')
    const priceValueMonth = findById(metadata, 'price-value-month')
    const priceTenure = findById(metadata, 'price-tenure')

    let autoDetection = workflowState['auto-detection'];
    const planDetails = workflowState['GetQuoteResponse']
    const quoteOffer = workflowState['quote-offer']
    const deviceDetection = workflowState['device-detection']
    const ManualSelection = workflowState['manual-detection']

    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const Make = detectionData.Make;
    const Model = detectionData.Model;

    let formattedModelName = formatModelName(Make, Model)
    let formattedCapacity = formatCapacity(detectionData?.Size)
    const PaymentTanure = quoteOffer?.selectedPaymentMethod?.toLowerCase()
    const isNewPhone = false //detectionData?.isNewPhone
    const selectedPlanName = quoteOffer?.selectedPlanName

    let discount = detectionData?.discountApplied
    let otherDiscount = discount?.filter((v) => v !== 'DIGITAL PROMOTIONAL DISCOUNT').filter((v) => v !== 'NEWPH_DISC')
    let monthlyFee = getPrice('MONTHLY', planDetails[selectedPlanName?.toUpperCase()], isNewPhone, otherDiscount[0])
    let yearlyFee = getPrice('YEARLY', planDetails[selectedPlanName?.toUpperCase()], isNewPhone, otherDiscount[0])

    const price = PaymentTanure === 'annual' ? yearlyFee : monthlyFee
    const monthlyPriceForYearlyFee = PaymentTanure === 'annual' ? (Number(yearlyFee) / 12)?.toFixed(2)?.toString() : ''

    btnNext.args = {
        ...btnNext.args,
        onClick: e => {
            return completeCurrentStep(currentStep, {
                ReceiptUploadSkipped: true
            })
        }
    }
    priceValue.args = {
        ...priceValue.args,
        content: `${price}`
    }
    priceTenure.args = {
        ...priceTenure.args,
        content: `${PaymentTanure === 'annual' ? 'year' : 'month'}`
    }

    priceValueMonth.args = {
        ...priceValueMonth.args,
        content: `$${monthlyPriceForYearlyFee}per month`
    }
    modelname.args = {
        ...modelname.args,
        content: `${Make?.toPascalCase()} ${formattedModelName} ${formattedCapacity}`
    }
    paymentText.args = {
        ...paymentText.args,
        content: `As you are no longer applying for cover for a new phone, we have revised your <b>${PaymentTanure} payment</b> to <b>$${PaymentTanure === 'annual' ? yearlyFee : monthlyFee}</b>.`
    }

    return <Stack orientation='vertical' metadata={metadata} />
}

export default ReceiptidUploadSkip;