import React, { useContext } from 'react'

import { Stack } from '../../stack/stack'
import { findById } from '../helper'
import { WorkflowContext } from '../../workflow/workflow-context'

export const FAQs = () => {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['FAQs']
    const quoteOffer = workflowState['quote-offer']
    const selectedPlanName = quoteOffer?.selectedPlanName || 'Lite'
    const PDSDoc = findById(metadata, 'pds-doc')
    const faqDoc = findById(metadata, 'faq-doc')
    
    const openDocument = (url) => {
        window.open(url, '_blank')
    }
    PDSDoc.args = {
        ...PDSDoc.args,
        onClick: () => openDocument(`$${process.env.REACT_APP_ASURION_URL}/resources/docs/asurionmobileplus-fsg-pds-${selectedPlanName.toLowerCase()}.pdf`)
    }
    faqDoc.args = {
        ...faqDoc.args,
        onClick: () => openDocument(`${process.env.REACT_APP_ASURION_URL}/faqs`)
    }

    return <Stack orientation='vertical' metadata={metadata} />
}

export default FAQs
