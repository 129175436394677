import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../button/button'

export const PhoneColors = ({
  deviceColors,
  onClick,
  phoneColor,
  ...props
}) => {
  const style = {
    height: '48px',
    padding: '2px',
    width: '48px',
    border: '2px solid #9419fc',
    borderRadius: '999px'
  }
  return (
    <div className='flex flex-wrap mb-5'>
      {deviceColors.map(color => {
        return (
          <div
            key={color.color}
            className='px-2 py-1 flex flex-col items-center w-1/4'
          >
            <div style={color.color === phoneColor ? style : {}}>
              <Button
                label=''
                style={{ boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.25)' }}
                colorClasses={color.ColorCode}
                paddingClasses='p-1 px-3'
                dimensionClasses='w-10 h-10'
                borderClasses='rounded-full'
                onClick={() => onClick(color)}
              />
            </div>
            <p className='text-sm leading-18 mt-2'>{color.color}</p>
          </div>
        )
      })}
    </div>
  )
}

PhoneColors.propTypes = {
  onClick: PropTypes.func,
  deviceColors: PropTypes.arrayOf(PropTypes.object)
}

PhoneColors.defaultProps = {
  onClick: undefined,
  deviceColors: [
    { ColorCode: 'bg-red-200', color: 'Red' },
    { ColorCode: 'bg-blue-300', color: 'Blue' }
  ]
}
