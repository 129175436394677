/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
  <svg className={className} width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M140.507 6.71484H52.2782C48.5299 6.71484 45.4913 9.7586 45.4913 13.5133V135.885C45.4913 139.64 48.5299 142.683 52.2782 142.683H140.507C144.256 142.683 147.294 139.64 147.294 135.885V13.5133C147.294 9.7586 144.256 6.71484 140.507 6.71484Z"
        fill="#F0F0F5"
        stroke="#333F48"
        stroke-width="1.57"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M136.173 114.277L118.853 131.627"
        stroke="#333F48"
        stroke-width="1.57"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M136.173 127.301L127.531 135.957"
        stroke="#333F48"
        stroke-width="1.57"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.925 36.1382V113.25C120.986 114.611 120.508 115.941 119.594 116.95C118.68 117.959 117.406 118.564 116.048 118.635H77.0547C75.6974 118.564 74.4232 117.958 73.5108 116.949C72.5984 115.94 72.1221 114.61 72.1861 113.25V36.1382C72.1221 34.7783 72.5984 33.4484 73.5108 32.4393C74.4232 31.4303 75.6974 30.8243 77.0547 30.7539H81.9321L86.5744 36.1926C87.0048 36.7221 87.5469 37.1495 88.1618 37.4441C88.7767 37.7388 89.449 37.8933 90.1307 37.8967H102.971C103.653 37.8933 104.326 37.7388 104.94 37.4441C105.555 37.1495 106.097 36.7221 106.528 36.1926L111.17 30.7539H116.048C117.406 30.8243 118.68 31.4299 119.594 32.4387C120.508 33.4475 120.986 34.7774 120.925 36.1382Z"
        fill="white"
        stroke="#333F48"
        stroke-width="1.36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.179 30.7539L106.537 36.1926C106.107 36.7221 105.565 37.1495 104.95 37.4441C104.335 37.7388 103.662 37.8933 102.98 37.8967H90.1579C89.4763 37.8933 88.8039 37.7388 88.189 37.4441C87.5741 37.1495 87.032 36.7221 86.6017 36.1926L81.9594 30.7539H111.179Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="1.42"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M106.709 47.9688H86.6829C84.439 47.9688 82.6199 49.7909 82.6199 52.0387V94.6603C82.6199 96.9081 84.439 98.7303 86.6829 98.7303H106.709C108.953 98.7303 110.772 96.9081 110.772 94.6603V52.0387C110.772 49.7909 108.953 47.9688 106.709 47.9688Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.91"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M96.818 53.3906H87.5879C86.8882 53.3906 86.321 53.9588 86.321 54.6597V63.9055C86.321 64.6064 86.8882 65.1746 87.5879 65.1746H96.818C97.5177 65.1746 98.0849 64.6064 98.0849 63.9055V54.6597C98.0849 53.9588 97.5177 53.3906 96.818 53.3906Z"
        fill="#F0F0F5"
        stroke="#333F48"
        stroke-width="0.91"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.1126 58.6925C91.0972 58.6925 91.8953 57.893 91.8953 56.9068C91.8953 55.9206 91.0972 55.1211 90.1126 55.1211C89.1281 55.1211 88.33 55.9206 88.33 56.9068C88.33 57.893 89.1281 58.6925 90.1126 58.6925Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.91"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M94.9177 61.2316C95.9022 61.2316 96.7004 60.4321 96.7004 59.4459C96.7004 58.4596 95.9022 57.6602 94.9177 57.6602C93.9331 57.6602 93.135 58.4596 93.135 59.4459C93.135 60.4321 93.9331 61.2316 94.9177 61.2316Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.91"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M90.1126 63.8058C91.0972 63.8058 91.8953 63.0063 91.8953 62.0201C91.8953 61.0339 91.0972 60.2344 90.1126 60.2344C89.1281 60.2344 88.33 61.0339 88.33 62.0201C88.33 63.0063 89.1281 63.8058 90.1126 63.8058Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.91"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M76.7198 56.5095C76.5446 56.5095 76.3766 56.4398 76.2527 56.3157C76.1289 56.1916 76.0592 56.0233 76.0592 55.8478V43.5472C76.0616 43.3732 76.1322 43.2072 76.2558 43.0851C76.3795 42.9629 76.5461 42.8945 76.7198 42.8945H88.9995C89.1723 42.8945 89.338 42.9633 89.4602 43.0857C89.5823 43.2081 89.651 43.3741 89.651 43.5472C89.651 43.7202 89.5823 43.8864 89.4602 44.0088C89.338 44.1312 89.1723 44.1998 88.9995 44.1998H77.3715V55.8478C77.3715 56.0218 77.303 56.1887 77.1811 56.3126C77.0591 56.4364 76.8934 56.5071 76.7198 56.5095Z"
        fill="#8223D2"
      />
      <path
        d="M116.4 56.5095C116.576 56.5095 116.744 56.4398 116.867 56.3157C116.991 56.1916 117.061 56.0233 117.061 55.8478V43.5472C117.059 43.3732 116.988 43.2072 116.864 43.0851C116.741 42.9629 116.574 42.8945 116.4 42.8945H104.121C103.948 42.8945 103.782 42.9633 103.66 43.0857C103.538 43.2081 103.469 43.3741 103.469 43.5472C103.469 43.7202 103.538 43.8864 103.66 44.0088C103.782 44.1312 103.948 44.1998 104.121 44.1998H115.749V55.8478C115.749 56.0218 115.817 56.1887 115.939 56.3126C116.061 56.4364 116.227 56.5071 116.4 56.5095Z"
        fill="#8223D2"
      />
      <path
        d="M76.7198 91.2617C76.5446 91.2617 76.3766 91.3313 76.2527 91.4554C76.1289 91.5795 76.0592 91.7479 76.0592 91.9234V104.224C76.0592 104.399 76.1289 104.568 76.2527 104.692C76.3766 104.816 76.5446 104.886 76.7198 104.886H88.9995C89.1747 104.886 89.3427 104.816 89.4666 104.692C89.5904 104.568 89.6601 104.399 89.6601 104.224C89.6577 104.05 89.5871 103.884 89.4635 103.762C89.3398 103.64 89.1732 103.571 88.9995 103.571H77.3715V91.9234C77.3715 91.7495 77.303 91.5825 77.1811 91.4587C77.0591 91.3348 76.8934 91.2641 76.7198 91.2617Z"
        fill="#8223D2"
      />
      <path
        d="M116.4 91.2617C116.576 91.2617 116.744 91.3313 116.867 91.4554C116.991 91.5795 117.061 91.7479 117.061 91.9234V104.224C117.061 104.399 116.991 104.568 116.867 104.692C116.744 104.816 116.576 104.886 116.4 104.886H104.121C103.947 104.883 103.781 104.812 103.659 104.689C103.537 104.565 103.469 104.398 103.469 104.224C103.469 104.051 103.538 103.885 103.66 103.762C103.782 103.64 103.948 103.571 104.121 103.571H115.749V91.9234C115.749 91.7495 115.817 91.5825 115.939 91.4587C116.061 91.3348 116.227 91.2641 116.4 91.2617Z"
        fill="#8223D2"
      />
      <path
        d="M113.487 107.93H79.6337C79.2838 107.93 79.0002 108.214 79.0002 108.564V113.876C79.0002 114.226 79.2838 114.511 79.6337 114.511H113.487C113.836 114.511 114.12 114.226 114.12 113.876V108.564C114.12 108.214 113.836 107.93 113.487 107.93Z"
        fill="#8223D2"
        stroke="#333F48"
        stroke-width="1.27"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.238 7.39453H52.8755C51.0971 7.39453 49.3915 8.10204 48.134 9.36169C46.8764 10.6213 46.1699 12.3299 46.1699 14.1113V135.277C46.1798 136.44 46.4916 137.58 47.0748 138.586L114.238 7.39453Z"
        fill="white"
      />
      <path
        d="M75.8964 13.7031L59.3093 30.3185"
        stroke="#333F48"
        stroke-width="1.57"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M63.9063 13.7031L57.2371 20.3837"
        stroke="#333F48"
        stroke-width="1.57"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.661 52.5838V145.686C77.661 149.312 75.0277 152.176 71.779 152.176H24.7235C21.4749 152.176 18.8416 149.276 18.8416 145.686V52.5838C18.8416 48.9579 21.4749 46.0846 24.7235 46.0846H30.6054L36.2159 52.6836C36.7377 53.3215 37.3935 53.8364 38.1364 54.192C38.8792 54.5476 39.6909 54.7352 40.5142 54.7413H56.0064C56.8297 54.7317 57.6406 54.5408 58.3818 54.182C59.1231 53.8233 59.7766 53.3055 60.2957 52.6654L65.9062 46.0664H71.7881C75.0367 46.0664 77.67 48.9761 77.67 52.5656L77.661 52.5838Z"
        fill="#F0F0F5"
        stroke="#333F48"
        stroke-width="1.81"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.897 46.0846L60.2957 52.6654C59.7738 53.3033 59.1182 53.8184 58.3754 54.1741C57.6325 54.5297 56.8206 54.7171 55.9973 54.7231H40.5051C39.6818 54.7171 38.8701 54.5297 38.1273 54.1741C37.3844 53.8184 36.7286 53.3033 36.2067 52.6654L30.5963 46.0664L65.897 46.0846Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="1.81"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.0148 73.621V131.979C66.064 133.007 65.7038 134.013 65.0134 134.776C64.3229 135.539 63.3585 135.997 62.3317 136.049H32.8224C31.7949 135.997 30.8296 135.54 30.1377 134.777C29.4458 134.014 29.0837 133.008 29.1305 131.979V73.621C29.0837 72.5915 29.4458 71.5851 30.1377 70.8224C30.8296 70.0597 31.7949 69.6024 32.8224 69.5508H36.5145L40.0257 73.6843C40.3554 74.0868 40.7694 74.4116 41.2386 74.6357C41.7078 74.8597 42.2207 74.9776 42.7404 74.9807H52.4502C52.9702 74.9795 53.4836 74.8625 53.9531 74.6383C54.4226 74.4141 54.8365 74.0883 55.1649 73.6843L58.6849 69.5508H62.377C63.4038 69.6024 64.3682 70.06 65.0586 70.8231C65.7491 71.5861 66.1093 72.5923 66.0601 73.621H66.0148Z"
        fill="white"
        stroke="#333F48"
        stroke-width="1.03"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6396 69.5508L55.1196 73.6843C54.7912 74.0883 54.3773 74.4141 53.9078 74.6383C53.4383 74.8625 52.925 74.9795 52.4049 74.9807H42.6952C42.1754 74.9776 41.6627 74.8597 41.1935 74.6357C40.7243 74.4116 40.3101 74.0868 39.9804 73.6843L36.4692 69.5508H58.6396Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="1.08"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.2554 82.5781H40.1071C38.4079 82.5781 37.0304 83.958 37.0304 85.6601V117.903C37.0304 119.605 38.4079 120.985 40.1071 120.985H55.2554C56.9546 120.985 58.3321 119.605 58.3321 117.903V85.6601C58.3321 83.958 56.9546 82.5781 55.2554 82.5781Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.69"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.7626 86.6758H40.7857C40.256 86.6758 39.8265 87.106 39.8265 87.6366V94.6254C39.8265 95.1561 40.256 95.5862 40.7857 95.5862H47.7626C48.2924 95.5862 48.7218 95.1561 48.7218 94.6254V87.6366C48.7218 87.106 48.2924 86.6758 47.7626 86.6758Z"
        fill="#F0F0F5"
        stroke="#333F48"
        stroke-width="0.69"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.6951 90.6895C43.4398 90.6895 44.0434 90.0848 44.0434 89.3389C44.0434 88.593 43.4398 87.9883 42.6951 87.9883C41.9505 87.9883 41.3468 88.593 41.3468 89.3389C41.3468 90.0848 41.9505 90.6895 42.6951 90.6895Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.69"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.3328 92.6114C47.0775 92.6114 47.6811 92.0067 47.6811 91.2608C47.6811 90.5148 47.0775 89.9102 46.3328 89.9102C45.5882 89.9102 44.9845 90.5148 44.9845 91.2608C44.9845 92.0067 45.5882 92.6114 46.3328 92.6114Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.69"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.6951 94.5606C43.4398 94.5606 44.0434 93.9559 44.0434 93.21C44.0434 92.4641 43.4398 91.8594 42.6951 91.8594C41.9505 91.8594 41.3468 92.4641 41.3468 93.21C41.3468 93.9559 41.9505 94.5606 42.6951 94.5606Z"
        fill="#D5D6DA"
        stroke="#333F48"
        stroke-width="0.69"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.5601 89.0324C32.4297 89.0324 32.3044 88.981 32.2113 88.8895C32.1182 88.7979 32.0647 88.6735 32.0624 88.5429V79.2245C32.0647 79.0939 32.1182 78.9693 32.2113 78.8777C32.3044 78.7862 32.4297 78.735 32.5601 78.735H41.8536C41.924 78.7254 41.9955 78.7311 42.0635 78.7516C42.1315 78.772 42.1945 78.8068 42.248 78.8536C42.3015 78.9004 42.3443 78.9579 42.3737 79.0227C42.403 79.0874 42.4181 79.1578 42.4181 79.2289C42.4181 79.3001 42.403 79.3704 42.3737 79.4352C42.3443 79.4999 42.3015 79.5577 42.248 79.6045C42.1945 79.6513 42.1315 79.6861 42.0635 79.7065C41.9955 79.7269 41.924 79.7327 41.8536 79.7231H33.0577V88.5429C33.0553 88.6735 33.0018 88.7979 32.9087 88.8895C32.8157 88.981 32.6906 89.0324 32.5601 89.0324Z"
        fill="#8223D2"
      />
      <path
        d="M62.5942 89.0318C62.7238 89.0318 62.8481 88.9802 62.9397 88.8884C63.0314 88.7966 63.0829 88.6721 63.0829 88.5423V79.2239C63.0829 79.0941 63.0314 78.9696 62.9397 78.8778C62.8481 78.786 62.7238 78.7344 62.5942 78.7344H53.2916C53.1723 78.7506 53.0629 78.8095 52.9837 78.9004C52.9045 78.9912 52.8608 79.1077 52.8608 79.2283C52.8608 79.3489 52.9045 79.4655 52.9837 79.5563C53.0629 79.6471 53.1723 79.7063 53.2916 79.7225H62.1055V88.5423C62.1055 88.6721 62.157 88.7966 62.2487 88.8884C62.3403 88.9802 62.4646 89.0318 62.5942 89.0318Z"
        fill="#8223D2"
      />
      <path
        d="M32.5601 115.336C32.4948 115.336 32.4298 115.349 32.3695 115.374C32.3091 115.399 32.2544 115.436 32.2082 115.482C32.162 115.528 32.1252 115.583 32.1002 115.644C32.0751 115.704 32.0624 115.769 32.0624 115.835V125.18C32.0624 125.246 32.0751 125.31 32.1002 125.371C32.1252 125.431 32.162 125.486 32.2082 125.533C32.2544 125.579 32.3091 125.616 32.3695 125.641C32.4298 125.666 32.4948 125.679 32.5601 125.679H41.8536C41.919 125.679 41.9837 125.666 42.0441 125.641C42.1044 125.616 42.1591 125.579 42.2053 125.533C42.2515 125.486 42.2883 125.431 42.3134 125.371C42.3384 125.31 42.3511 125.246 42.3511 125.18C42.3488 125.05 42.2953 124.925 42.2022 124.833C42.1092 124.742 41.9841 124.691 41.8536 124.691H33.0577V115.871C33.0626 115.802 33.0535 115.734 33.0307 115.669C33.0079 115.604 32.972 115.545 32.9253 115.495C32.8786 115.444 32.8221 115.404 32.7592 115.377C32.6963 115.35 32.6286 115.336 32.5601 115.336Z"
        fill="#8223D2"
      />
      <path
        d="M62.5942 115.336C62.7246 115.338 62.8488 115.392 62.9402 115.485C63.0316 115.579 63.0829 115.704 63.0829 115.835V125.18C63.0829 125.311 63.0316 125.436 62.9402 125.53C62.8488 125.623 62.7246 125.676 62.5942 125.679H53.2917C53.1612 125.676 53.0369 125.623 52.9455 125.53C52.8541 125.436 52.803 125.311 52.803 125.18C52.803 125.05 52.8545 124.926 52.9461 124.834C53.0378 124.742 53.1621 124.691 53.2917 124.691H62.1055V115.871C62.1006 115.803 62.1096 115.735 62.1318 115.671C62.154 115.607 62.189 115.548 62.2348 115.498C62.2805 115.448 62.336 115.408 62.3978 115.38C62.4596 115.352 62.5265 115.337 62.5942 115.336Z"
        fill="#8223D2"
      />
      <path
        d="M60.3862 127.953H34.7681C34.5032 127.953 34.2885 128.168 34.2885 128.434V132.449C34.2885 132.714 34.5032 132.93 34.7681 132.93H60.3862C60.6511 132.93 60.8658 132.714 60.8658 132.449V128.434C60.8658 128.168 60.6511 127.953 60.3862 127.953Z"
        fill="#8223D2"
        stroke="#333F48"
        stroke-width="0.96"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="130" height="147" fill="white" transform="translate(18 6)" />
      </clipPath>
    </defs>
  </svg>
)

export default icon
