import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

export const QRField = ({
    qrUrl,
    QRState,
    ...props
}) => {

    return (
        <QRCode
            value={qrUrl}
            size={256}
            fgColor={QRState.expired ? '#A5AAAF' : '#000000'}
            className="mx-auto"
            metadata="uuid"
        />
    );
};

// QRField.propTypes = {
//      qrUrl: PropTypes.string,
//      QRState: PropTypes.object(PropTypes.string)
// };

// QRField.defaultProps = {
//     qrUrl: ''
// };
