/* eslint-disable max-len */
import React from 'react'

const icon = ({ isSelected, className }) => (
  <svg className={className} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.5" cy="6.5" r="6.5" fill="#8223D2" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="3" width="7" height="7">
      <rect x="3" y="3" width="7" height="7" fill="white" />
    </mask>
    <g mask="">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.08337 8.25001L5.52782 8.83334L3.58337 6.79167L4.13893 6.20834L6.08337 8.25001ZM8.86115 4.16667L9.41671 4.75L6.08337 8.25001L5.52782 7.66667L8.86115 4.16667Z" fill="white" />
    </g>
  </svg>

)

export default icon
