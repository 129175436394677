import { useContext, useEffect } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { appleModels } from './appleModelsList'
import { samsungModels } from './samsungModelsList'
import { oppoModels } from './oppoModelsList'
import { useDeviceSelectors } from 'react-device-detect';
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import { createKeyValuePair, uniqFilter } from "./helper";

function detectDevice(selectors) {

    const { mobileVendor } = selectors

    // get current height n width of the phone
    let nativeWidth = window.screen.width * window.devicePixelRatio
    let nativeHeight = window.screen.height * window.devicePixelRatio

    // decide phoneModelList based on brandName
    let phoneModelList = mobileVendor.toUpperCase() === 'APPLE' ? appleModels
        : mobileVendor.toUpperCase() === 'SAMSUNG' ? samsungModels
            : oppoModels

    // return if the device is desktop or other device
    if (!phoneModelList) return {}
    // console.log(mobileModel,mobileVendor, nativeWidth, nativeHeight, phoneModelList);

    // loop though the phoneModelList & return the object that matches with height / width
    let deviceInfo = phoneModelList.filter((pm) => pm.height === nativeHeight && pm.width === nativeWidth) || {}

    // since local is emulated environment, can return detected brand model from here
    // if (process.env.BG_TRANSACTION_ENV === 'DEV') return { brand, model }
    // return the deviceInfo object
    return deviceInfo
}

function DeviceDetection() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const isDesktop = window.innerWidth > 1023;
    const queryParams = new URLSearchParams(window.location.search)
    const reskey_src = queryParams.get("reskey_src")
    const queryParamDevice = queryParams.get("device")
    const queryParamPlan = queryParams.get("plan")
    let QRScannedFromDesktop = reskey_src === 'desktop';
    let device, plan;
    if (queryParamDevice || queryParamPlan) {
        device = queryParamDevice.toPascalCase();
        plan = queryParamPlan.toUpperCase() || "LTTE"
    }

    let devices = [];
    let userAgent = navigator.userAgent || navigator.vendor || window.opera
    const [selectors] = useDeviceSelectors(userAgent)

    if (!isDesktop) {
        devices = detectDevice(selectors);
    }
    useEffect(() => {
        initialization(workflowState, updateState);
    }, [])
    const initialization = (state, setState) => {
        API[ActionTypes.INTIALIZE_API_REQUEST]().then(data => {
            let makeOptions = createKeyValuePair(uniqFilter(data.data.InitializeResponse.DeviceDetails, 'AssetMakeName'))
            console.log(data.data.InitializeResponse.workflow, data.data.InitializeResponse.metadata)
            setState({ ...state, config: { ...data.data.InitializeResponse, makeOptions } });
            if (currentStep === 'device-detection') {
                completeCurrentStep(currentStep, { workflow: data.data.InitializeResponse.workflow, metadata: data.data.InitializeResponse.metadata, device, plan, isDesktop, autoDetectedDevices: devices, QRScannedFromDesktop, auto: devices.length > 0 })
            }
        })
    };
    // useEffect(() => {
    //     if (currentStep === 'device-detection') {
    //         completeCurrentStep(currentStep, { device, plan, isDesktop, autoDetectedDevices: devices, QRScannedFromDesktop, auto: devices.length > 0 })
    //     }
    // })


    return <></>
}

export default DeviceDetection;