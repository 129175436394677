/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 5.00098H4V7.00098H20V5.00098Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 11H4V13H20V11Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 17H4V19H20V17Z" fill="black" />
    </g>
  </svg>
)

export default icon
