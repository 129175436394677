/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
            <rect width="30" height="30" fill="white" />
        </mask>
        <g>
            <path fillRule="evenodd" clipRule="evenodd" d="M4.49991 9.23225C5.03081 7.9745 5.78189 6.87732 6.6614 5.88399C8.65361 3.63737 11.0649 2.19803 13.9194 1.70678C17.9145 1.01992 21.49 2.03428 24.5801 4.8983C24.7754 5.07926 24.8233 5.18503 24.6115 5.40867C23.5206 6.55874 22.447 7.72729 21.3674 8.88882C21.2568 9.00797 21.1822 9.15324 20.9857 8.95955C18.2649 6.27584 13.8241 6.30642 10.9335 9.20039C9.94092 10.1944 9.23541 11.3852 8.77376 12.75C8.7057 12.7022 8.63467 12.6602 8.57134 12.6073C7.21361 11.4827 5.85705 10.3581 4.49991 9.23225Z" fill="#D7282A" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.15968 17.25C8.56419 18.3114 9.06691 19.3146 9.81331 20.1667C11.7131 22.3334 14.0671 23.268 16.8882 22.9656C18.1981 22.8248 19.3926 22.3664 20.5079 21.6619C20.6153 21.7608 20.7172 21.8661 20.8301 21.9574C22.1364 23.0106 23.4432 24.0612 24.75 25.1125C23.3094 26.5272 21.6072 27.4681 19.6934 27.9678C15.1806 29.1465 11.0195 28.4015 7.3255 25.3903C5.7977 24.1449 4.59646 22.6073 3.75 20.7869C5.2201 19.6081 6.68958 18.4287 8.15968 17.25Z" fill="#45AC43" />
            <path fillRule="evenodd" clipRule="evenodd" d="M24.5559 24.75C23.2734 23.7344 21.9904 22.7193 20.7086 21.7012C20.5984 21.6137 20.4984 21.512 20.393 21.4164C21.2616 20.742 21.9856 19.945 22.4621 18.9324C22.6518 18.5288 22.7855 18.1055 22.9114 17.6785C22.9982 17.3839 22.9711 17.2693 22.6127 17.2724C20.4779 17.2908 18.3437 17.2816 16.209 17.2816C15.7566 17.2816 15.7566 17.2816 15.7566 16.8056C15.756 15.3342 15.7626 13.8628 15.75 12.3914C15.7476 12.1072 15.7964 11.9988 16.1102 12C20.0484 12.011 23.9854 12.0086 27.923 12.0031C28.1351 12.0025 28.2688 12.019 28.3061 12.2818C28.7959 15.7838 28.4031 19.1297 26.6009 22.2207C26.0491 23.1671 25.3859 24.0308 24.5559 24.75Z" fill="#5D7FBE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.21555 18.1399C6.71745 19.343 5.21997 20.5468 3.72186 21.75C2.99001 20.3423 2.56842 18.8311 2.37138 17.2564C2.03048 14.5285 2.40516 11.9034 3.52358 9.40062C3.5855 9.26341 3.66307 9.13332 3.73312 9C5.16743 10.1436 6.6011 11.2859 8.03603 12.4283C8.10296 12.482 8.17802 12.5247 8.24995 12.5732C7.64884 14.4255 7.67886 16.2811 8.21555 18.1399Z" fill="#F4C300" />
        </g>
    </svg>


)

export default icon
