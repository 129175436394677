/* eslint-disable max-len */
import React from 'react'

const icon = ({ className }) => (
    <svg
     className={className} 
     width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8" fill="#2743CC"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 11.4111L9.37795 8.78906L8.7887 9.37832L11.4107 12.0004L8.7887 14.6224L9.37795 15.2117L12 12.5896L14.622 15.2117L15.2113 14.6224L12.5892 12.0004L15.2113 9.37832L14.622 8.78906L12 11.4111Z" fill="white"/>
    </svg>
)

export default icon
