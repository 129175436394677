import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

function IMEIDifferentDevice() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['ImeiDifferentDevice']
    const contactMethodCheckbox = findById(metadata, 'contactMethodCheckbox')
    const PhoneCapacity = findById(metadata, 'PhoneCapacity')
    const PhoneColour = findById(metadata, 'PhoneColour')

    const btnNext = findById(metadata, 'btnNext')
    const [phoneSize, setPhoneSize] = useState('')
    const [phoneColor, setPhoneColor] = useState('')
    const [contactMethod, setContactMethod] = useState()
    contactMethodCheckbox.args = {
        ...contactMethodCheckbox.args,
        value: contactMethod,
        onSelect: setContactMethod
    }
    PhoneColour.args = {
        ...PhoneColour.args,
        value: phoneColor,
        onSelect: setPhoneColor
    }
    PhoneCapacity.args = {
        ...PhoneCapacity.args,
        value: phoneSize,
        onSelect: setPhoneSize
    }
    btnNext.args = {
        ...btnNext.args,
        enabled: phoneSize && phoneColor && contactMethod !== undefined
    }
    return <Stack orientation='vertical' metadata={metadata} />

}

export default IMEIDifferentDevice;