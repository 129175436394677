import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

function Desktop() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['Desktop']
  let btnNext = findById(metadata, 'routes')

  /* this button is added for testing all routes */

  btnNext.args = {
    ...btnNext.args,
    onClick: e => {
      return completeCurrentStep(currentStep, {
        navigateRoutes: true
      })
    }
  }


  return <Stack orientation='vertical' metadata={metadata} />
}

export default Desktop;