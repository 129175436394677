import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById, formatCapacity, formatMobile, formatModelName, getPrice } from "./helper";

function PolicyDetails() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['PolicyDetails']

    const phoneMake = findById(metadata, 'phoneMake')
    const phoneModel = findById(metadata, 'phoneModel')
    const phoneNbr = findById(metadata, 'phoneNumber')
    const plan = findById(metadata, 'plan')
    const premium = findById(metadata, 'premium')
    const insurer = findById(metadata, 'insurer')
    const addressfield = findById(metadata, 'address')



    let autoDetection = workflowState['auto-detection'];
    const planDetails = workflowState['GetQuoteResponse']
    const quoteOffer = workflowState['quote-offer']
    const deviceDetection = workflowState['device-detection']
    const ManualSelection = workflowState['manual-detection']
    const loginDetails = workflowState['login']
    const registerationDetails = workflowState['registeration']

    let address = registerationDetails
    const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
    const Make = detectionData.Make;
    const Model = detectionData.Model;

    let formattedModelName = formatModelName(Make, Model)
    let formattedCapacity = formatCapacity(detectionData?.Size)
    const PaymentTanure = quoteOffer?.selectedPaymentMethod?.toLowerCase()
    const fullAddress = `${address?.StreetNo} ${address?.StreetName ? `${address?.StreetName} ` : ''} ${address?.Suburb} ${address?.PostCode} ${address?.State}`
    const selectedPlanName = quoteOffer?.selectedPlanName
    const isNewPhone = detectionData?.isNewPhone

    let phnNo = registerationDetails?.mobileNumber?.startsWith('0')
        ? formatMobile(registerationDetails?.mobileNumber)
        : `${registerationDetails?.mobileNumber?.split('-')[0]} ${formatMobile(registerationDetails?.mobileNumber?.split('-')[1])}`

    let discount = detectionData?.discountApplied
    let otherDiscount = discount?.filter((v) => v !== 'DIGITAL PROMOTIONAL DISCOUNT').filter((v) => v !== 'NEWPH_DISC')
    let monthlyFee = getPrice('MONTHLY', planDetails[selectedPlanName?.toUpperCase()], isNewPhone, otherDiscount[0])
    let yearlyFee = getPrice('YEARLY', planDetails[selectedPlanName?.toUpperCase()], isNewPhone, otherDiscount[0])


    phoneMake.args = {
        ...phoneMake.args,
        content: Make?.toPascalCase()
    }
    phoneModel.args = {
        ...phoneModel.args,
        content: `${formattedModelName}, ${formattedCapacity}, ${detectionData?.Color}`
    }
    phoneNbr.args = {
        ...phoneNbr.args,
        content: phnNo
    }
    plan.args = {
        ...plan.args,
        content: `Mobile+ ${selectedPlanName?.toPascalCase()}`
    }
    premium.args = {
        ...premium.args,
        content: `${PaymentTanure == 'annual'
            ? `$${yearlyFee} / Year`
            : `$${monthlyFee} / month`}`
    }
    insurer.args = {
        ...insurer.args,
        content: `${loginDetails?.firstName} ${loginDetails?.lastName}`
    }
    addressfield.args = {
        ...addressfield.args,
        content: fullAddress
    }
    return <Stack orientation='vertical' metadata={metadata} />
}

export default PolicyDetails;