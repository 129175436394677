import { useContext, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import { Stack } from '../stack/stack'
import { findById } from "./helper";

import Icon2ndPhn from '../../icons/icon-2ndphone'
import IconMirrorApproach from '../../icons/icon-mirrorapproach'
import API from "../../services";
import ActionTypes from "../../ActionTypes";

function VideoCapture() {
    const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
        WorkflowContext
    )
    const metadata = workflowState['config']?.metadata['VideoCapture']
    const videoCaptureTooltip = findById(metadata, 'videoCaptureTooltip')
    const videoCaptureOption = findById(metadata, 'video-capture-opt')
    const notSureVideo = findById(metadata, 'notSureVideo')
    const [videoCaptureOpt, setVideoCaptureOpt] = useState();
    const btnNext = findById(metadata, 'btnNext')
    let agreementDetails = workflowState['CreateAgreementResponse'];

    const updateAgreement = (status) => {
        let UpdateAgreementRequest = {
            CreateAgreementActivityRequest: {
                AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
                AgreementRequestStatus: status === 'vhc-unsure' ? 'VHC_UNSURE' : 'VHC_TYPE_SELECTION',
            }
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
            updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
            if (status === 'vhc-unsure') {
                return completeCurrentStep(currentStep, {
                    notSureVideo: true
                })
            } else {
                return completeCurrentStep(currentStep, {
                    videoCaptureOptionSelected: true,
                    navigateTo: videoCaptureOpt === 'mirrorapproach' ? 'imei-instructions' : 'letsgetprepared',
                    selectedVideoCaptureOption: videoCaptureOpt
                })
            }
        })
    }

    videoCaptureTooltip.args = {
        ...videoCaptureTooltip.args,
        labelText: '<span class="text-black text-bold">Select an option</span>',
        tooltipText:
            'While most people’s phones are in great working order, we do need to verify everything is 100% ok with your device.'
    }
    let options = videoCaptureOption.args.options
    options.map(
        o =>
            (o.imageName = o.value === '2ndphone' ? Icon2ndPhn : IconMirrorApproach)
    )
    videoCaptureOption.args = {
        ...videoCaptureOption.args,
        options: options,
        value: videoCaptureOpt,
        onSelect: s => setVideoCaptureOpt(s)
    }
    notSureVideo.args = {
        ...notSureVideo.args,
        onClick: s => updateAgreement('vhc-unsure')
    }
    btnNext.args = {
        ...btnNext.args,
        enabled: videoCaptureOpt != undefined ? true : false,
        onClick: e => updateAgreement('vhc-type-selection')
    }

    return <Stack orientation='vertical' metadata={metadata} />
}

export default VideoCapture;