import React from 'react'
import PropTypes from 'prop-types'
import iconUpload from '../../icons/icon-upload'
import { Image } from '../image/image'
import { Text } from '../text/text'
import { Input } from '../input/input'
import iconDelete from '../../icons/icon-delete'
import iconGreenTick from '../../icons/icon-green-tick'

export const FileUploader = ({
  label,
  id,
  enabled,
  handleChange,
  selectedFile,
  removeFile,
  spacing,
  isButton,
  progress,
  ...props
}) => {
  return (
    <>
      <div className='flex flex-col items-center mb-2'>
        <label
          className={`cursor-pointer ${!enabled ? 'opacity-50 pointer-events-none' : ''} 
          ${
            isButton ? 'text-white bg-purple-600 w-full justify-center' : 'text-purple-600 bg-white w-220 '
          } ${spacing} border border-purple-600  flex flex-row items-center py-1 mx-auto rounded-lg px-4`}
        >
          {!isButton && <Image image={iconUpload} />}
          {label}
          <input
            type='file'
            className='hidden'
            enabled={enabled}
            label=''
            onChange={e => handleChange(e)}
          />
        </label>
        {!isButton && (
          <Text
            size='sm'
            style='supressed'
            content='Image (.png or .jpg)'
            marginClasses='mt-2'
          />
        )}
      </div>
      <div className='flex flex-row items-center mb-5'>
        {selectedFile && progress > 0 && (
          <div className='bg-opacity-20 bg-purple-600 w-full my-m10'>
            <div
              style={{
                width:`${progress}%`,
                minWidth: '0%',
                height: '6px',
                background: '#8223D2',
                borderRadius: '10px'
              }}
            />
          </div>
        )}
        <div className='flex '>
          {selectedFile && progress == 100 && (
            <>
              <Image image={iconGreenTick} />
              <Image image={iconDelete} onClick={() => removeFile()} />
            </>
          )}
        </div>
      </div>
    </>
  )
}

FileUploader.propTypes = {
  /**
   * Is the control enable or disabled
   */
  enabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  handleChange: PropTypes.func,
  removeFile: PropTypes.func,
  selectedFile: PropTypes.string,
  isButton: PropTypes.bool
}

FileUploader.defaultProps = {
  enabled: true,
  label: 'Upload',
  id: 'Upload',
  handleChange: undefined,
  selectedFile: undefined,
  removeFile: undefined,
  isButton: false
}
