import React from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

export const Modal = ({ isPopUpOpen, childern, popupStyle, ...props }) => {
  return (
    <Popup
      contentStyle={popupStyle}
      open={isPopUpOpen}
      closeOnDocumentClick={false}
      closeOnEscape={false}
      repositionOnResize={false}
      modal
    >
      {childern}
    </Popup>
  )
}

Modal.propTypes = {
  /**
   * Is the control style for popup
   */
  popupStyle: PropTypes.object,
  /**
   * Is the control popup is open or closed
   */
  isPopUpOpen: PropTypes.bool,
  /**
   * Is the control for component which will be shown in popup
   */
  childern: PropTypes.func
}

Modal.defaultProps = {
  isPopUpOpen: true,
  childern: <div>inside popup</div>,
  popupStyle: {
    padding: '10px',
    alignSelf: 'center',
    background: 'white',
    height: '300px'
  }
}
