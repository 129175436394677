import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '../image/image';
import { Expandable } from '../expandable/expandable';

export const Carts = ({
    data,
    ...props
}) => {

    return (
        <div className="">
            {data.map((item) => {
                return <div key={item.title} className='mb-5' >
                    <Expandable
                        expanded={item.expanded}
                        setIsOpen={item.setIsOpen}
                        marginClasses={item.marginClasses}
                        title={item.title}
                        description={item.description}
                        subtitle={item.subtitle}
                        subsubtitle={item.subsubtitle}
                        isBorder={item.isBorder}
                    />
                </div>;
            })}
        </div>
    );
};

Carts.propTypes = {

};

Carts.defaultProps = {

};
