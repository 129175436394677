export const appleModels = [
  {
    brand: 'Apple',
    model: 'iPhone 12 Pro Max',
    name: 'Apple iPhone 12 Pro Max',
    width: 1284,
    height: 2778,
  },
  {
    brand: 'Apple',
    model: 'iPhone XS Max',
    name: 'Apple iPhone XS Max',
    width: 1242,
    height: 2688,
  },
  {
    brand: 'Apple',
    model: 'iPhone 11 Pro Max',
    name: 'Apple iPhone 11 Pro Max',
    width: 1242,
    height: 2688,
  },
  {
    brand: 'Apple',
    model: 'iPhone 12',
    name: 'Apple iPhone 12',
    width: 1170,
    height: 2532,
  },
  {
    brand: 'Apple',
    model: 'iPhone 12 Pro',
    name: 'Apple iPhone 12 Pro',
    width: 1170,
    height: 2532,
  },
  {
    brand: 'Apple',
    model: 'iPhone XS',
    name: 'Apple iPhone XS',
    width: 1125,
    height: 2436,
  },
  {
    brand: 'Apple',
    model: 'iPhone 11',
    name: 'Apple iPhone 11 Pro',
    width: 1125,
    height: 2436,
  },
  {
    brand: 'Apple',
    model: 'iPhone 12 Mini',
    name: 'Apple iPhone 12 Mini',
    width: 1125,
    height: 2436,
  },
  {
    brand: 'Apple',
    model: 'iPhone XR',
    name: 'Apple iPhone XR',
    width: 828,
    height: 1792,
  },
  {
    brand: 'Apple',
    model: 'iPhone 11',
    name: 'Apple iPhone 11',
    width: 828,
    height: 1792,
  },
  {
    brand: 'Apple',
    model: 'iPhone X',
    name: 'Apple iPhone X',
    width: 1125,
    height: 2436,
  },
  {
    brand: 'Apple',
    model: 'iPhone 8 Plus',
    name: 'Apple iPhone 8 Plus',
    width: 1242,
    height: 2208,
  },
  {
    brand: 'Apple',
    model: 'iPhone 8',
    name: 'Apple iPhone 8',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone 7 Plus',
    name: 'Apple iPhone 7 Plus',
    width: 1080,
    height: 1920,
  },
  {
    brand: 'Apple',
    model: 'iPhone 7',
    name: 'Apple iPhone 7',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone 6 Plus',
    name: 'Apple iPhone 6 Plus',
    width: 1080,
    height: 1920,
  },
  {
    brand: 'Apple',
    model: 'iPhone 6s',
    name: 'Apple iPhone 6s',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone 6',
    name: 'Apple iPhone 6',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE 2020',
    name: 'Apple iPhone SE 2020',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE',
    name: 'Apple iPhone SE',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE (2nd generation)',
    name: 'Apple iPhone SE (2nd generation)',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE 2',
    name: 'Apple iPhone SE 2',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE 3',
    name: 'Apple iPhone SE 3',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE 2022',
    name: 'Apple iPhone SE 2022',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone SE (3rd generation)',
    name: 'Apple iPhone SE (3rd generation)',
    width: 750,
    height: 1334,
  },
  {
    brand: 'Apple',
    model: 'iPhone 5',
    name: 'Apple iPhone 5',
    width: 640,
    height: 1136,
  },
  {
    brand: 'Apple',
    model: 'iPhone 5s',
    name: 'Apple iPhone 5s',
    width: 640,
    height: 1136,
  },
  {
    brand: 'Apple',
    model: 'iPhone 5c',
    name: 'Apple iPhone 5c',
    width: 640,
    height: 1136,
  },
  {
    brand: 'Apple',
    model: 'iPhone 4S',
    name: 'Apple iPhone 4S',
    width: 640,
    height: 960,
  },
  {
    brand: 'Apple',
    model: 'iPhone 4',
    name: 'Apple iPhone 4',
    width: 640,
    height: 960,
  },
  {
    brand: 'Apple',
    model: 'iPhone 3GS',
    name: 'Apple iPhone 3GS',
    width: 320,
    height: 480,
  },
  {
    brand: 'Apple',
    model: 'iPhone 13 Pro Max',
    name: 'Apple iPhone 13 Pro Max',
    width: 1284,
    height: 2778,
  },
  {
    brand: 'Apple',
    model: 'iPhone 13',
    name: 'Apple iPhone 13',
    width: 1170,
    height: 2532,
  },
  {
    brand: 'Apple',
    model: 'iPhone 13 Pro',
    name: 'Apple iPhone 13 Pro',
    width: 1170,
    height: 2532,
  },
  {
    brand: 'Apple',
    model: 'iPhone 13 Mini',
    name: 'Apple iPhone 13 Mini',
    width: 1125,
    height: 2436,
  }
]