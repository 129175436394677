import "./style.css";
import EnrollmentWorkflow from "./components/enrollment-workflow/enrollment-workflow";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { MetadataProvider } from './components/metadata-context'
import { ControlLocator } from './components/control-locator'
import { toPascalCase } from "./components/enrollment-workflow/helper";
String.prototype.toPascalCase = toPascalCase

function App() {
  return (
    <MetadataProvider controlLocator={ControlLocator}>
      <Router>
        <EnrollmentWorkflow />
      </Router>
    </MetadataProvider>
  );
}

export default App;
