import React, { useContext, useEffect, useState } from 'react'
import { Stack } from '../../stack/stack'
import { findById, getPrice } from '../helper'
import { WorkflowContext } from '../../workflow/workflow-context'

export const PriceSection = () => {
  
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const metadata = workflowState['config']?.metadata['PriceSection']

  const pricetext = findById(metadata, 'pricetext')
  const pricevalue = findById(metadata, 'pricevalue')
  const pricesavings = findById(metadata, 'pricesavings')
  const paymentmethod = findById(metadata, 'paymentmethod')
  const helpertext = findById(metadata, 'helpertext')

  let autoDetection = workflowState['auto-detection'];
  const planDetails = workflowState['GetQuoteResponse']
  const quoteOffer = workflowState['quote-offer']
  const deviceDetection = workflowState['device-detection']
  const ManualSelection = workflowState['manual-detection']
  const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection

  const isNewPhone = detectionData?.isNewPhone
  const selectedPlanName = quoteOffer?.selectedPlanName || 'Lite'
  const PaymentTanure = quoteOffer?.selectedPaymentMethod == 'Annual' ? 'YEARLY' : 'MONTHLY'
  let discount = detectionData?.discountApplied || [];
  let otherDiscount = discount?.filter((v) => v !== 'DIGITAL PROMOTIONAL DISCOUNT' && v !== 'NEWPH_DISC')
  console.log(planDetails, selectedPlanName, isNewPhone, otherDiscount)
  let monthlyFee = getPrice('MONTHLY', planDetails[selectedPlanName?.toUpperCase()], isNewPhone, otherDiscount[0])
  let yearlyFee = getPrice('YEARLY', planDetails[selectedPlanName?.toUpperCase()], isNewPhone, otherDiscount[0])
  const price = PaymentTanure === 'MONTHLY' ? monthlyFee : yearlyFee
  // const monthlyPriceForYearlyFee = PaymentTanure === 'YEARLY' ? (Number(yearlyFee) / 12)?.toFixed(2)?.toString() : ''
  const monthlyPriceYearFee = (Number(monthlyFee) * 12)?.toFixed(2)?.toString()
  const formattedmonthlyPriceYearFee = monthlyFee.indexOf('.') > -1 ? monthlyFee.split('.') : [monthlyFee, '00']
  let month_yr_desimalNumber = formattedmonthlyPriceYearFee[1] && formattedmonthlyPriceYearFee[1]
  month_yr_desimalNumber = month_yr_desimalNumber ? (month_yr_desimalNumber.length === 1 ? month_yr_desimalNumber + '0' : month_yr_desimalNumber) : '00'

  const monthlyPriceForYearlyFee = (Number(yearlyFee) / 12)?.toFixed(2)?.toString()
  const formattedPrice = price.indexOf('.') > -1 ? price.split('.') : [price, '00']

  const formattedMonthlyPrice = monthlyFee.indexOf('.') > -1 ? monthlyFee.split('.') : [monthlyFee, '00']
  let month_desimalNumber = formattedMonthlyPrice[1] && formattedMonthlyPrice[1]
  month_desimalNumber = month_desimalNumber ? (month_desimalNumber.length === 1 ? month_desimalNumber + '0' : month_desimalNumber) : '00'

  const formattedYearlyPrice = yearlyFee.indexOf('.') > -1 ? yearlyFee.split('.') : [yearlyFee, '00']
  let year_desimalNumber = formattedYearlyPrice[1] && formattedYearlyPrice[1]
  year_desimalNumber = year_desimalNumber ? (year_desimalNumber.length === 1 ? year_desimalNumber + '0' : year_desimalNumber) : '00'

  const formatMonthlyForYearlyFee = monthlyPriceForYearlyFee.indexOf('.') > -1 ? monthlyPriceForYearlyFee.split('.') : [monthlyPriceForYearlyFee, '00']
  let monPriceForYr_desimalNumber = formatMonthlyForYearlyFee[1] && formatMonthlyForYearlyFee[1]
  monPriceForYr_desimalNumber = monPriceForYr_desimalNumber ? (monPriceForYr_desimalNumber.length === 1 ? monPriceForYr_desimalNumber + '0' : monPriceForYr_desimalNumber) : '00'

  let desimalNumber = formattedPrice[1] && formattedPrice[1]
  desimalNumber = desimalNumber ? (desimalNumber.length === 1 ? desimalNumber + '0' : desimalNumber) : '00'

  let savingsValue = (PaymentTanure === 'MONTHLY' ? Number(planDetails[selectedPlanName?.toUpperCase()]?.enrollmentFee) - Number(monthlyFee) : Number(planDetails[selectedPlanName?.toUpperCase()]?.yearlyEnrollmentFee) - Number(yearlyFee))

  const setPaymentMethodData = (d) => {
    let newWFState = {
      ...workflowState,
      ...{
        'quote-offer': {
          ...workflowState['quote-offer'],
          ...{ selectedPaymentMethod: d }
        }
      }
    }
    updateState(newWFState)
  }
  let showMsg = ''
  // if (state?.promoData) {
  //   if (!state?.promoData?.isReferal) {
  //     if (isNewPhone) {
  //       if (PaymentTanure === 'MONTHLY') showMsg = `*Includes promo: <b>${state.promoCode}</b> and new phone discount`
  //       else showMsg = `*Includes promo: <b>${state.promoCode}</b>, annual and new phone discount`
  //     } else {
  //       if (PaymentTanure === 'YEARLY') showMsg = `*Includes promo: <b>${state.promoCode}</b> and annual discount`
  //       else showMsg = `*Includes promo: <b>${state.promoCode}</b> discount`
  //     }
  //   } else {
  //     if (isNewPhone) {
  //       if (PaymentTanure === 'MONTHLY') showMsg = '*Includes new phone discount'
  //       else showMsg = '*Includes new phone and annual discount'
  //     } else {
  //       if (PaymentTanure === 'YEARLY') showMsg = '*Includes annual discount'
  //     }
  //   }
  // } else {
  if (isNewPhone) {
    if (PaymentTanure === 'MONTHLY') showMsg = '*Includes new phone discount'
    else showMsg = '*Includes new phone and annual discount'
  } else {
    if (PaymentTanure === 'YEARLY') showMsg = '*Includes annual discount'
  }
  // }
  pricetext.args = {
    ...pricetext.args,
    content: `${quoteOffer?.selectedPlanName} Plan price`,
    justification: `text-left ${currentStep == 'registeration' ? 'hidden' : 'block'}`
  }
  helpertext.args = {
    ...helpertext.args,
    content: showMsg,
  }
  pricevalue.args = {
    ...pricevalue.args,
    content: `$${PaymentTanure === 'MONTHLY' ? (month_desimalNumber && `${formattedMonthlyPrice[0]}.${month_desimalNumber}${month_desimalNumber ? '' : ''}`) : (monPriceForYr_desimalNumber && `${formattedYearlyPrice[0]}.${year_desimalNumber}${year_desimalNumber ? '' : ''}`)}${isNewPhone ? '*' : ''}`,

  }
  pricesavings.args = {
    ...pricesavings.args,
    content: savingsValue > 0 ? `${quoteOffer?.selectedPaymentMethod} Savings: $${savingsValue?.toFixed(2)}` : '',
  }
  paymentmethod.args = {
    ...paymentmethod.args,
    value: quoteOffer?.selectedPaymentMethod || 'Annual',
    onSelect: setPaymentMethodData
  }
  return <Stack orientation='vertical' metadata={metadata} />
}

export default PriceSection
