import React from "react";
import { Routes, Route, Navigate} from 'react-router-dom'
import {WorkflowProvider} from "./workflow-context";

const Workflow = ({getNextStep, initialStep, initialization, children}) => {

  return (
      <WorkflowProvider getNextStep={getNextStep} initialization={initialization} initialStep={initialStep}>
          <Routes>
            {children}
            <Route path="/" element={<Navigate to={initialStep}/>} />
          </Routes>
      </WorkflowProvider>
  );
}

export default Workflow;
