import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '../image/image';

export const List = ({
  orientation,
  listStyle,
  options,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  itemClasses,
  image,
  ...props
}) => {
  const _listStyle = listStyle ? `list-${listStyle}` : image ? '' : "list-disc";

  return (
    <div className={`${fontClasses} ${dimensionClasses} ${paddingClasses} ${marginClasses} ${colorClasses}`}>
      <ul
        className={`flex ${_listStyle} ${orientation === "vertical" ? "flex-col" : "flex-row"
          } pl-4`}
      >
        {options.map((item) => {
          return <li key={item} className={` ${image ? 'flex' : ''} ${itemClasses}`}>
            {image && <span className="inline-block mr-1"><Image image={image} /></span>}
            <span>{item}</span></li>;
        })}
      </ul>
    </div>
  );
};

List.propTypes = {
  /**
   * Orientation describes how list items will be rendered vertically or horizontally
   */
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  listStyle: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  image: PropTypes.func
};

List.defaultProps = {
  orientation: "vertical",
  marginClasses: "mb-5",
  fontClasses: "text-sm",
  image: undefined
};
