import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '../image/image'

export const Radio = ({
  options,
  name,
  value,
  orientation,
  onSelect,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  itemClasses,
  labelClasses,
  hasImage,
  imageName,
  ...props
}) => {
  return (
    <div
      className={`${fontClasses} ${dimensionClasses} ${paddingClasses} ${marginClasses} ${colorClasses} flex ${
        orientation === 'vertical' ? 'flex-col' : 'flex-row'
      } `}
    >
      {options.map(item => {
        return (
          <div className={`${itemClasses} flex`} key={item.value}>
            <input
              type='radio'
              id={item.value}
              name={name}
              className='p-1 my-auto'
              value={item.value}
              checked={value === item.value ? true : false}
              onChange={e => {
                onSelect(e.target.value)
              }}
            />
            {hasImage && (
              <div className='ml-2 pl-2 mr-1'>
                <Image image={item.imageName} />
              </div>
            )}
            <div className={`my-auto px-4 ${labelClasses}`}>
            <label
              htmlFor={item.value}
              dangerouslySetInnerHTML={{ __html: item.label }}
            />
            { item.selectedDisplay && item.value === value && <p className='-mt-1.5' dangerouslySetInnerHTML={{ __html: item.selectedDisplay }}></p> }
            </div>
          </div>
        )
      })}
    </div>
  )
}

Radio.propTypes = {
  /**
   * Array of Options to display radio options
   */
  options: PropTypes.arrayOf(PropTypes.object),
  /**
   * Name of the Radio Group
   */
  name: PropTypes.string,
  /**
   * Value of the selected radio option
   */
  value: PropTypes.string,
  /**
   * Orientation describes how radio button will be rendered vertically or horizontally
   */
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  /**
   * onSelect event handler for on Selection of Option
   */
  onSelect: PropTypes.func,
  fontClasses: PropTypes.string,
  dimensionClasses: PropTypes.string,
  colorClasses: PropTypes.string,
  borderClasses: PropTypes.string,
  paddingClasses: PropTypes.string,
  marginClasses: PropTypes.string,
  itemClasses: PropTypes.string,
  hasImage: PropTypes.bool,
  imageName: PropTypes.string
}

Radio.defaultProps = {
  options: [],
  type: 'options',
  orientation: 'vertical',
  hasImage: false,  
}
