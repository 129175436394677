/* eslint-disable max-len */
import React from 'react'

const icon = ({ isSelected, className }) => (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.16666 6.66601C1.16666 5.56144 2.06209 4.66602 3.16666 4.66602H12.5C13.6046 4.66602 14.5 5.56145 14.5 6.66602V29.3327C14.5 30.4373 13.6046 31.3327 12.5 31.3327H3.16665C2.06209 31.3327 1.16666 30.4373 1.16666 29.3327V6.66601Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M14.5 26H1.16666" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.83333 28.332C8.01743 28.332 8.16667 28.4813 8.16667 28.6654V28.6654C8.16667 28.8495 8.01743 28.9987 7.83333 28.9987V28.9987C7.64924 28.9987 7.5 28.8495 7.5 28.6654V28.6654C7.5 28.4813 7.64924 28.332 7.83333 28.332" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.8333 1.99935C11.8333 1.26297 12.4303 0.666016 13.1666 0.666016H30.5C31.2364 0.666016 31.8333 1.26297 31.8333 1.99935V17.9993C31.8333 18.7357 31.2364 19.3327 30.5 19.3327H17.8333" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.1667 31.332L25.1667 23.332" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.8333 23.332H25.1666V28.6654" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

export default icon
