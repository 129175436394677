/* eslint-disable max-len */
import React from 'react'

const icon = ({ className, click, fillColor }) => (
    <svg className={className} onClick={click} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.75592 3.57715L16.4226 15.2438L15.2441 16.4223L3.57741 4.75566L4.75592 3.57715Z" fill={fillColor ? fillColor : "#6E767D"} />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.4226 4.75566L4.75592 16.4223L3.57741 15.2438L15.2441 3.57715L16.4226 4.75566Z" fill={fillColor ? fillColor : "#6E767D"} />
    </svg>

)

export default icon
