import React from 'react'
import PropTypes from 'prop-types'
import IconGoogle from '../../icons/icon-google'
import IconFacebook from '../../icons/icon-fb'

export const GoogleButton = props => {
  const { onClick, marginClasses } = props
  return (
    <div
      className={`py-1 bg-white border border-light-gray rounded-md md:w-36 w-full cursor-pointer ${marginClasses}`}
      onClick={() => onClick('Google')}
    >
      <div className='flex flex-row items-center justify-center'>
        <IconGoogle className={`h-7`} />
        <span className='mx-2 md:text-sm lg:text-base'>Google</span>
      </div>
    </div>
  )
}

export const FacebookButton = props => {
  const { onClick, marginClasses } = props
  return (
    <div
      className={`py-1 bg-white border border-light-gray rounded-md md:w-36 w-full cursor-pointer ${marginClasses}`}
      id='btn-buyf-google-login'
      onClick={() => onClick('Facebook')}
    >
      <div className='flex flex-row items-center justify-center'>
        <IconFacebook className={`h-7`} />
        <span className='mx-2 md:text-sm lg:text-base'>Facebook</span>
      </div>
    </div>
  )
}

export const Button = ({
  enabled,
  label,
  onClick,
  fontClasses,
  dimensionClasses,
  colorClasses,
  borderClasses,
  paddingClasses,
  marginClasses,
  style,
  ...props
}) => {
  return (
    <button
      type='button'
      style={style}
      disabled={!enabled}
      className={`focus:outline-none ${fontClasses} ${paddingClasses} ${marginClasses} ${dimensionClasses} ${borderClasses} ${colorClasses}`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.propTypes = {
  /**
   * enable: Indicates if button is enabled or disabled
   */
  enabled: PropTypes.bool,
  /**
   * label: Text to be displayed on button
   */
  label: PropTypes.string,
  /**
   * onClick: On Click event handler for button
   */
  onClick: PropTypes.func,
  /**
   * fontClasses: Classes to manage fonts of the control
   */
  fontClasses: PropTypes.string,
  /**
   * dimensionClasses: Classes to manage dimensions of the control
   */
  dimensionClasses: PropTypes.string,
  /**
   * colorClasses: Classes to manage foreground and background colors of the control
   */
  colorClasses: PropTypes.string,
  /**
   * borderClasses: Classes to manage border of the control
   */
  borderClasses: PropTypes.string,
  /**
   * paddingClasses: Classes to manage padding of the control
   */
  paddingClasses: PropTypes.string,
  /**
   * marginClasses: Classes to manage margin of the control
   */
  marginClasses: PropTypes.string
}

Button.defaultProps = {
  enabled: true,
  label: 'Button Label',
  onClick: undefined,
  fontClasses: 'text-base',
  dimensionClasses: '',
  colorClasses:
    'bg-purple-600 disabled:bg-gray-300 hover:bg-purple-200 text-white disabled:text-gray-400',
  borderClasses: 'rounded-md',
  paddingClasses: 'p-1 py-2 px-3',
  marginClasses: ''
}
