export const samsungModels = [
  {
    brand: "Samsung",
    model: "Galaxy S8+",
    name: "Samsung Galaxy S8 Plus",
    width: 1081.5,
    height: 2220.75,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S8",
    name: "Samsung Galaxy S8",
    width: 1080,
    height: 2220,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy Note Pro 12.2",
    name: "Samsung Galaxy Note Pro 12.2",
    width: 2560,
    height: 1600,
    pixelRatio: 247
  },
  {
    brand: "Samsung",
    model: "Galaxy S6",
    name: "Samsung Galaxy S6",
    width: 1440,
    height: 2560,
    pixelRatio: 577
  },
  {
    brand: "Samsung",
    model: "Galaxy S6 Edge+",
    name: "Samsung Galaxy S6 Edge+",
    width: 1440,
    height: 2560,
    pixelRatio: 518
  },
  {
    brand: "Samsung",
    model: "Galaxy Note5",
    name: "Samsung Galaxy Note5",
    width: 1440,
    height: 2560,
    pixelRatio: 518
  },
  {
    brand: "Samsung",
    model: "Galaxy Note4",
    name: "Samsung Galaxy Note4",
    width: 1440,
    height: 2560,
    pixelRatio: 515
  },
  {
    brand: "Samsung",
    model: "Galaxy S7",
    name: "Samsung Galaxy S7",
    width: 1440,
    height: 2560,
    pixelRatio: 577
  },
  {
    brand: "Samsung",
    model: "Galaxy S7 Edge",
    name: "Samsung Galaxy S7 Edge",
    width: 1440,
    height: 2560,
    pixelRatio: 534
  },
  {
    brand: "Samsung",
    model: "Galaxy S20+",
    name: "Samsung Galaxy S20+",
    width: 1080,
    height: 2401.875,
    pixelRatio: 2.8125
  },
  {
    brand: "Samsung",
    model: "Galaxy Note20 Ultra",
    name: "Samsung Galaxy Note 20 Ultra",
    width: 1081.5,
    height: 2317.875,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S20 Ultra",
    name: "Samsung Galaxy S20 Ultra",
    width: 1081.5,
    height: 2401.875,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S20 Ultra",
    name: "Samsung Galaxy S20 Ultra 5G",
    width: 1081.5,
    height: 2401.875,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy Note10+",
    name: "Samsung Galaxy Note 10+",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 2.265
  },
  {
    brand: "Samsung",
    model: "Galaxy S5",
    name: "Samsung Galaxy S5",
    width: 1080,
    height: 1920,
    pixelRatio: 432
  },
  {
    brand: "Samsung",
    model: "Galaxy SIV",
    name: "Samsung Galaxy SIV",
    width: 1080,
    height: 1920,
    pixelRatio: 441
  },
  {
    brand: "Samsung",
    model: "Galaxy SV",
    name: "Samsung Galaxy SV",
    width: 1080,
    height: 1920,
    pixelRatio: 432
  },
  {
    brand: "Samsung",
    model: "Galaxy S10E",
    name: "Samsung Galaxy S10E",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 394
  },
  {
    brand: "Samsung",
    model: "Galaxy S10",
    name: "Samsung Galaxy S10",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 394
  },
  {
    brand: "Samsung",
    model: "Galaxy A71",
    name: "Samsung Galaxy A71",
    width: 1080,
    height: 2400,
    pixelRatio: 393
  },
  {
    brand: "Samsung",
    model: "Galaxy A51",
    name: "Samsung Galaxy A51",
    width: 1081.5,
    height: 2401.875,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy A70",
    name: "Samsung Galaxy A70",
    width: 1081.5,
    height: 2401.875,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy A30",
    name: "Samsung Galaxy A30",
    width: 1080,
    height: 2340,
    pixelRatio: 403
  },
  {
    brand: "Samsung",
    model: "Galaxy A50s",
    name: "Samsung Galaxy A50s",
    width: 1080,
    height: 2340,
    pixelRatio: 403
  },
  {
    brand: "Samsung",
    model: "Galaxy A50",
    name: "Samsung Galaxy A50",
    width: 1080,
    height: 2340,
    pixelRatio: 403
  },
  {
    brand: "Samsung",
    model: "Galaxy Note20",
    name: "Samsung Galaxy Note 20",
    width: 1080,
    height: 2401.875,
    pixelRatio: 2.8125
  },
  {
    brand: "Samsung",
    model: "Galaxy Note10",
    name: "Samsung Galaxy Note 10",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy J7 Prime2",
    name: "Samsung Galaxy J7 Prime2",
    width: 1080,
    height: 1920,
    pixelRatio: 401
  },
  {
    brand: "Samsung",
    model: "Galaxy J7 Prime",
    name: "Samsung Galaxy J7 Prime",
    width: 1080,
    height: 1920,
    pixelRatio: 401
  },
  {
    brand: "Samsung",
    model: "Galaxy Note",
    name: "Samsung Galaxy Note",
    width: 800,
    height: 1280,
    pixelRatio: 285
  },
  {
    brand: "Samsung",
    model: "A5",
    name: "Samsung A5",
    width: 720,
    height: 1280,
    pixelRatio: 294
  },
  {
    brand: "Samsung",
    model: "Galaxy J7",
    name: "Samsung Galaxy J7",
    width: 720,
    height: 1280,
    pixelRatio: 267
  },
  {
    brand: "Samsung",
    model: "Galaxy Nexus",
    name: "Samsung Galaxy Nexus",
    width: 720,
    height: 1280,
    pixelRatio: 316
  },
  {
    brand: "Samsung",
    model: "Galaxy Note II",
    name: "Samsung Galaxy Note II",
    width: 720,
    height: 1280,
    pixelRatio: 267
  },
  {
    brand: "Samsung",
    model: "Galaxy SIII",
    name: "Samsung Galaxy SIII",
    width: 720,
    height: 1280,
    pixelRatio: 306
  },
  {
    brand: "Samsung",
    model: "Z3",
    name: "Samsung Z3",
    width: 720,
    height: 1280,
    pixelRatio: 294
  },
  {
    brand: "Samsung",
    model: "Galaxy S",
    name: "Samsung Galaxy S",
    width: 480,
    height: 800,
    pixelRatio: 233
  },
  {
    brand: "Samsung",
    model: "Galaxy S Plus",
    name: "Samsung Galaxy S Plus",
    width: 480,
    height: 800,
    pixelRatio: 233
  },
  {
    brand: "Samsung",
    model: "Galaxy SII",
    name: "Samsung Galaxy SII",
    width: 480,
    height: 800,
    pixelRatio: 219
  },
  {
    brand: "Samsung",
    model: "Nexus S",
    name: "Samsung Nexus S",
    width: 480,
    height: 800,
    pixelRatio: 235
  },
  {
    brand: "Samsung",
    model: "Galaxy M21",
    name: "Samsung Galaxy M21",
    width: 2340,
    height: 1080,
    pixelRatio: 235
  },
  {
    brand: "Samsung",
    model: "Galaxy M40",
    name: "Samsung Galaxy M40",
    width: 2340,
    height: 1080,
    pixelRatio: 235
  },
  {
    brand: "Samsung",
    model: "Galaxy S9",
    name: "Samsung Galaxy S9",
    width: 1080,
    height: 2220,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S9 Plus",
    name: "Samsung Galaxy S9 Plus",
    width: 1081.5,
    height: 2220.75,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy Note 8",
    name: "Samsung Galaxy Note 8",
    width: 1081.5,
    height: 2220.75,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy Note 9",
    name: "Samsung Galaxy Note 9",
    width: 1081.5,
    height: 2220.75,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S10 Plus",
    name: "Samsung Galaxy S10+",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 2.265
  },
  {
    brand: "Samsung",
    model: "Galaxy S20",
    name: "Samsung Galaxy S20",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S20 FE",
    name: "Samsung Galaxy S20 FE",
    width: 1080,
    height: 2400,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S21",
    name: "Samsung Galaxy S21",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S21",
    name: "Samsung Galaxy S21 Plus",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S21",
    name: "Samsung Galaxy S21 Ultra",
    width: 1400,
    height: 3200,
    pixelRatio: 3.75
  },
  {
    brand: "Samsung",
    model: "Galaxy A90",
    name: "Samsung Galaxy A90",
    width: 1080,
    height: 2400,
    pixelRatio: 393
  },
  {
    brand: "Samsung",
    model: "Galaxy A31",
    name: "Samsung Galaxy A31",
    width: 1080,
    height: 2400,
    pixelRatio: 403
  },
  {
    brand: "Samsung",
    model: "Galaxy A21s",
    name: "Samsung Galaxy A21s",
    width: 720,
    height: 1600,
    pixelRatio: 403
  },
  {
    brand: "Samsung",
    model: "Galaxy Note20",
    name: "Samsung Galaxy Note20 5G",
    width: 1080,
    height: 2401.875,
    pixelRatio: 2.8125
  },
  {
    brand: "Samsung",
    model: "Galaxy Note20 Ultra",
    name: "Samsung Galaxy Note20 Ultra 5G",
    width: 1081.5,
    height: 2317.875,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S20 5G",
    name: "Samsung Galaxy S20 5G",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S20 FE 5G",
    name: "Samsung Galaxy S20 FE 5G",
    width: 1080,
    height: 2400,
    pixelRatio: 2.625
  },
  {
    brand: "Samsung",
    model: "Galaxy S20+ 5G",
    name: "Samsung Galaxy S20+ 5G",
    width: 1080,
    height: 2401.875,
    pixelRatio: 2.8125
  },
  {
    brand: "Samsung",
    model: "Galaxy Note10+ 5G",
    name: "Samsung Galaxy Note 10+ 5G",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 2.265
  },
  {
    brand: "Samsung",
    model: "Galaxy S10 5G",
    name: "Samsung Galaxy S10 5G",
    width: 1081.5,
    height: 2281.125,
    pixelRatio: 394
  },
  {
    brand: "Samsung",
    model: "Galaxy S21 5G",
    name: "Samsung Galaxy S21 5G",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S21 Plus 5G",
    name: "Samsung Galaxy S21 Plus 5G",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  },
  {
    brand: "Samsung",
    model: "Galaxy S21 Ultra 5G",
    name: "Samsung Galaxy S21 Ultra 5G",
    width: 1400,
    height: 3200,
    pixelRatio: 3.75
  },
  {
    brand: "Samsung",
    model: "Galaxy S22 5G",
    name: "Samsung Galaxy S22 5G",
    width: 1080,
    height: 2340,
    pixelRatio: 2.62
  },
  {
    brand: "Samsung",
    model: "Galaxy S22 Ultra 5G",
    name: "Samsung Galaxy S22 Ultra 5G",
    width: 1440,
    height: 3088,
    pixelRatio: 3.33
  },
  {
    brand: "Samsung",
    model: "Galaxy S22+ 5G",
    name: "Samsung Galaxy S22+ 5G",
    width: 1080,
    height: 2340,
    pixelRatio: 2.83
  },
  {
    brand: "Samsung",
    model: "Galaxy S21 FE 5G",
    name: "Samsung Galaxy S21 FE 5G",
    width: 1080,
    height: 2400,
    pixelRatio: 3
  }
]
