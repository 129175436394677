
import { useContext } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import IconAsurionMobilePlus from '../../icons/icon-AsurionMobilePlus'
import METADATA from '../stack/metadata2.json'
import { Stack } from '../stack/stack'
import { findById } from "./helper";
import { useNavigate } from "react-router-dom";

/* this page is for testing all routes */

function TestLink() {
    const navigate = useNavigate();

    const [currentStep, completeCurrentStep] = useContext(WorkflowContext)

    return (
        <div>
            <ul className=" p-3">
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/get-quote")} >get quote </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/login")} >Login </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/email-otp")} >Email OTP </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/registeration")} >Registeration </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/nationalid-upload")} >NationalidUpload </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/nationalid-skipped")} >NationalidUploadSkip </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/receipt-upload")} >ReceiptidUpload </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/receipt-skipped")} >ReceiptidUploadSkip </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/video-capture")} >VideoCapture </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/video-notsure")} >VideoNotSure </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/bypass-imei-instructions")} >BypassIMEIInstructions </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-instructions")} >IMEIInstructions </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-upload-success")} >IMEIUploadSuccess </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-upload-success/2")} >IMEIUploadSuccess2 </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-video")} >IMEIVideo </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-not-read")} >IMEINotRead </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-not-found")} >IMEINotFound </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-different-device")} >IMEIDifferentDevice </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-device-unsupported")} >IMEIDeviceUnsupported </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/imei-mismatch-contactus")} >IMEIMismatchContactUs </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/policy-details")} >PolicyDetails </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/proceed-policy")} >ProceedPolicy </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/letsgetprepared")} >LetsGetPrepared </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/letsgetprepared/2")} >LetsGetPrepared2 </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/letsgetprepared/3")} >LetsGetPrepared3 </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/two-phone")} >TwoPhone </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/two-phone/qr-code")} >QRCode </li>
                <li className="cursor-pointer pb-2 underline" onClick={() => navigate("/two-phone/device-linked")} >DeviceLinked </li>
            </ul>
        </div>
    )
}

export default TestLink;