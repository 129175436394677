import { useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../workflow/workflow-context"
import IconTick from '../../icons/icon-tick'
import { Stack } from '../stack/stack'
import { findById, validateFileType } from "./helper";
import API from "../../services";
import ActionTypes from "../../ActionTypes";
import axios from "axios";
import Promise from 'bluebird'

function ReceiptidUpload() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )

  const metadata = workflowState['config']?.metadata['receiptUpload']
  const [selectedFile, setSelectedFile] = useState()
  const uploadFl = findById(metadata, 'upload')
  const btnSkip = findById(metadata, 'btnSkip')
  const btnNext = findById(metadata, 'btnNext')

  const reciptProvider = findById(metadata, 'reciptProviderId')
  const quoteOffer = workflowState['quote-offer']
  let receiptUploadDetails = workflowState['receipt-upload']
  let agreementDetails = workflowState['CreateAgreementResponse'];
  let autoDetection = workflowState['auto-detection'];
  const deviceDetection = workflowState['device-detection']
  const [selectedReciptProvider, setSelectedReciptProvider] = useState()

  const [dataCleared, setDataCleared] = useState(false)
  const [fileUploadError, setFileUploadError] = useState()
  const [fileName, setFileName] = useState(null)
  const [loaded, setProgressBarValue] = useState(0)
  const [moreThan15Mb, setMoreThan15Mb] = useState(false)
  const [enableUploadBtn, setEnableUploadButton] = useState()
  const selectedPlanName = quoteOffer?.selectedPlanName
  const navigateTo = 'VideoCapture'

  const list = findById(metadata, 'list')

  let clientFeatures = workflowState['config']?.ClientFeatures
  let ReceiptProviders = clientFeatures.filter(c => c.AttributeName == 'RECEIPT_PROVIDER' && c.AttributeValue !== "Other / Not Listed" && c.ClientOfferName == selectedPlanName?.toUpperCase() && c.AttributeValue).map(ele => ele.AttributeValue)
  let _receiptProvider = ReceiptProviders.map((ele) => {
    return {
      label: ele,
      value: ele,
    }
  })
  _receiptProvider.push({
    label: 'Other / Not Listed',
    value: 'Other / Not Listed',
  })

  let handleClick = (btnText) => {
    let _vhcTags = workflowState?.vhcTags ? workflowState?.vhcTags?.filter((v) => v !== 'receipt') : []
    _vhcTags.push('receipt', 'newPhone')
    if (selectedReciptProvider == 'Other / Not Listed') {
      _vhcTags.push('unknownReceipt')
    }
    updateState({ ...workflowState, vhcTags: _vhcTags });
    let canBypassVhc = clientFeatures && clientFeatures.find(c => c.AttributeName == 'BYPASS_VHC' && c.AttributeValue == 'Y')
    let nextPath = canBypassVhc ? 'bypassImeissInstructions' : 'videoCapture';
    updateAgreement(btnNext, nextPath)
  }
  let clearReceiptDetails = () => {
    updateState({
      ...workflowState, [currentStep]: {
        ...workflowState[currentStep],
        selectedReceiptUpload: [],
        selectedFile: [],
        enableUploadBtn: true,
      }
    });
  }
  useEffect(() => {
    setEnableUploadButton(false)
    clearReceiptDetails()
    setFileUploadError(null)
    setProgressBarValue(0)
    setDataCleared(true)
  }, [])
  useEffect(() => {
    if (receiptUploadDetails?.selectedReceiptUpload?.length && receiptUploadDetails?.PresignUrlResult) {
      setDataCleared(true)
      let UpdateAgreementRequest = {
        CreateAgreementActivityRequest: {
          AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
          ReceiptProvider: selectedReciptProvider,
          ReceiptBucket: receiptUploadDetails?.PresignUrlResult?.[0]?.Bucket,
          ReceiptPath: receiptUploadDetails?.PresignUrlResult?.[0]?.Path1,
        },
      }
      API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
        updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        // return completeCurrentStep(currentStep, {
        //     ...workflowState[currentStep],
        //     navigateTo: navigateTo,
        // })
      })
    }
  }, [receiptUploadDetails?.selectedReceiptUpload, receiptUploadDetails?.PresignUrlResult])

  const skipReceiptUpload = (btnText) => {
    if (deviceDetection?.auto && autoDetection.AutoDetected) {
      updateState({
        ...workflowState, 'auto-detection': {
          ...workflowState['auto-detection'],
          isNewPhone: false,
        }
      });
    } else {
      updateState({
        ...workflowState, 'manual-detection': {
          ...workflowState['manual-detection'],
          isNewPhone: false,
        }
      });
    }
    updateAgreement(btnText)
  }
  const updateAgreement = (btnText, nextPath) => {
    let UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
        AgreementRequestStatus: btnText === 'Skip' ? 'RECEIPT_SKIPPED' : 'RECEIPT_UPLOAD',
      }
    }
    API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
      updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
      return completeCurrentStep(currentStep, {
        ReceiptUploadSkipped: btnText === 'Skip' ? true : false,
        navigateTo: btnText === 'Skip' ? 'ReceiptUploadSkipped' : nextPath,
        selectedReciptProvider: selectedReciptProvider
      })
    })
  }
  const uploadToS3 = (url, document, formData) => {
    return axios({
      method: 'put',
      url: url,
      headers: {
        Accept: 'application/json',
        'Content-Type': document.type,
      },
      data: document,
      onUploadProgress: (progressEvent) => {
        const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        setProgressBarValue(progress)
      },
      formData: formData,
    })
      .then((response) => {
        setEnableUploadButton(false)
        let UpdateAgreementRequest = {
          CreateAgreementActivityRequest: {
            AgreementRequestId: agreementDetails && agreementDetails.AgreementRequestId,
            PhotoIdBucket: receiptUploadDetails?.PresignUrlResult?.[0]?.Bucket,
            PhotoIdPath: receiptUploadDetails?.PresignUrlResult?.[0]?.Path1,
          },
        }
        API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
          updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
        })
      })
      .catch((error) => {
        // actions.unSetLoader()
      })
  }

  let handleChange = (e) => {
    setDataCleared(false)
    if (!validateFileType(e.target.files[0].name)) {
      clearReceiptDetails()
      setFileUploadError('Invalid file format')
    } else {
      setFileUploadError(null)
      setFileName(e.target.files[0].name)
      updateState({
        ...workflowState, [currentStep]: {
          ...workflowState[currentStep],
          selectedReceiptUpload: [...e.target.files]
        }
      });
    }
  }

  let prependReceiptPrefix = (path) => {
    return `Receipt_${path}`
  }

  useEffect(() => {
    if (receiptUploadDetails?.selectedReceiptUpload && receiptUploadDetails?.selectedReceiptUpload[0]) {
      let fileSizeInKB = receiptUploadDetails?.selectedReceiptUpload[0]?.size / 1024
      if (fileSizeInKB && fileSizeInKB <= 102400) {
        setMoreThan15Mb(false)
        if (receiptUploadDetails?.selectedReceiptUpload && receiptUploadDetails?.selectedReceiptUpload.length > 0) {
          getPresignUrl()
        }
      } else {
        setMoreThan15Mb(true)
        clearReceiptDetails()
      }
    }
  }, [receiptUploadDetails?.selectedReceiptUpload])

  useEffect(() => {
    if (receiptUploadDetails?.PresignUrlResult && receiptUploadDetails?.PresignUrlResult.length > 0) {
      Promise.map(receiptUploadDetails?.selectedReceiptUpload, (document, index) => {
        const formData = new FormData()
        formData.append('file', document)
        let filterUrl =
          receiptUploadDetails?.PresignUrlResult &&
          receiptUploadDetails?.PresignUrlResult.filter((u) => u.Path1.split('/')[2] === prependReceiptPrefix(document.name))
        return uploadToS3(filterUrl[0].PreSignedURL, document, formData)
      })
    }
  }, [receiptUploadDetails?.PresignUrlResult])

  const getPresignUrl = (e) => {
    let files = receiptUploadDetails?.selectedReceiptUpload.map((file) => {
      return {
        ClaimId: agreementDetails?.AgreementRequestId || '111111111',
        FileName: prependReceiptPrefix(file.name),
        FolderType: 'buyflow',
      }
    })
    let getPresignUrlRequest = {
      FileUploadRequest: {
        Files: files,
      },
    }
    API[ActionTypes.GET_PRESIGN_URL_FOR_UPLOAD_REQUEST](getPresignUrlRequest).then(data => {
      updateState({
        ...workflowState, getPresignUrlResponse: data.data.Files, [currentStep]: {
          ...workflowState[currentStep],
          PresignUrlResult: data.data.Files
        }
      });
    })
    // actions.getPresignUrlForUpload({ getPresignUrlRequest: getPresignUrlRequest, id: 'upload-receipt' })
  }

  const removeFile = () => {
    setEnableUploadButton(true)
    setProgressBarValue(0)
    clearReceiptDetails()
  }
  list.args = {
    ...list.args,
    image: IconTick
  }
  reciptProvider.args = {
    ...reciptProvider.args,
    onSelect: setSelectedReciptProvider,
    options: _receiptProvider,
    value: selectedReciptProvider
  }
  uploadFl.args = {
    ...uploadFl.args,
    handleChange: e => handleChange(e),
    selectedFile: receiptUploadDetails?.selectedReceiptUpload?.[0]?.name,
    progress: loaded,
    removeFile: removeFile,
    enabled: selectedReciptProvider != undefined ? true : false
  }
  btnSkip.args = {
    ...btnSkip.args,
    onClick: e => skipReceiptUpload('Skip'),
    marginClasses: `mb-4 ${loaded == 100 ? 'hidden' : 'block'}`,
  }
  btnNext.args = {
    ...btnNext.args,
    marginClasses: `mb-4 ${loaded == 100 ? 'block' : 'hidden'}`,
    onClick: e => handleClick('Next')
  }


  return <Stack orientation='vertical' metadata={metadata} />

}

export default ReceiptidUpload;