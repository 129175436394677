import Amplify from 'aws-amplify'

import { config } from './config'
// import chatConf from '../components/Chat/appsync/ChatConfig'

function decideSignInUrlFromSource(source) {
  switch (source) {
    case 'myAsurion':
      return config.cognito.myAsurionSignInUrl
    case 'buyNow':
      return config.cognito.buyNowSignInUrl
    case 'coolade':
      return config.cognito.cooladeSignInUrl
    // case 'standalone':
    //   return config.cognito.standaloneSignInUrl
    // case 'contactUs':
    //   return config.cognito.contactusSignInUrl
    // case 'contactUsComplaint':
    //   return config.cognito.contactusComplaintSignInUrl
    default:
  }
}

function initAmplifyConfig(source) {
  let signInUrl = decideSignInUrlFromSource(source)
  const oauth = {
    domain: config.cognito.domain,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: signInUrl,
    redirectSignOut: config.cognito.redirectSignOut,
    responseType: 'token',
  }

  Amplify.configure({
    // aws_appsync_graphqlEndpoint: chatConf.AppSync.EndPoint,
    // aws_appsync_region: chatConf.AppSync.Region,
    Analytics: {
      disabled: true, // OPTIONAL - disable Analytics if true
    },
    Auth: {
      oauth: oauth,
      identityPoolId: config.cognito.identityPoolId,
      region: config.cognito.region,
      userPoolId: config.cognito.userPoolId,
      userPoolWebClientId: config.cognito.userPoolWebClientId,
    },
    // ApiKey: chatConf.Twilio.ApiGatewayKey,
  })
}

export default initAmplifyConfig
