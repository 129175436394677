import { useContext, useState } from 'react'
import { WorkflowContext } from '../workflow/workflow-context'
import { Stack } from '../stack/stack'
import { findById } from '../enrollment-workflow/helper'
import LitePlan from './quote-offer/lite-plan'
import ClassicPlan from './quote-offer/classic-plan'
import PrestigePlan from './quote-offer/prestige-plan'
import { useEffect } from 'react'
import ComparePlan from './quote-offer/compare-plan'
import ActionTypes from '../../ActionTypes'
import API from '../../services'
function QuoteOffer() {
  const [currentStep, completeCurrentStep, workflowState, updateState] = useContext(
    WorkflowContext
  )
  const deviceDetection = workflowState['device-detection']
  const autoDetection = workflowState['auto-detection']
  const ManualSelection = workflowState['manual-detection']
  const quoteOffer = workflowState['quote-offer']
  const detectionData = deviceDetection?.auto && autoDetection.AutoDetected ? autoDetection : ManualSelection
  const metadata = workflowState['config']?.metadata['QuoteOffer']
  const headerText = findById(metadata, 'headerText')
  const tabContent = findById(metadata, 'tabContent')
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setRepairIsOpen] = useState(false)
  const [isOpenResolve, setResolveIsOpen] = useState(false)
  const [isOpenAdd, setAddIsOpen] = useState(false)
  const [isOpenExp, setExpIsOpen] = useState(false)
  const [isOpenLost, setLostIsOpen] = useState(false)
  const planData = workflowState['GetQuoteResponse']
  let agreementDetails = workflowState['CreateAgreementResponse'];

  const deviceName = detectionData?.AutoDetected ? `${detectionData.Model}, ${detectionData.Color} ${detectionData.Size}` :
    `${detectionData.Model}, ${detectionData.Color} ${detectionData.Size}`
  const Make = detectionData.Make;
  const Model = detectionData.Model;
  const selectedPlanName = quoteOffer?.selectedPlanName || 'Lite'
  const isNewPhone = detectionData.isNewPhone;
  let getOfferFeatures = (planDetails, planName, make) => {
    let d = [
      {
        offerName: 'Lite',
        cards: [
          {
            title: 'REPAIR',
            subtitle: '2 Screen repairs per 12 months',
            subsubtitle: `<ul class='list-disc ml-5'><li>Genuine repair or reimbursement</li><li>Screen Repair Excess $${planDetails['LITE']['Repairs']['surFee']}.</li></ul>`,
            description: `<br/>Cover for any Screen Damage to your phone. Send your phone to us or take it to your nearest authorised repairer and your screen will be repaired using genuine parts.
            <br/><br/>* Physical damage to your device’s screen. Does not include scratches, scrapes and other cosmetic damage that does not impact the device functionality.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
          {
            title: 'RESOLVE',
            subtitle: 'Knowledge Base access',
            subsubtitle: `<ul class='list-disc ml-5'><li>For all your tech support issues with your phone.</li></ul>`,
            description: `<br/>We give you access to the Asurion Knowledge Base, where you can find useful articles, advice and tips and tricks related to your phone.`,
            expanded: isOpenResolve,
            setIsOpen: setResolveIsOpen,
            isBorder: true,
          },
        ],
        keyPointsCards: [
          {
            title: 'Additional Screen Repairs',
            description:
              "The Lite Plan provides 2 screen repairs every 12 months. For greater protection, you can upgrade to <span class=''>Prestige</span> with unlimited screen repairs.",
            expanded: isOpenAdd,
            setIsOpen: setAddIsOpen,
            isBorder: false,
          },
          {
            title: 'Complete Device Replace',
            description:
              "If your phone is damaged beyond the screen, this is NOT covered under the <span class=''>Lite Plan</span>. Please consider the <span class=''>Classic</span> or <span class=''>Prestige</span> plans which give you the option of a “like for like” device exchange in the case of phone damage. <br/> <br/> A device replace is especially useful when a phone stops operating, is badly broken or suffers water damage.",
            expanded: isOpenExp,
            setIsOpen: setExpIsOpen,
            isBorder: false,
          },
          {
            title: 'Lost or Stolen',
            description:
              "The option of Lost or Stolen replacement is only available in the <span class=''>Prestige</span> Plan.",
            expanded: isOpenLost,
            setIsOpen: setLostIsOpen,
            isBorder: false,
          },
        ]
      },
      {
        offerName: 'Classic',
        cards: [
          {
            title: 'REPAIR',
            subtitle: '2 Screen repairs per 12 months',
            subsubtitle: `<ul class='list-disc ml-5'><li>Genuine repair or reimbursement</li><li>Screen Repair Excess $${planDetails['LITE']['Repairs']['surFee']}</li></ul>`,
            description: `<br/>Cover for any Screen Damage to your phone. Send your phone to us or take it to your nearest authorised repairer and your screen will be repaired using genuine parts.
            <br/><br/>* Physical damage to your device’s screen. Does not include scratches, scrapes and other cosmetic damage that does not impact the device functionality.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
          {
            title: 'REPLACE',
            subtitle: '1 Claim per 12 months',
            subsubtitle: `<ul class='list-disc ml-5'><li>"like for like" damage replacement</li><li>Replace Excess is $${planDetails['CLASSIC']['Swap']['swapFee']}</li></ul>`,
            description: `<br/>If your phone suffers Accidental Damage* we will provide you with a ‘like for like’ device replacement which may be a refurbished or new device of the same make, model and capacity. You must also return your damaged phone to us within 14 days, using the instructions and prepaid packaging we provide you.
            <br/><br/>* Any physical damage (including liquid damage) impairing the functionality of the device, caused by an unintended event.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
          {
            title: 'RESOLVE',
            subtitle: 'Virtual  Assistance<br/>',
            subsubtitle: `<ul class='list-disc ml-5'><li>For all your tech support issues with your phone.</li></ul>`,
            description: `<br/>We give you access to the Asurion Knowledge Base, where you can find useful articles, advice and tips and tricks related to your phone. Our AI Virtual Assistant also provides you with fast responses to your device questions any time of day.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
        ],
        keyPointsCards: [
          {
            title: 'Additional Screen Repairs',
            description:
              "The Classic Plan provides two screen repairs every 12 months. For greater protection, you can upgrade to the <span class=''>Prestige</span> plan which offers unlimited screen repair claims.",
            expanded: isOpenAdd,
            setIsOpen: setAddIsOpen,
            isBorder: false,
          },
          {
            title: 'Complete Device Replace',
            description:
              "If your phone is damaged beyond the screen, the <span class=''>Classic</span> plan provides only one Replace per year. For the best coverage, the <span class=''>Prestige</span> plan gives you 2 “like for like” replacements per 12 months.",
            expanded: isOpenAdd,
            setIsOpen: setAddIsOpen,
            isBorder: false,
          },
          {
            title: 'Lost or Stolen',
            description:
              "The option of Lost or Stolen replacement is only available in the <span class=''>Prestige</span> Plan.",
            expanded: isOpenAdd,
            setIsOpen: setAddIsOpen,
            isBorder: false,
          },
        ]
      },
      {
        offerName: 'Prestige',
        cards: [
          {
            title: 'REPAIR',
            subtitle: 'Unlimited Screen Repairs<br/>',
            subsubtitle: `<ul class='list-disc ml-5'><li>Genuine repair or reimbursement</li><li>Screen Repair Excess $${planDetails['PRESTIGE']['Repairs']['surFee']}</li></ul>`,
            description: `<br/>Cover for any Screen Damage to your phone. Send your phone to us or take it to your nearest authorised repairer and your screen will be repaired using genuine parts.
            <br/><br/> * Physical damage to your device’s screen. Does not include scratches, scrapes and other cosmetic damage that does not impact the device functionality.${make?.toUpperCase() === 'APPLE'
                ? `<br/><br/>Extra Benefits: iPhone Battery Replacement
            <br/><br/>Apple iPhones may be eligible for battery replacement if they are aged between 24-36 months and where the battery state of health is below 80%. One battery replacement is included . Only available under the Prestige plan.
            <br/><br/>Battery Excess is $29`
                : ''
              }`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
          {
            title: 'REPLACE',
            subtitle: '2 Claims per 12 months<br/>',
            subsubtitle: `<ul class='list-disc ml-5'><li>"like for like" damage replacement</li><li>Replace Excess is  $${planDetails['PRESTIGE']['Swap']['swapFee']}</li></ul>`,
            description: `<br/>If your phone suffers Accidental Damage* we will provide you with a ‘like for like’ device replacement which may be a refurbished or new device of the same make, model and capacity. You must also return your damaged phone to us within 14 days, using the instructions and prepaid packaging we provide you.
            <br/><br/> * Any physical damage (including liquid damage) impairing the functionality of the device, caused by an unintended event.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
          {
            title: 'RESTORE',
            subtitle: '1 Claim per 12 months<br/>',
            subsubtitle: `<ul class='list-disc ml-5'><li>Lost or stolen replacement</li><li>Replace Excess is  $${planDetails['PRESTIGE']['Replacement']['replacementFee']}</li></ul>`,
            description: `<br/>We cover you for Loss* or Theft** of your phone by providing you a ‘like for like’ device replacement which may be a refurbished or new device of the same make, model and capacity.
            <br/><br/> * Circumstances where you are no longer in possession of your phone or it is unrecoverable (excludes theft).
            <br/><br/>** Dishonest act or crime of stealing.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
          {
            title: 'RESOLVE',
            subtitle: 'Human  Assistance<br/>',
            subsubtitle: `<ul class='list-disc ml-5'><li>For all your tech support issues with your phone.</li></ul>`,
            description: `<br/>We give you access to the Asurion Knowledge Base, where you can find useful articles, advice and tips and tricks related to your phone. Our AI Virtual Assistant also provides you with fast responses to your device questions any time of day. 
            <br/><br/>You can also access human help from our Tech Experts! Ask unlimited questions about your phone (how to backup, data transfer and more!). Tech Experts are available every day, 8am-9pm (AEST).
            <br/><br/>Customers also have special access to our online tool ‘Security Advisor’. This tool can help you protect your online identity by letting you know if your email address has been compromised or been involved in a data breach.`,
            expanded: isOpen,
            setIsOpen: setRepairIsOpen,
            isBorder: true,
          },
        ],
        keyPointsCards: []
      },
      {
        offerName: 'Compare',
        cards: [],
        keyPointsCards: []
      },
    ]
    return d.find(d => d.offerName == planName)
  }
  const selectedPlanFeatures = getOfferFeatures(planData, selectedPlanName, Make)

  const updateAgreement = () => {
    const UpdateAgreementRequest = {
      CreateAgreementActivityRequest: {
        AgreementRequestId: agreementDetails.AgreementRequestId,
        AgreementRequestStatus: 'PLAN_SELECTION',
      },
    }
    API[ActionTypes.UPDATE_AGREEMENT_REQUEST](UpdateAgreementRequest).then(data => {
      updateState({ ...workflowState, UpdateAgreementResponse: data.data.CreateAgreementActivityResponse });
      return completeCurrentStep(currentStep, {
        PlanSelected: true,
        selectedPlanName: selectedPlanName,
        selectedPaymentMethod: quoteOffer?.selectedPaymentMethod
      })
    })
  }
  const tabsData = [
    {
      title: "Lite",
      render: () => <LitePlan selectedPlanFeatures={selectedPlanFeatures} updateAgreement={updateAgreement} />
    },
    {
      title: "Classic",
      render: () => <ClassicPlan selectedPlanFeatures={selectedPlanFeatures} updateAgreement={updateAgreement} />
    },
    {
      title: "Prestige",
      render: () => <PrestigePlan selectedPlanFeatures={selectedPlanFeatures} updateAgreement={updateAgreement} />
    },
    {
      title: "Compare",
      render: () => <ComparePlan planDetails={planData} selectedModel={Model} make={Make} isNewPhone={isNewPhone} showHeader={false} />
    }
  ]
  useEffect(() => {
    let newWFState = { ...workflowState, ['quote-offer']: { selectedPlanName: tabsData[activeTab].title, selectedPaymentMethod: quoteOffer?.selectedPaymentMethod || 'Annual' } };
    updateState(newWFState);
  }, [activeTab])



  headerText.args = {
    ...headerText.args,
    content: "Great your 1 month new phone discount{discountValue} has been applied for <b>{deviceName}</b>".replace('{discountValue}', '(8.33%)').replace('{deviceName}', deviceName)
  }

  tabContent.args = {
    ...tabContent.args,
    tabs: tabsData,
    activeTab: activeTab,
    setActiveTab: setActiveTab
  }

  return <Stack orientation='vertical' metadata={metadata} />
}

export default QuoteOffer
